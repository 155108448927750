import React, { Component } from "react";
import { Card, CardHeader } from 'reactstrap';
import moment from 'moment';

class HistoryKonsultan extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Card style={{ height: '320px' }} >
                    <CardHeader className='card-header pb-0 d-flex justify-content-between mb-0'>
                        <h5>History Konsultan</h5>
                    </CardHeader>
                    <div className="card-body scroll scrool recent-notification">
                        {
                            this.props.log.length > 0 ?
                                this.props.log.map((data, index) => (
                                    <div className="media" key={index}>
                                        <div className=""><span>{moment(data.created_at).format('DD MMM YY')}</span>
                                            <p>{moment(data.created_at).format('HH:mm')}</p>
                                        </div>
                                        <div className="media-body">
                                            <div>
                                                <span>consultant has been changed from <p style={{ fontSize: '1em', color: '#000', fontWeight: 'bold' }}>{data.consultant != null ? data.consultant.nama : ''}</p></span>
                                                <p className="f-12">{data.user != null ? data.user.username : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : ''
                        }
                    </div>
                </Card>
            </>
        )
    }
}

export default HistoryKonsultan;