import React, { Component } from 'react';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'react-big-calendar/lib/sass/styles.scss';
import Loader from '../../../components/common/loader';

class CalendarEvent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            schedules: [],
            modal: false,
            title: '',
            category: '',
            kota: ''
        }
    }

    componentDidMount() {
        this.getActivities();
    }

    getActivities() {
        this.setState({
            showLoading: true
        });
        var formData = new FormData();
        formData.append('start_date', Moment().startOf('month').format('YYYY-MM-DD'));
        formData.append('end_date', Moment().endOf('month').format('YYYY-MM-DD'));

        const url = API_URL.superStudent + '/event/calendar-event';
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`, }
        })
            .then(response => {
                this.setState({
                    showLoading: false,
                    schedules: response.data.data
                })
            })
    }
    showModal = (title, category, kota) => {
        this.setState({
            modal: true,
            title,
            category,
            kota
        })
    }

    closeModal = () => {
        this.setState({
            modal: false
        })
    }

    modalDetail = () => {
        return (
            <Modal className="modal-dialog" size="lg" isOpen={this.state.modal} toggle={() => this.closeModal()}>
                <ModalHeader toggle={() => this.closeModal()}>Detail Event</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col'>
                            <table>
                                <tr>
                                    <th>Nama Event</th>
                                    <th>: {this.state.title}</th>
                                </tr>
                                <tr>
                                    <th>Kategori Event</th>
                                    <th>: {this.state.category}</th>
                                </tr>
                                <tr>
                                    <th>Lokasi Event</th>
                                    <th>: {this.state.kota}</th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => this.closeModal()}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const localizer = momentLocalizer(Moment);
        return (
            <>
                <div className="card height-equal">
                    <div className="card-header">
                        <h6 className='f-w-600'>Calendar Event Bulan Ini</h6>
                    </div>
                    <div style={{ height: 450 }} className="card-body scroll recent-notification">
                        <div className='row'>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>EXPO</label>
                                    <input disabled type='color' className='form-control' value='#0BA22B' />
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>Info Session</label>
                                    <input disabled type='color' className='form-control' value='#0AEDE6' />
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>Global Youth</label>
                                    <input disabled type='color' className='form-control' value='#EDA10A' />
                                </div>
                            </div>
                        </div>
                        <Calendar
                            localizer={localizer}
                            scrollToTime={new Date("2024-01-01")}
                            defaultDate={new Date()}
                            events={this.state.schedules}
                            onSelectEvent={event => this.showModal(event.title, event.category_event, event.kota)}
                            views={{ month: true }}
                            style={{ maxHeight: 580 }}
                            eventOverlap
                            dragRevertDuration={500}
                            dragScroll
                            showMultiDayTimes
                            startAccessor="start"
                            endAccessor="end"
                            eventPropGetter={myList => ({
                                style: {
                                    backgroundColor: myList.borderColor
                                }
                            })}
                        />
                        <Loader showLoading={this.state.showLoading} />
                    </div>
                </div>
                {this.modalDetail()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState)(CalendarEvent);