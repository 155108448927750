/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { matchSorter } from 'match-sorter'


class EnglishCoursePromo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataPromo: [],
            operation: '',
            modal: false,
            id_promo: '',
            nama_promo: '',
            kode_promo: '',
            potongan: '',
            status: '',
            btnDisabled: false,
        }
    }

    loadDataPromo() {
        axios.get(API_URL.superStudent + '/english-course/get-promo', {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        })
            .then((result) => {
                this.setState({
                    dataPromo: result.data.data
                })
            })
    }

    handleInput(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    componentDidMount() {
        this.loadDataPromo()
    }

    addRow() {
        this.setState({
            title: 'Add Promo Data',
            operation: 'add',
            modal: true
        });
    }

    editRow(row) {
        this.setState({
            operation: 'edit',
            modal: true,
            id_promo: row.id_promo,
            nama_promo: row.nama_promo,
            kode_promo: row.kode_promo,
            potongan: row.potongan,
            status: row.status
        });
    }

    closeDialog() {
        this.setState({
            modal: false,
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    sendData() {
        this.setState({
            btnDisabled: true
        })
        var formData = new FormData();
        formData.append('nama_promo', this.state.nama_promo);
        formData.append('kode_promo', this.state.kode_promo);
        formData.append('status', this.state.status)
        formData.append('potongan', this.state.potongan)
        formData.append('id_promo', this.state.id_promo)

        if (this.state.operation === 'edit')
            formData.append('_method', 'PATCH');

        axios({
            method: 'POST',
            url: API_URL.superStudent + '/english-course/update-promo',
            data: formData,
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
            .then(() => {
                toast.success('Data Update Successfully')
                this.loadDataPromo()
                this.closeDialog()
            })
            .catch(() => {
                toast.error('Something Wrong')
                this.closeDialog()
            })

    }

    renderDialog() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Form Kode Promo</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" >
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Nama Promo</label>
                                    <input className="form-control" name="nama_promo" required type="text" value={this.state.nama_promo} onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Kode Promo</label>
                                    <input className="form-control" required name="kode_promo" type="text" value={this.state.kode_promo} onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Periode</label>
                                    <input className='form-control' type='number' name='potongan' value={this.state.potongan} onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className="form-control" required name="status" type="text" id="status" value={this.state.status}
                                        onChange={this.handleInput}>
                                        <option value="">--Pilih Status--</option>
                                        <option value="1">Active</option>
                                        <option value="0">Non Active</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} onClick={(e) => this.sendData()} color="primary">Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    render() {
        const columns = [
            {
                Header: 'Nama Promo',
                accessor: 'nama_promo',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["nama_promo"] }),
                filterAll: true
            },
            {
                Header: 'Kode Promo',
                accessor: 'kode_promo',
                style: { textAlign: 'center' },
                filterable: false
            },
            {
                Header: 'Potongan',
                accessor: 'potongan',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    <>{row.original.potongan} %</>
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: { textAlign: 'center' },
                filterable: false,
                Cell: (row) => (
                    row.original.status == '1' ? <label className='badge bg-success'>Active</label> : <label className='badge bg-dark'>Inactive</label>
                )
            }
        ]
        if (this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL005') {
            columns.push(
                {
                    Header: <b>Action</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div>
                            <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs mr-2" type="button" style={{ border: 'none' }} ><i className="fa fa-pencil" ></i></button>
                        </div>
                    ),
                    style: {
                        textAlign: 'center'
                    },
                    sortable: false,
                    filterable: false
                }
            )
        }
        return (
            <Fragment>
                <div style={{ display: this.props.dataUser.code_roles == 'RL001' || this.props.dataUser.code_roles == 'RL005' ? '' : 'none' }}>
                    <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{ border: 'none' }} >
                        <div>
                            <span>
                                <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                            </span>
                        </div>
                    </button>
                </div>

                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <ReactTable
                                data={this.state.dataPromo}
                                columns={columns}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                showPagination={true}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                            />
                        </div>
                    </div>
                </div>
                <ToastContainer />
                {this.renderDialog()}
            </Fragment>
        );
    }
};


const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState, null)(EnglishCoursePromo);