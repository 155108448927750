/* eslint-disable  react-hooks/exhaustive-deps */

import React ,{ useState, useEffect } from 'react';
import { useSelector } from "react-redux"
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert'

const IncomeConsultant = ({start_date, end_date}) => {

    const[data,setData] = useState([]);
    const[loader,setLoader] = useState(false)
    const[modal,setModal] = useState(false);
    const[detail,setDetail] = useState([])
    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));


    const loadIncome = () => {
        let formData = new FormData ()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/last-year-income/income-consultant', formData, {
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setData(response.data.data)
        })
        .catch(error => console.log(error))
    }

    const showDetail = (item) => {
        setLoader(true)
        let form = new FormData()
        form.append('code_konsultan', item)
        form.append('start_date', start_date)
        form.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/last-year-income/payment-detail', form,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setDetail(response.data.data)
            setLoader(false)
            setModal(true)
        })
        .catch(error => console.log(error))
    }

    const formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    const modalDetail = () => {
        return (
            <Modal className="modal-dialog"  size="lg" isOpen={modal} toggle={() => setModal(false)}>
            <ModalHeader toggle={() => setModal(false)}>Detail Income</ModalHeader>
            <ModalBody>
                <div className='row'>
                {
                    detail.length > 0 ?
                    detail.map((items,index) => (
                        <div key={index} className='col-lg-6 col-md-12 mt-2'>
                            <ul>
                                <li className='f-w-600'>{items.jenis_pembayaran}</li>
                                <ul style={{fontSize:'11px'}}>
                                    {
                                        items.payments.length > 0 ?
                                        items.payments.map((item,i) => (
                                            <li key={i}><i className='fa fa-caret-right txt-secondary m-r-10'></i> ({item.student.code_students}) {item.student.nama} - {formatRupiah(item.total_rupiah)}</li>
                                        ))
                                        :
                                        ''
                                    }
                                </ul>
                            </ul>
                        </div>
                    ))
                    :
                    <div className='col-md-6'>
                        <label className='f-w-600'>Data kosong</label>
                    </div>
                }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => setModal(false)}>Close</Button>
            </ModalFooter>
        </Modal>
        );
    }

    useEffect(() => {
        loadIncome()
    },[start_date, end_date])

    return (
        <>
            <SweetAlert title="Loading..." showConfirm={false} showCancel={false} show={loader} />
            <div className='card'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Consultant Income </h6>
                </div>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table style={{fontSize:'10px'}} className='table table-striped table-hover table-borderedfor'>
                            <thead className='tbl-strip-thad-bdr'>
                                <tr className='table-secondary'>
                                    <th scope="col">#</th>
                                    <th scope="col">Nama</th>
                                    <th scope="col">Membership</th>                                
                                    <th scope="col">Translate</th>
                                    <th scope="col">Visa</th>
                                    <th scope="col">Admin Fee</th>
                                    <th scope="col">Flight</th>
                                    <th scope="col">SIM Card</th>
                                    <th scope="col">English Course</th>
                                    <th scope="col">Total Income</th>
                                    <th scope="col">Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.length > 0 ?
                                    data.map((items,index) => (
                                        <tr key={index}>
                                            <th>{index+1}</th>
                                           {
                                            items.map((item,i) => (
                                                i === 9 ?
                                                <td key={i} width={50}>
                                                    <button style={{border:'none'}} className='btn btn-primary btn-xs btn-pill' onClick={() => showDetail(item) }><i className='fa fa-eye'></i></button>
                                                </td>
                                                :
                                                <th style={{color:i === 8 ? '#7c9ed9': 'black'}} key={i} >{item}</th>
                                            ))
                                           }
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={6}>Data Kosong</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {modalDetail()}
        </>
    )
}

export default IncomeConsultant;