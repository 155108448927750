import React ,{Component} from 'react'
import ReactTable from 'react-table';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import image from '../../assets/images/user/3.jpg'

class DataEduGroup extends Component {

    constructor(props){
        super(props)
        this.state = {
            dataGroup:[],
            showAction: this.props.dataUser.code_roles === 'RL021' ? '' : this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL026' ? '' : this.props.dataUser.code_roles === 'RL005' ? '' : this.props.dataUser.code_roles === 'RL012' ? '' : 'none',
        }
    }

    componentDidMount(){
        this.loadEducationGroup()
    }

    loadEducationGroup = () => {
        axios.get(API_URL.superStudent+'/edu-group',{
            headers: { Authorization: `Bearer ${this.props.dataToken}`, }
        })
        .then(response => this.setState({ dataGroup: response.data.data }))
    }

    render(){

        const columns = [
            { 
                Header: 'Name',
                accessor: 'name',
                Cell:(row) => (
                    <div className="image-sm-size align-middle">
                        <img className="img-radius img-50 align-top m-r-15 rounded-circle" src={row.original.logo !== null ? row.original.logo : image} alt="" />
                        <div className="d-inline-block">
                            <div className='f-w-600 mt-3'>{row.original.name}</div>
                        </div>
                    </div>
                ),
            },
            {
                Header: 'City',
                accessor: 'city',
                style: {textAlign: 'center'},
            },
            {
                Header: 'End Contract',
                accessor: 'city',
                style: {textAlign: 'center'},
                Cell: (row) => (
                    <div className='row'>
                         <span className='col'>{Moment(row.original.end_date).format('DD MMM YYYY')}</span>
                    </div>
                ),
            },
            {
                Header: <b>Action</b>,
                id:'action',
                accessor: str => 'delete',
                width:110,
                Cell:  (row) => (
                    <>                 
                    <button onClick={() => this.props.history.push({
                        pathname: `${process.env.PUBLIC_URL}/pages/form-edugroup/`+row.original.id,
                        state: row.original,
                    })}
                    className="btn btn-outline-success btn-xs" type="button" style={{border:'none',display:this.state.showAction}} ><i className="fa fa-pencil"></i>
                    </button>
                    </>
                ),
                style: {
                  textAlign: 'center'
                },
                sortable: false,
                filterable: false
              }
        ]
        return(
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className='card-header'>
                                    <Link className="btn btn-success" style={{color:'white'}} to={'/pages/form-edugroup'}> Tambah Data</Link>
                                </div>
                                <div className='card-body'>
                                <ReactTable
                                    data={this.state.dataGroup}
                                    columns={columns}
                                    showPagination={false}
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser
  })

export default connect(reduxState, null)(withRouter(DataEduGroup));