
import React from "react"
import CardStatic from "../partition/card-static";
import Breadcrumb from "../../common/breadcrumb";
import TaskList from "./task-list";
import CalendarEvent from "./calendar-event";

const DashboardCreative = () => {


    return(
        <>
            <Breadcrumb parent="Dashboard Creative" title = "Dashboard Creative" />
            <CardStatic/>
            <CalendarEvent/>
            <TaskList/>
        </>
    )

}

export default DashboardCreative;