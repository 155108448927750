/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { saveAs } from "file-saver";
import excel from '../../assets/images/document-logo/excel-logo.png';
import word from '../../assets/images/document-logo/word-logo.png';
import pdf from '../../assets/images/document-logo/pdf-logo.png';
import ReactTable from 'react-table';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

class Resources extends Component{

    constructor(props){
        super(props)
        this.state ={
            dataResources:[],
            modal_card:false,
            modal_add: false,
            modal_delete:false,
            modalPreview:false,
            btnSave: 'Save',
            disableButton:false,
            file_id:'',
            card_id:'',
            title:'',
            description:'',
            data:'',
            name:'',
            urlPreview:'',
            showComponent: this.props.dataUser.code_roles === 'RL001' || this.props.dataUser.code_roles === 'RL003' ? '' : 'none',
            action: '',
            id: ''
        }
    }

    componentDidMount(){
        this.loadFileResource()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeFile = (e) => {
        this.setState({
            data:e.target.files[0]
        })
    }

    closeModal = () => {
        this.setState({
            modal_card:false, 
            modal_add:false,
            modal_card_delete:false,
            modalPreview:false,
            modal_delete:false
        })
    }

    showModalAdd = (card_id) => {
        this.setState({
            modal_add: true,
            title:'',
            card_id,
            description:'',
            data:''
        })
    }

    addCard = () => {
        this.setState({
            action: 'add',
            modal_card: true
        })
    }

    editCard = (row) => {
        this.setState({
            action: 'edit',
            modal_card: true,
            id_card: row.id,
            name: row.name,
        })
    }

    deleteCard = (row) => {
        this.setState({
            action: 'delete',
            modal_card_delete: true,
            id_card: row.id,
            name: row.name,
        })
    }

    handleModalPreivew = (urlPreview) => {
        this.setState({
            modalPreview:true,
            urlPreview
        })
    }

    loadFileResource = () => {
        axios.get(API_URL.superStudent+'/resources/all-data', {
            headers:{ Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => this.setState({dataResources: response.data.data}))
        .catch(error => console.log(error))
    }

    saveData = () => {
        let formData =  new FormData()
        formData.append('id', this.state.id_card)
        formData.append('name', this.state.name)

        var url = '/resources/add-card';

        if (this.state.action == 'edit') {
            url = '/resources/edit-card';
        } else if (this.state.action == 'delete') {
            url = '/resources/delete-card';
        }

        axios.post(API_URL.superStudent+url,formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response =>{
            toast.success('successfully added a new card')
            this.setState({
                name:'',
                modal_card:false,
                modal_card_delete:false,
            })
            this.loadFileResource()
        })
        .catch(error => {
            toast.error("There is an error...");
        })
    }

    uploadData = () => {
        let formData = new FormData()
        formData.append('name', this.state.title)
        formData.append('description',this.state.description)
        formData.append('card_id', this.state.card_id)
        formData.append('data', this.state.data)
        this.setState({
            btnSave:'Saving..',
            disableButton:true
        })
        axios.post(API_URL.superStudent+'/resources/add-data', formData,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.loadFileResource()
            toast.success('successfully added a new data')
            this.setState({
                btnSave:'Save',
                disableButton:false
            })
            this.closeModal()
        })
        .catch(error => {
            this.closeModal()
            this.setState({
                btnSave:'Save',
                disableButton:false
            })
            toast.error("There is an error...");
        })
    }

    deleteData = () => {
        axios.delete(API_URL.superStudent+'/resources/delete-file/'+this.state.file_id,{
            headers:{ Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(response => {
            this.loadFileResource()
            this.closeModal()
            toast.success('successfully delete data')
        })
        .catch(error => {
            this.closeModal()
            toast.error("There is an error...");
        })
    }

    renderModalCard() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modal_card} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Add new card</ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
                <div className='form-group'>
                    <label className='f-w-600'>Card Name</label>
                    <input className='form-control' name="name" value={this.state.name} onChange={this.handleInput}/>
                </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.saveData()}>Save Data</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderModalCardDelete() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modal_card_delete} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Delete data</ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
               Are you sure you want to delete this data ?
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disableButton} color="primary" onClick={(e) => this.saveData()}>Delete</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    showDeleteModal = (id) => [
        this.setState({
            file_id: id,
            modal_delete:true
        })
    ]

    renderModalAdd() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modal_add} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Add new data</ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
                <div className='form-group'>
                    <label className='f-w-600'>Title</label>
                    <input className='form-control' name="title" value={this.state.title} onChange={this.handleInput}/>
                </div>
                <div className='form-group'>
                    <label className='f-w-600'>Detail</label>
                    <textarea name='description' value={this.state.description} className='form-control' onChange={this.handleInput}/>
                </div>
                <div className='form-group'>
                    <label className='f-w-600'>File</label>
                    <input type='file' className='form-control' onChange={this.handleChangeFile}/>
                </div>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disableButton} color="primary" onClick={(e) => this.uploadData()}>{this.state.btnSave}</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderModalDelete() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modal_delete} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Delete data</ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
               Are you sure you want to delete this data ?
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disableButton} color="primary" onClick={(e) => this.deleteData()}>Delete</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderPreview(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalPreview} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>Preview Data</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    {
                        this.state.urlPreview.images == null ?
                        <Row>
                            <Col lg='12' md='12' sm='12' xs='12'>
                                <h4>URL : </h4>
                                <br/>
                                <a href={this.state.urlPreview.description} rel='noopener noreferrer' target="_blank">{this.state.urlPreview.description}</a>
                                <iframe title='frame' className='mt-4' src={this.state.urlPreview.description} width="100%" height="600px" />
                            </Col>
                        </Row>
                        :
                        <DocViewer 
                            pluginRenderers={DocViewerRenderers} 
                            documents={[{ uri:this.state.urlPreview.data }]} 
                            config={{
                                header: {
                                    disableHeader: false,
                                    disableFileName: false,
                                    retainURLParams: false
                                }
                            }}
                        />
                    }
              </ModalBody>
            </Modal>
        )
    }

    downloadFile = (url) => {
        saveAs(url,url.substring(url.lastIndexOf('/')));
    }

    render(){

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                filterable: false
            },
            {
                Header: 'Detail',
                accessor: 'detail',
                filterable: false,
                Cell: (row) => (
                    row.original.files.length > 0 ?
                    <>
                        {
                            row.original.files.map((file) => (
                                file.images == null ?
                                <>
                                    <a href={file.description}>{file.description}</a>
                                    <br/>
                                </>
                                :
                                ""
                            ))
                        }
                    </>
                    :
                    ""
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>
                        {
                            this.props.codeRoles == 'RL001' || this.props.codeRoles == 'RL003' || this.props.codeRoles == 'RL005' ?
                            <div>
                                <button onClick={() => this.editCard(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                                <button onClick={() => this.deleteCard(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                            </div>
                            :
                            ''
                        }
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <>
            <Breadcrumb title="Resources" parent="Employee" />
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <button onClick={() => this.addCard()} className='btn btn-success mb-5'><i className='fa fa-plus'></i> New Card</button>
                                    <br/>
                                    {
                                        this.state.dataResources.length > 0 ? 
                                            <ReactTable
                                                data={this.state.dataResources}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={20}
                                                className="-striped -highlight"
                                                SubComponent={row => {
                                                    const data = row.original;
                                                    return(
                                                        <div className="card-body scroll">
                                                            <button onClick={() => this.showModalAdd(data.id)} className='btn btn-dark m-3'>Tambah</button>
                                                            <div className="ribbon ribbon-primary ribbon-right ribbon-clip-right">{data.name}</div>
                                                            <div className="my-gallery gallery-with-description product-box">
                                                                <Row>
                                                                    {
                                                                        data.files.length > 0 ?
                                                                        data.files.map(file => (
                                                                            <>
                                                                                <Col lg='2' md='2' sm='2' xs='2'>
                                                                                    <figure key={file.id} className='product-img'>
                                                                                        {
                                                                                            file.data.split('.').pop() == 'pdf' ?
                                                                                            <img src={pdf} alt="Gallery" className="img-thumbnail"/> :
                                                                                            file.data.split('.').pop() == 'docx' ?
                                                                                            <img src={word} alt="Gallery" className="img-thumbnail"/> : 
                                                                                            file.data.split('.').pop() == 'xlsx' ?
                                                                                            <img src={excel} alt="Gallery" className="img-thumbnail"/> :
                                                                                            <img src={file.data} alt="Gallery" className="img-thumbnail"/>
                                                                                        }
                                                                                        
                                                                                        <div className="product-hover">
                                                                                            <ul>
                                                                                                <li>
                                                                                                    <button className="btn" type="button" data-toggle="modal"
                                                                                                    onClick={() => this.handleModalPreivew(file)} data-target="#exampleModalCenter">
                                                                                                    <i className="icon-eye"></i>
                                                                                                    </button>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <button className="btn" type="button" data-toggle="modal"
                                                                                                    onClick={() => this.downloadFile(file.data)} data-target="#exampleModalCenter">
                                                                                                    <i className="icon-download"></i>
                                                                                                    </button>
                                                                                                </li>
                                                                                                <li style={{display:this.state.showComponent}}>
                                                                                                    <button className="btn" type="button" data-toggle="modal"
                                                                                                    onClick={() => this.showDeleteModal(file.id)} data-target="#exampleModalCenter">
                                                                                                    <i className="icon-trash"></i>
                                                                                                    </button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        <div className="caption">
                                                                                            <h5>{file.name}</h5>
                                                                                            <p>{file.description}</p>
                                                                                        </div>
                                                                                    </figure>
                                                                                </Col>
                                                                            </>
                                                                        ))
                                                                        :
                                                                        ''
                                                                    }
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            /> 
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.renderModalCard()}
            {this.renderModalAdd()}
            {this.renderModalCardDelete()}
            {this.renderPreview()}
            {this.renderModalDelete()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState, null)(Resources)