/* eslint-disable react-hooks/exhaustive-deps */

import React ,{useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/sass/styles.scss';
import API_URL from '../../ApiUrl';
import axios from 'axios'


const localizer = momentLocalizer(moment)

const CalendarEmployee = () => {

    const[calendar,setCalendar] = useState([])

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    useEffect(() => {
        loadCalendar()
    },[])

    const loadCalendar = () => {
        console.log(new Date('2022-10-10'))
        axios.get(API_URL.superStudent+'/consultant/calendar-employee',{
            headers: { 'Authorization': `Bearer ${dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => {
            setCalendar(result)
        })
        .catch(error => console.log(error))
    }

    return (
        <>
            <div className="container-fluid">
                <h4>Employee Calender</h4>
                <div className="card">
                    <div className="card-header">
                        <div className='row'>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>Start Kontrak</label>
                                    <input disabled type='color' className='form-control' value='#0BA22B'/>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>Day 90</label>
                                    <input disabled type='color' className='form-control' value='#0AEDE6'/>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>Day -60</label>
                                    <input disabled type='color' className='form-control' value='#EDA10A'/>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>End Kontrak</label>
                                    <input disabled type='color' className='form-control' value='#FE0202'/>
                                </div>
                            </div>
                            <div className='col-sm'>
                                <div className='form-group'>
                                    <label className='f-w-600'>Birthday</label>
                                    <input disabled type='color' className='form-control' value='#000000'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <Calendar
                            localizer={localizer}
                            scrollToTime={new Date(1970, 1, 1, 6)}
                            defaultDate={new Date()}
                            events={calendar}
                            views={{month: true}}
                            style={{ maxHeight: 580 }}
                            startAccessor="start"
                            endAccessor="end"
                            eventPropGetter={myList =>({
                                style:{
                                    backgroundColor: myList.borderColor
                                }
                            })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CalendarEmployee;