/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Moment from 'moment';

class RightProfile extends Component{

    constructor(props){
        super(props)
        this.state = {
            dataProvinsi:[],
            dataKabKota:[],
            dataKabKotaFilter:[],
            no_wa:'',
            tempat_lahir:'',
            tanggal_lahir:'',
            provinsi_id:'',
            kabupaten_id:'',
            alamat:'',
            modal_kontrak:false,
            file_type:'',
            fileData:'',
            urlPreview:'',
            start_date:'',
            end_date:'',
            no_nik:'',
            no_kk:'',
            no_npwp:'',
            email_pribadi:'',
            agama:0,
            jenis_kelamin:0,
            status_kawin:0,
            alamat_ktp:'',
            nama_ibu:'',
            nama_kontak_darurat:'',
            telepon_kontak_darurat:'',
            alamat_kontak_darurat:'',
            hubungan_pekerja:'',
            id_kontrak:'',
            type_kontrak:'',
        }
    }

    handleInput = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        })
    }

    closeModal = () => {
        this.setState({
            modal_kontrak:false
        })
    }

    componentDidMount(){
        this.getProvinsi()
        this.getKabKota()
        this.setState({
            no_wa: this.props.data.no_wa,
            tempat_lahir: this.props.data.tempat_lahir,
            tanggal_lahir: this.props.data.tanggal_lahir,
            provinsi_id: this.props.data.provinsi_id,
            kabupaten_id: this.props.data.kabupaten_id,
            alamat: this.props.data.alamat,
            no_nik: this.props.data.no_nik,
            no_kk: this.props.data.no_kk,
            no_npwp: this.props.data.no_npwp,
            email_pribadi: this.props.data.email_pribadi,
            agama: this.props.data.agama,
            jenis_kelamin: this.props.data.jenis_kelamin,
            status_kawin: this.props.data.status_kawin,
            alamat_ktp: this.props.data.alamat_ktp,
            nama_ibu: this.props.data.nama_ibu,
            nama_kontak_darurat: this.props.data.nama_kontak_darurat,
            telepon_kontak_darurat: this.props.data.telepon_kontak_darurat,
            alamat_kontak_darurat: this.props.data.alamat_kontak_darurat,
            hubungan_pekerja: this.props.data.hubungan_pekerja,
        })
    }

    saveData = () => {
        let formData = new FormData()
        formData.append('code_konsultan',this.props.data.code_konsultan)
        formData.append('no_wa',this.state.no_wa)
        formData.append('tempat_lahir', this.state.tempat_lahir)
        formData.append('tanggal_lahir', this.state.tanggal_lahir)
        formData.append('provinsi_id', this.state.provinsi_id)
        formData.append('kabupaten_id', this.state.kabupaten_id)
        formData.append('alamat', this.state.alamat)
        formData.append('no_nik', this.state.no_nik)
        formData.append('no_kk', this.state.no_kk)
        formData.append('no_npwp', this.state.no_npwp)
        formData.append('email_pribadi', this.state.email_pribadi)
        formData.append('agama', this.state.agama)
        formData.append('jenis_kelamin', this.state.jenis_kelamin)
        formData.append('status_kawin', this.state.status_kawin)
        formData.append('alamat_ktp', this.state.alamat_ktp)
        formData.append('nama_ibu', this.state.nama_ibu)
        formData.append('nama_kontak_darurat', this.state.nama_kontak_darurat)
        formData.append('telepon_kontak_darurat', this.state.telepon_kontak_darurat)
        formData.append('alamat_kontak_darurat', this.state.alamat_kontak_darurat)
        formData.append('hubungan_pekerja', this.state.hubungan_pekerja)
        this.props.saveProfile(formData)
    }

    getProvinsi(){
        const url = API_URL.superStudent+'/master/provinces';
        axios.get(url,{
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
        .then((data) => {
            this.setState({ dataProvinsi: data })
        })
    }

    getKabKota(){
        const url = API_URL.superStudent+'/master/kabupaten';
        axios.get(url,{
            headers: {
                'Accept': 'application/json'
            }
        }).then(response => response.data.data)
        .then((data) => {
          this.setState({ dataKabKota: data })
          const id = this.props.data.provinsi_id
            if(id > 0){
              const data = Object.values(this.state.dataKabKota);              
              const filterData = data.filter(dataFilter => {
                  return dataFilter.province_id == id;
                  });
                this.setState({dataKabKotaFilter:filterData})
            }
        })
    }

    setProvinsi = (e) => { 
        const newData = e.target.value;
        const data = Object.values(this.state.dataKabKota);
        const filterData = data.filter(dataFilter => {
          return dataFilter.province_id == newData;
          });
        this.setState({dataKabKotaFilter:filterData})
  
        this.setState(prevState => {
          let provinsi_id = Object.assign({}, prevState.provinsi_id);  
          provinsi_id = newData; 
          return { provinsi_id };                   
        })
    }

    renderModalKontrak = () => {
        this.setState({
            modal_kontrak: true,
            type_kontrak: 'create',
            start_date: '',
            end_date: ''
        })
    }

    addKontrak = () => {
        let formData = new FormData()
        formData.append('code_konsultan', this.props.data.code_konsultan)
        formData.append('start_date', this.state.start_date)
        formData.append('end_date', this.state.end_date)
        formData.append('type_kontrak', this.state.type_kontrak)
        formData.append('id_kontrak', this.state.id_kontrak)
        this.props.historyKontrak(formData)
        this.closeModal()
    }

    editKontrak = (e,row) => {
        e.preventDefault()
        this.setState({
            start_date: row.start_date,
            id_kontrak: row.id,
            end_date: row.end_date,
            modal_kontrak: true,
            type_kontrak:'update'
        })
    }

    modalKontrak(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modal_kontrak} toggle={() => this.closeModal()} size="md">
              <ModalHeader toggle={() => this.closeModal()}>Add Kontrak</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>              
                <div className="form-group">
                    <label className="f-w-600">Tanggal Kontrak</label>
                        <div className="input-group">
                            <input type="date" value={this.state.start_date} onChange={this.handleInput} name="start_date" className="form-control"/>
                            <input type="date" value={this.state.end_date} onChange={this.handleInput} name="end_date" className="form-control"/>
                        </div>
                    </div> 
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={(e) => this.addKontrak()}>Set</Button>
                <Button  color="secondary" onClick={() => this.setState({ modal_kontrak: false })}>Cancel</Button>
              </ModalFooter>
            </Modal>
        )
    }

    render(){
        return(
            <>
                <form className="card">
                    <div className="card-header">
                        <h4 className='f-w-600'>Profile</h4>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">NO.NIK</label>
                                    <input className="form-control" value={this.state.no_nik} type="text" placeholder="1234567890" name='no_nik' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">NO.KK</label>
                                    <input className="form-control" value={this.state.no_kk} type="text" placeholder="0987654321" name='no_kk' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">NO. NPWP</label>
                                    <input className="form-control" value={this.state.no_npwp} type="text" placeholder="9909802122" name='no_npwp' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Jenis Kelamin</label>
                                    <select className='form-control' onChange={this.handleInput} name='jenis_kelamin' value={this.state.jenis_kelamin}>
                                        <option value={0}> --Select-- </option>
                                        <option value={1}> Pria </option>
                                        <option value={2}> Wanita </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Agama</label>
                                    <select className='form-control' name='agama' onChange={this.handleInput} value={this.state.agama}>
                                        <option value={0}> --Select-- </option>
                                        <option value={1}> Islam </option>
                                        <option value={2}> Kristen Katolik </option>
                                        <option value={3}> Kristen Protestan </option>
                                        <option value={4}> Hindu </option>
                                        <option value={5}> Buddha </option>
                                        <option value={6}> Konghucu </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Status Pernikahan</label>
                                    <select className='form-control' onChange={this.handleInput} name='status_kawin' value={this.state.status_kawin}>
                                        <option value={0}> --Select-- </option>
                                        <option value={1}> Belum Menikah </option>
                                        <option value={2}> Menikah </option>
                                        <option value={3}> Cerai Hidup </option>
                                        <option value={4}> Cerai Meninggal </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Telepon</label>
                                    <input className="form-control" value={this.state.no_wa} type="text" placeholder="62812345678" name='no_wa' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Tempat Lahir</label>
                                    <input className="form-control" value={this.state.tempat_lahir} type="text" placeholder="Medan" name='tempat_lahir' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Tanggal Lahir</label>
                                    <input className="form-control" value={this.state.tanggal_lahir} type="date" placeholder="1996-01-01" name='tanggal_lahir'  onChange={this.handleInput}/>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Provinsi</label>
                                    <select className="form-control" value={this.state.provinsi_id} name="provinsi_id" type="text" onChange={this.setProvinsi}>
                                    <option value="0">--Pilih Provinsi--</option>
                                    {this.state.dataProvinsi.map((province)=>(
                                        <option key={province.id} value={province.id}>{province.province_name}</option>
                                    ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Kabupaten</label>
                                    <select className="form-control" value={this.state.kabupaten_id} name="kabupaten_id" type="text" onChange={this.handleInput} >
                                    <option value="0">--Pilih Kab/Kota--</option>
                                    {this.state.dataKabKotaFilter.map((kabupaten)=>(
                                    <option key={kabupaten.id} value={kabupaten.id}>{kabupaten.kabupaten_name}</option>
                                    ))} 
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Alamat Sekarang</label>
                                    <input className="form-control" value={this.state.alamat} type="text" placeholder="Jln Buluh Indah No.128" name='alamat' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Nama Ibu</label>
                                    <input className="form-control" value={this.state.nama_ibu} type="text" placeholder="Siti" name='nama_ibu' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Email Pribadi</label>
                                    <input className="form-control" value={this.state.email_pribadi} type="email" placeholder="Siti" name='email_pribadi' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Alamat KTP</label>
                                    <input className="form-control" value={this.state.alamat_ktp} type="text" placeholder="Jln. Sudirman" name='alamat_ktp' onChange={this.handleInput} />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <h4 className='f-w-600'>Kontak Darurat</h4>
                        <div className='row'>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Nama Lengkap</label>
                                    <input className="form-control" value={this.state.nama_kontak_darurat} type="text" placeholder="Budi" name='nama_kontak_darurat' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Telepon/No.HP</label>
                                    <input className="form-control" value={this.state.telepon_kontak_darurat} type="text" placeholder="12345678" name='telepon_kontak_darurat' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Alamat Lengkap</label>
                                    <input className="form-control" value={this.state.alamat_kontak_darurat} type="text" placeholder="Jln. Soekarno" name='alamat_kontak_darurat' onChange={this.handleInput} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="f-w-600">Hubungan Pekerja</label>
                                    <input className="form-control" value={this.state.hubungan_pekerja} type="text" placeholder="Saudara" name='hubungan_pekerja' onChange={this.handleInput} />
                                </div>
                            </div>
                        </div>
                        {
                            this.props.data.code_konsultan === this.props.dataLogin.code_konsultan || this.props.dataLogin.code_roles === 'RL004' ?
                            <button className="btn btn-primary float-right" type="button" onClick={e => this.saveData()}>Update Profile</button>
                            :
                            ''
                        }
                    </div>
                    <div className='card-body'>
                        <label className='f-w-600'>History Kontrak</label>
                        {
                            this.props.data.history_kontrak.map((item, index) => (
                                <div key={index}>
                                    <h6><button style={{display:this.props.dataLogin.code_roles === 'RL004' ? '' : 'none'}} onClick={(e) => this.editKontrak(e, item)} className='btn btn-info btn-xs' ><i className='fa fa-pencil'></i></button> {index+1}. {Moment(item.start_date).format('DD MMMM YYYY')} - {Moment(item.end_date).format('DD MMMM YYYY')} {item.status === 1 ? <span className='badge badge-success badge-'> Active</span> : ''}</h6>
                                </div>
                            ))
                        }
                        {
                            this.props.dataLogin.code_roles === 'RL004' ?
                            <button className="btn btn-primary float-right" type="button" onClick={this.renderModalKontrak}>Add Kontrak</button>
                            :
                            ''
                        }
                    </div>
                </form>
                {this.modalKontrak()}
            </>
        )
    }

}

export default RightProfile;