/* eslint-disable eqeqeq */
import React, { Component,Fragment } from 'react'
import { connect } from 'react-redux';
import DataTablesExpo from './dataTablesExpo';

class UniversityExpoAttandance extends Component {

    render(){
        return(
            <Fragment>
                <div style={{display:this.props.userableData.userable.status_expo != '0' ? '' : 'none'}} className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row m-1">
                            <div className='col-md-12'>
                                <DataTablesExpo id_event={this.props.match.params.idEvents} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    userableData: state.Login.userableData
  })

export default connect(reduxState, null)(UniversityExpoAttandance);