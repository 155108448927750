/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import ReactTable from 'react-table';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { ExportCSV } from '../../../services/ExportCSV';
import Loader from '../../../components/common/loader'
import { connect } from 'react-redux';
import Breadcrumb from '../../../components/common/breadcrumb';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';
import {matchSorter} from 'match-sorter';

class EventAmbassador extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            event_data: [],
            register_data: [],
            event_id: '',
            tipe: '',
            referal: '',
            modal: false,
            showAction: this.props.dataUser.code_roles === 'RL023' ? '' : this.props.dataUser.code_roles === 'RL001' ? '' : this.props.dataUser.code_roles === 'RL003' ? '' : 'none',

        }
    }

    componentDidMount() {
        this.getDataEvent();
    }

    getDataEvent = () => {
        const url = API_URL.superStudent+'/event/jenis-event/3';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                event_data:data.data
            });
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },() => {
            this.getDataExpoAmbassador()
        })
    }

    getDataExpoAmbassador() {
        this.setState({ showLoading: true })

        const url = API_URL.superStudent+'/event/ambassador-event/'+this.state.event_id;
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then(response => {
            this.setState({
                data: response.data,
                showLoading: false
            })
        }).catch(err => {
            this.setState({ 
                showLoading: false 
            })
        })
    }

    detailRow(row) {
        this.setState({
            register_data: []
        })

        const url = API_URL.superStudent+'/event/ambassador-register/'+row.code_ambassador+'/'+row.tipe;
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then(response => {
            this.setState({
                register_data: response.data[0].event[0].register,
                showLoading: false,
                modal: true

            })
        }).catch(err => {
            this.setState({ 
                showLoading: false 
            })
        })
    }

    renderDialog() {
        const { modal } = this.state;

        const columns_peserta = [
            {
                Header: 'Kode Register',
                accessor: 'register_code',
                style: { textAlign: 'center' },
            },
            {
                Header: 'Nama Lengkap',
                accessor: 'name',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'phone',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["phone"] }),
            },
            {
                Header: 'Sekolah / Kampus',
                accessor: 'school',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["school"] }),
            },
            {
                Header: 'Kehadiran',
                accessor: 'pivot.attandance',
                style: { textAlign: 'center' },
                Cell: (row) => (
                    <>
                        {
                            row.original.pivot.attandance == 1 ? "Checkin"
                            :
                            row.original.pivot.attandance == 2 ? "Checkout"
                            :
                            ""
                        }
                    </>
                )
            }, 
        ];

        return (
            <div className="card-body btn-showcase">
                <Modal size="lg" isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Detail Register</ModalHeader>
                    <ModalBody>
                        <ExportCSV csvData={this.state.register_data} fileName={"data-peserta"} />
                        <ReactTable
                            data={this.state.register_data}
                            columns={columns_peserta}
                            defaultPageSize={20}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            filterable
                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    render() {
        const columns = [
            {
                Header: 'Kode Ambassador',
                accessor: 'code_ambassador',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["code_ambassador"] }),
            },
            {
                Header: 'Tipe',
                accessor: 'tipe',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["tipe"] }),
            },
            {
                Header: 'Nama Lengkap',
                accessor: 'nama_lengkap',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_wa"] }),
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
            },
            {
                Header: 'No. Rek',
                accessor: 'no_rek',
                style: { textAlign: 'center' },
                filterAll: true,
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["no_rek"] }),
            },
            {
                Header: 'Total Peserta',
                accessor: 'register_count',
                style: { textAlign: 'center' },
                filterable: true
            },
            {
                Header: 'Total Attandance',
                accessor: 'total_attandance',
                style: { textAlign: 'center' },
                filterable: true
            },
            {
                Header: 'Total Pendapatan',
                accessor: 'total_pendapatan',
                style: { textAlign: 'center' },
                filterable: true
            },
            {
                Header: <b>Action</b>,
                id: 'detail',
                accessor: str => "detail",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.detailRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-list" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ];

        return (
            <Fragment>
                <Breadcrumb title="Ambassador Event" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className='row m-1 justify-content-md-center mb-5'>
                            <div className="col-sm-6">
                                <div className="text-right">
                                    <select className="form-control" onChange={this.handleChange} name="event_id" value={this.state.event_id}>
                                        <option value='0'> --Filter Peserta-- </option>
                                        {
                                        this.state.event_data.map(event => {
                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row justify-content-md-center">
                                            <div className="col-md-4">
                                                <h5 className="mb-1">Total Registration : <span className="badge badge-primary"><Countup end={this.state.data.length} /></span> </h5>
                                            </div>
                                            <div style={{ display: this.state.showAction }} className="col-md-3">
                                                <ExportCSV csvData={this.state.data} fileName={"data-ambassador-2022"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row m-1">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <ReactTable
                                            data={this.state.data}
                                            columns={columns}
                                            defaultPageSize={100}
                                            className="-striped -highlight"
                                            showPaginationBottom={true}
                                            filterable
                                            defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                                        />
                                        <Loader showLoading={this.state.showLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    codeUsers: state.Login.detailUser.code_users,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(EventAmbassador);