/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Moment from 'moment';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';

class EventCard extends Component {

    _isMounted = false; 
    constructor(props) {
        super(props);
        this.state = {
            totalKota: 0,
            hargaKota: 0,
            selectKota: []
        }
    }
    
    pilihKota = (e, item, j) => {
        if (e.target.checked) {
            this.setState(prevState => ({
                selectKota: [...prevState.selectKota, item]
            }), () => {
                this.setState({
                    totalKota: this.state.selectKota.reduce((total, currentValue) => total = total + currentValue.pivot.harga,0)
                })
            })
        } else {
            let selectKota = [...this.state.selectKota];
            selectKota.splice(j, 1);
            this.setState({
                selectKota 
            }, () => {
                this.setState({
                    totalKota: this.state.selectKota.reduce((total, currentValue) => total = total + currentValue.pivot.harga,0)
                })
            });
        }
    }

    async addCartEvent(row) {
        if (this.state.selectKota.length > 0) {
            this.state.selectKota.map(async (kota, i) => {
                var formData = new FormData();
                formData.append('code_events', row.code_event);
                formData.append('code_school_rep', this.props.userableData.userable.code);
                formData.append('id_event_kabkota', kota.pivot.id_event_kabkota);
    
                let url = '/event/cart';
    
                await axios.post(API_URL.superStudent+url,formData, {
                    headers: { 
                        'Authorization': `Bearer ${this.props.dataToken}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((reply)=>{
                    if (reply.status == 200) {
                        toast.success(reply.data.message);
                    } else {
                        toast.success(reply.data.message);
                    }
                }).catch(err => {
                    console.log(err);
                });
            });
        } else {
            var formData = new FormData();
            formData.append('code_events', row.code_event);
            formData.append('code_school_rep', this.props.userableData.userable.code);

            let url = '/event/cart';

            await axios.post(API_URL.superStudent+url,formData, {
                headers: { 
                    'Authorization': `Bearer ${this.props.dataToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((reply)=>{
                if (reply.status == 200) {
                    toast.success(reply.data.message);
                } else {
                    toast.success(reply.data.message);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    detailCartEvent(row) {
        this.props.history.push({
            pathname: `${process.env.PUBLIC_URL}/pages/university/eventdetail/`+row.id
        })
    }

    render() {

        let dollarUSLocale = Intl.NumberFormat('en-US');
        const detail = this.props.data;

        return (
            <Fragment>
                {
                    detail !== null ? 
                    <div className='col-xl-4 col-md-6 col-sm-1 mb-5'>
                        <div className='container'>
                            <div className='row card-promo-campus-top'>
                                <div className='col-lg-12 text-center'>
                                    <h6><strong>{detail.name}</strong></h6>
                                </div>
                            </div>
                            <div className='row text-start card-promo-campus-middle'>
                                <div className='col-lg-12 text-center mb-3' style={{height: '90px'}}>
                                    <img alt='imagess' style={{width: '100px'}} src={detail.image} />
                                </div>
                                <div className='col-lg-12 text-center mb-3'>
                                    {detail.jenis_event == 1 ? detail.jenis_event == 2 ? 'Info Session' : 'Education Expo' : 'Education Festival'}
                                </div>
                            </div>
                            {
                                detail.kota.map((pivot, j) => {
                                    return  <div className='row card-promo-campus-harga'>
                                                <div className='col-lg-5 text-left'>
                                                    <p>{pivot.nama_kabkota}</p>
                                                </div>
                                                <div className='col-lg-5 text-left'>
                                                    <p>AUD {pivot.pivot.harga}</p>
                                                </div>
                                                <div className='col-lg-1 text-left'>
                                                    <input id={pivot.id_kabkota} type="checkbox" name="hargaKota" value="Bike" onChange={(e) => this.pilihKota(e, pivot, j)} />
                                                </div>
                                            </div>
                                })
                            }
                            {
                                detail.kota.length > 0 ? 
                                <div className='row card-promo-campus-middle'>
                                    <div className='col-lg-5 text-left'>
                                        <h6><strong>Total</strong></h6>
                                    </div>
                                    <div className='col-lg-7 text-right'>
                                        <h6><strong>{this.state.totalKota != null ? dollarUSLocale.format(this.state.totalKota) : ''}</strong></h6>
                                    </div>
                                </div>
                                :
                                <div className='row card-promo-campus-middle'>
                                    <div className='col-lg-5 text-left'>
                                        <h6><strong>AUD</strong></h6>
                                    </div>
                                    <div className='col-lg-7 text-right'>
                                        <h6><strong>{detail.harga != null ? dollarUSLocale.format(detail.harga) : ''}</strong></h6>
                                    </div>
                                </div>
                            }
                            <div className='row text-start card-promo-campus-middle'>
                                <div className='col-lg-12 text-center'>
                                    <h6><strong>{Moment(detail.start_date).format('DD MMMM YYYY')}</strong></h6>
                                </div>
                            </div>
                            <div className='row text-start card-promo-campus-bottom'>
                            <div className='col-lg-6 text-center'>
                                    <button className='btn-sm btn-info' onClick={() => this.detailCartEvent(detail)}>Detail</button>
                                </div>
                                <div className='col-lg-6 text-center mb-3'>
                                    <button className='btn-sm btn-success' onClick={() => this.addCartEvent(detail)}>Add To Cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState, null)(withRouter(EventCard))