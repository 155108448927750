/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import Countup from 'react-countup';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import Moment from 'moment';
import Loader from '../../components/common/loader';
import setRangeDate from '../../helper/general-function';

class StudentsHomeComponent extends Component {

    constructor(props) {

        let date = setRangeDate('this_year')
        super(props)
        this.state = {
            open: false,
            consultantName:'',
            code_konsultan_category:'none',
            code_konsultan_LoA:'none',
            dataCategory:[],
            dataProgress:[],
            dataSource:[],
            totalStudents:[0,[]],
            totalStudentsNew:[0,[]],
            recapStudentGranted:[],
            rekapStudentKonsultan:[],
            dataCategoryStudent:[],
            studnetLoA:[],
            studnetEstimasiDate:[],
            studnetFinishStudy:[],
            filterStartLoA:Moment().startOf('month'),
            filterEndLoA:Moment().endOf('month'),
            filterStartEstimasi:Moment().startOf('month'),
            filterEndEstimasi:Moment().endOf('month'),
            filterStartFinishStudy:Moment().startOf('month'),
            filterEndFinishStudy:Moment().endOf('month'),
            dataStudentLoA:[],
            dataStudentEstimasi:[],
            dataStudentFinishStudy:[],
            dataConsultant:[],
            consultant:'',
            staticFilter:'',
            dinamicFilter:'none',
            date:'this_year',
            startDate: date.start,
            endDate: date.end,
            modalEdit: false,
            loadingRecapGranted:false,
            loadingPeformanceApps:false,
            arayCodeStudents:[]
        }
    }

    componentDidMount() {
        this.getStudentsAll();
        this.getStudentsNew();
        this.getRekapKonsultan();
        this.loadConsultant()
        this.getRecapGranted()

        if(this.props.codeRoles != 'RL006'){
            this.getStudentCategory();
            this.CountStudentLoA()
            this.CountStudentEstimasiIntake();
            this.CountStudentFinishStudy();
        }
    }

    loadConsultant(){
        axios.get(API_URL.superStudent+'/consultant/list-consultant',{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ dataConsultant: data })
        })
        .catch(error => console.log(error))
    }

    getStudentsAll() {
        const url = API_URL.superStudent+'/student/total-with-filter';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ totalStudents: data })
        })
        .catch(error => console.log(error))
    }

    getStudentCategory(){
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        formData.append('code_konsultan', this.state.code_konsultan_category)
        axios.post(API_URL.superStudent+'/count-student-category', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({dataCategoryStudent:result}))
        .catch(error => console.log(error))
    }

    CountStudentLoA(){
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartLoA.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndLoA.format('YYYY-MM-DD'))
        formData.append('code_konsultan', this.state.code_konsultan_LoA)
        axios.post(API_URL.superStudent+'/consultant-peformance/count-loa-data', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({ studnetLoA: result}))
        .catch(error => console.log(error))
    }

    getStudentLoA(){
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartLoA)
        formData.append('end_date', this.state.filterEndLoA)
        axios.post(API_URL.superStudent+'/student/loa-data', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({ dataStudentLoA : result}))
        .catch(error => console.log(error))
    }

    CountStudentEstimasiIntake(){
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartEstimasi.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndEstimasi.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/consultant-peformance/count-estimasi-data', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({ studnetEstimasiDate: result}))
        .catch(error => console.log(error))
    }

    getStudentEstimasi(){
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartEstimasi.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndEstimasi.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/student/estimasi-intake-data', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({ dataStudentEstimasi: result }))
        .catch(error => console.log(error))
    }

    CountStudentFinishStudy(){
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartFinishStudy.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndFinishStudy.format('YYYY-MM-DD'))
        axios.post(API_URL.superStudent+'/consultant-peformance/count-finish-study', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({ studnetFinishStudy: result}))
        .catch(error => console.log(error))
    }

    getStudentFinishStudy(){
        let formData = new FormData()
        formData.append('start_date', this.state.filterStartFinishStudy)
        formData.append('end_date', this.state.filterEndFinishStudy)
        axios.post(API_URL.superStudent+'/student/finish-study-data', formData,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => this.setState({ dataStudentFinishStudy: result }))
        .catch(error => console.log(error))
    }

    getStudentsNew() {
        const url = API_URL.superStudent+'/student/total-with-filter';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        formData.append('code_progress','PG001')
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ totalStudentsNew: data })
        });
    }

    getRecapGranted() {
        this.setState({ loadingRecapGranted: true })
        const url = API_URL.superStudent+'/student/count-progress-log';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ recapStudentGranted: data, loadingRecapGranted: false })
        });
    }

    getRekapKonsultan() {
        this.setState({ loadingPeformanceApps: true })
        const url = API_URL.superStudent+'/consultant-peformance/student-apps';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        axios.post(url, formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({rekapStudentKonsultan:data, loadingPeformanceApps: false})
        });      
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        },() => {
            if(this.state.date === 'dinammic_date'){
                this.setState({
                    dinamicFilter:'',
                    staticFilter:'none'
                })
            }
            else{
                this.setRangeDate()
            }
        })
    }
 
    handleLoADate = (e) => {
        this.setState({
            filterStartLoA: Moment(e.target.value).startOf('month'),
            filterEndLoA: Moment(e.target.value).endOf('month')
        },() => {
            this.CountStudentLoA()
        })
    }

    handleEstimasiDate = (e) => {
        this.setState({
            filterStartEstimasi: Moment(e.target.value).startOf('month'),
            filterEndEstimasi: Moment(e.target.value).endOf('month')
        },() => {
            this.CountStudentEstimasiIntake()
        })
    }

    handleFinishStudy = (e) => {
        this.setState({
            filterStartFinishStudy: Moment(e.target.value).startOf('month'),
            filterEndFinishStudy: Moment(e.target.value).endOf('month')
        },() => {
            this.CountStudentFinishStudy()
        })
    }

    setRangeDate() {
        let data = setRangeDate(this.state.date);
        this.setState({ 
            startDate: data.start,
            endDate: data.end
        },() => this.filter())
    }

    filter = () => {
       this.getStudentsAll()
       this.getStudentsNew()
       this.getRekapKonsultan()
       this.getRecapGranted()
       this.setState({
           showDetail:'none'
       })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },() => this.getStudentCategory())
    }

    handleEdit = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    
    render() {

        const recapGranted = [
            {
              Header: 'Area',
              accessor: 'area',
              style: {textAlign: 'center'},
            },
            {
              Header: 'Student Granteed',
              accessor: 'granted',
              style: {textAlign: 'center'},
            },
            {
              Header: 'J1 Granted',
              accessor: 'j_one',
              style: {textAlign: 'center'},
            },
            {
                Header: 'Tourist Granted',
                accessor: 'tourist',
                style: {textAlign: 'center'},
            },
            {
                Header: 'Rejection',
                accessor: 'rejection',
                style: {textAlign: 'center'},
            },
        ];

        const recapConsultant = [
            {
              Header: 'Konsultan',
              accessor: 'nama',
              style: {textAlign: 'center'},
            },
            {
              Header: 'Total Student Apps',
              accessor: 'student_user_count',
              style: {textAlign: 'center'},
            },
            {
              Header: 'Total Member Apps',
              accessor: 'member_user_count',
              style: {textAlign: 'center'},
            },
        ];

        const columns_LoA_consultan = [
            {
                Header: 'Consultant Code',
                accessor: 'code_konsultan',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Consultant Name',
                accessor: 'nama',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Total Students',
                accessor: 'total_students',
                style: {textAlign: 'center'},
                filterable: false,
            },

        ]

        const columns_LoA_students = [
            {
                Header: 'Code',
                accessor: 'code_students',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) =>(
                    <button style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => 
                        window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)    
                    }>{row.original.code_students}</button>
                )
            },
            {
                Header: 'Name',
                accessor: 'nama',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>{row.original.nama} <br/><strong>({row.original.progress.name_progress})</strong></>
                )
            },
            {
                Header: 'Kampus Tujuan',
                accessor: 'university.nama_sekolah',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Program',
                accessor: 'programs[0].program_name',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Consultant',
                accessor: 'consultant.nama',
                style: {textAlign: 'center'},
                filterable: false,
            },
        ]

 

        return (
            <Fragment>
                <div className="col-sm-12">
                    <div style={{marginBottom:'10px'}} className="row justify-content-md-center justify-content-xs-center">
                        <div style={{display:this.state.staticFilter}} className='col-md-3 col-sm-4 mb-2'>
                            <select name='date' value={this.state.date} onChange={this.handleInput} className='form-control'>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                                    <optgroup label='Quartal'>
                                        <option value='1'>Quartal I</option>
                                        <option value='2'>Quartal II</option>
                                        <option value='3'>Quartal III</option>
                                        <option value='4'>Quartal IV</option>
                                </optgroup>
                                <option value='dinammic_date'> --Dinammic Date-- </option>
                            </select>
                        </div>
                        <div style={{display:this.state.dinamicFilter}} className='row'>
                            <div className="col-md-4 mb-2">
                                <input className="form-control" type="date" onChange={(e) => this.setState({ startDate: Moment(e.target.value).format('YYYY-MM-DD')})} name="startDate"  value ={this.state.startDate} />
                            </div>
                            <div className="col-md-4 mb-2">
                                <input className="form-control" type="date" onChange={(e) => this.setState({ endDate: Moment(e.target.value).format('YYYY-MM-DD')})} name="endDate"  value ={this.state.endDate} />
                            </div>
                            <div className="col-md-4 btn-group" role='group'>
                                <button style={{color:'white'}} type='button' className='btn btn-primary btn-md' onClick={() => this.filter()} ><i className='fa fa-filter'></i> Filter</button>
                                <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                            </div>
                        </div>
                        <div className='col-sm-12 mb-3'>
                                   <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(this.state.startDate).format('DD MMMM yyyy')} - {Moment(this.state.endDate).format('DD MMMM yyyy')}</span>
                            </div>         
                    </div>
                </div>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1 justify-content-center">
                        <div className="col-lg-6 col-xs-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="mb-0" >Total Students</h5>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h1 className="font-primary counter">
                                            <Countup end={this.state.totalStudents[0]} />
                                        </h1>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div style={{fontSize:'12px'}} className='row m-0'>
                                        {
                                            this.state.totalStudents[1].map((item,index) => (
                                                <div key={index} className='col-md text-center font-weight-bold mb-2'>
                                                    <div className="mb-0" >{item.area}</div>
                                                    <Countup className='font-primary' end={item.total_students} />
                                                </div>
                                            ))
                                        }        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="mb-0">Progress New</h5>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h1 className="font-primary counter">
                                            <Countup end={this.state.totalStudentsNew[0]} />
                                        </h1>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div style={{fontSize:'12px'}} className='row m-0'>
                                        {
                                            this.state.totalStudentsNew[1].map((item,index) => (
                                                <div key={index} className='col-md text-center font-weight-bold mb-2'>
                                                    <div className="mb-0" >{item.area}</div>
                                                    <Countup className='font-primary' end={item.total_students} />
                                                </div>
                                            ))
                                        }        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.recapStudentGranted}
                                        columns={recapGranted}   
                                        defaultPageSize={7}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    />
                                     <Loader showLoading={this.state.loadingRecapGranted}/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-xs-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Consultant Peofrmance Apps</h5>
                                </div>
                                <div className="card-body">
                                    <ReactTable
                                        defaultPageSize={15}
                                        columns={recapConsultant}
                                        data={this.state.rekapStudentKonsultan}
                                    />
                                     <Loader showLoading={this.state.loadingPeformanceApps}/>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='card'>
                                <div className="card-header">
                                    <div className='row'>
                                            <div className='col-sm-6'>
                                                <h5>Student Caegory</h5>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className="select2-drpdwn-project select-options">
                                                    <select onChange={this.handleChange} className='form-control form-control-primary btn-square' name="code_konsultan_category" value={this.state.code_konsultan_category}>
                                                        <option key={0} value='none'>All Data</option>
                                                        {
                                                            this.state.dataConsultant.length > 0 ?
                                                            this.state.dataConsultant.map(consultant => (
                                                                <option key={consultant.code} value={consultant.code}>{consultant.name}</option>
                                                            ))
                                                            :
                                                            ''
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ul className="list-group">
                                        {
                                            this.state.dataCategoryStudent.length > 0 ?
                                            this.state.dataCategoryStudent.map((list, index) => (
                                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                    <strong> {list.category} </strong>
                                                    <span className="badge badge-primary counter digits">{list.students_count}</span>
                                                </li>
                                            ))
                                            :''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                        </div>
                        <div className='col-xl-6'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-7'>
                                            <h5>STUDENT START STUDY</h5>
                                        </div>
                                        <div className='col-sm-5'>
                                            <input className='form-control' value={this.state.filterStartLoA.format('YYYY-MM')} onChange={this.handleLoADate} type='month'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.studnetLoA}
                                        columns={columns_LoA_students}   
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-7'>
                                            <h5>STUDENT ESTIMASI INTAKE</h5>
                                        </div>
                                        <div className='col-sm-5'>
                                            <input className='form-control' value={this.state.filterStartEstimasi.format('YYYY-MM')} onChange={this.handleEstimasiDate} type='month'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.studnetEstimasiDate}
                                        columns={columns_LoA_consultan}   
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                        SubComponent={ row => {
                                            return(
                                                <ReactTable
                                                    data={row.original.students}
                                                    columns={columns_LoA_students}
                                                    defaultPageSize={4}
                                                    className="-striped -highlight"
                                                    showPaginationBottom={false}
                                                />
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-sm-7'>
                                            <h5>STUDENT FINISH STUDY</h5>
                                        </div>
                                        <div className='col-sm-5'>
                                            <input className='form-control' value={this.state.filterEndFinishStudy.format('YYYY-MM')} onChange={this.handleFinishStudy} type='month'/>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <ReactTable
                                        data={this.state.studnetFinishStudy}
                                        columns={columns_LoA_students}   
                                        defaultPageSize={15}
                                        className="-striped -highlight"
                                        showPaginationBottom={true}
                                    />
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    codeRoles: state.Login.detailUser.code_roles,
    dataUser: state.Login.detailUser
})

export default connect(reduxState, null) (StudentsHomeComponent);