import React, { Component } from 'react';
import Moment from 'moment';
import axios from 'axios'
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import setRangeDate from '../../../helper/general-function';

import API_URL from '../../../ApiUrl';
import EnglishCoursePromo from './english-course-promo';

class EnglishCourseHome extends Component {

    constructor(props) {
        super(props)
        let data = setRangeDate('this_year')
        this.state = {
            startDate: data.start,
            endDate: data.end,
            dataIncome: {
                columns: [],
                rows: []
            }
        }
    }

    componentDidMount() {
        this.loadDataIncome()
    }

    loadDataIncome() {
        const url = API_URL.superStudentV2 + '/consultant-peformance/income-by-type';
        let formData = new FormData();
        formData.append('start_date', this.state.startDate)
        formData.append('end_date', this.state.endDate)
        formData.append('code_jenis_pembayaran', 'JPB012')
        axios.post(url, formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
        }).then(response => response.data.data).then((data) => {
            this.setState({ dataIncome: data })
        });
    }

    handlePickerStartMonth = (e) => {
        this.setState({ startDate: Moment(e).startOf('month').format('YYYY-MM-DD') }, () => this.loadDataIncome())
    }

    handlePickerEndMonth = (e) => {
        this.setState({ endDate: Moment(e).endOf('month').format('YYYY-MM-DD') }, () => this.loadDataIncome())
    }

    render() {
        return (
            <>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className='col-md-4 col-sm-6'>
                                            <h5>Income English Course</h5>
                                        </div>
                                        <div className='col-md-6 col-sm-12'>
                                            <div className='form-group'>
                                                <div className='input-group'>
                                                    <DatePicker dateFormat='MMMM yyyy' dateFormatCalendar='MMM' selected={Moment(this.state.startDate)._d} showMonthYearPicker className='form-control' onChange={this.handlePickerStartMonth} />
                                                    <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.endDate)._d} showMonthYearPicker className='form-control' onChange={this.handlePickerEndMonth} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='table-responsive'>
                                        <table style={{ fontSize: '10px' }} className='table table-striped table-hover table-borderedfor'>
                                            <thead className='tbl-strip-thad-bdr'>
                                                <tr className='table-secondary'>
                                                <th scope="col">#</th>
                                                    {
                                                        this.state.dataIncome.columns.length > 0 ?
                                                            this.state.dataIncome.columns.map((item, index) => (
                                                                <th key={'columns' + index}>{item}</th>
                                                            ))
                                                            : ''
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.dataIncome.rows.length > 0 ?
                                                        this.state.dataIncome.rows.map((item, index) => (
                                                            <tr key={'rows'+index}>
                                                                  <th>{index+1}</th>
                                                                {
                                                                    item.map((cell, index) => (
                                                                        <th key={'cell'+index}>{cell}</th>
                                                                    ))
                                                                }
                                                            </tr>
                                                        ))
                                                        : ''
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <EnglishCoursePromo />
                </div>
            </>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(EnglishCourseHome);