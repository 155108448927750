import React, { Fragment, Component } from 'react';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import { ExportCSV } from '../../../services/ExportCSV';
import Countup from 'react-countup';
import Moment from 'moment';

import Loader from '../loader'
import {matchSorter} from 'match-sorter';
import MemberLocation from '../../dashboard/partition/member-location'

class DatatableMembership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            startDate:Moment().startOf('year').format('YYYY-MM-DD'),
            endDate:Moment().endOf('year').format('YYYY-MM-DD'),
            showLoading: false,
        }
    }
  componentDidMount(){
      this.getMembershipData()
  }

  getMembershipData = async (data = null) =>{
    this.setState({ showLoading: true })
    await axios.post(API_URL.superStudent+'/members/member-by-date', data, {
        headers: {
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
    .then(response => response.data.data)
    .then(result => {
      this.setState({
        data: result,
        showLoading:false
      })
    })
    .catch(err => {
      this.setState({ showLoading:false })
    })
  }

  handleInput = (e) => {
    this.setState({
        [e.target.name] : e.target.value
    })  
  }

  filter = () => {
    var formData = new FormData();
    formData.append('start_date', this.state.startDate)
    formData.append('end_date', this.state.endDate)
    this.getMembershipData(formData)
    this.getRekapKonsultan(formData)
 }

    render() {
      const columns = [
        {
          Header: 'Code Member',
          accessor: 'kode_member',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["kode_member"] }),
          filterAll: true
        },
        {
          Header: 'Nama Lengkap',
          accessor: 'nama_lengkap',
          style: {textAlign: 'left'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["nama_lengkap"] }),
          filterAll: true
        },
        {
          Header: 'Telp',
          accessor: 'no_wa',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["no_wa"] }),
          filterAll: true,
          Cell:(row) => (
            <span style={{color:row.original.is_phone_verified === 1 ? 'green' : 'red' }}>{row.original.no_wa}</span>
          ),
        },
        {
          Header: 'Email',
          accessor: 'email',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["email"] }),
          Cell:(row) => (
            <span style={{color:row.original.is_email_verified === 1 ? 'green' : 'red' }}>{row.original.email}</span>
          ),
          filterAll: true
        },
        {
          Header: 'Consultant',
          accessor: 'consultant_name',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["consultant_name"] }),
          filterAll: true
        },
        {
          Header: 'Sekolah ',
          accessor: 'sekolah_siswa',
          style: {textAlign: 'center'},
          filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["sekolah_siswa"] }),
          filterAll: true
        },
        {
          id:'date_approval',
          Header: 'Tanggal Aktif',
          accessor: a => {
            return Moment(a.date_approval)
            .local()
            .format("DD/MM/YYYY")
          },
          style: {textAlign: 'center'},
          filterable: false
        },
      ]

        const csvData = this.state.data;
        return (
            <Fragment>
              <div className="row justify-content-center mt-1">
                    <div className="col-md-3 col-sm-6">
                        <h5>Total Member : <span className="badge badge-info"><Countup end={this.state.data.length} /></span> </h5>
                    </div>
                    <div className="col-md-3 col-sm-6 mb-1">
                       <ExportCSV csvData={csvData} fileName={"data-membership"} />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div style={{marginBottom:'10px'}} className="row">
                            <div className="col-md-5 col-sm-6 mb-2">
                                <input className="form-control" type="date" onChange={this.handleInput} name="startDate"  value ={this.state.startDate} />
                            </div>
                            <div className="col-md-5 col-sm-6 mb-2">
                                <input className="form-control" type="date" onChange={this.handleInput} name="endDate"  value ={this.state.endDate} />
                            </div>
                            <div className="col-md-2 col-sm-6"><Button onClick={() => this.filter()}  color="primary"><i className="fa fa-filter"></i></Button></div>
                        </div>
                    </div>
                  <div className="col-md-12">
                  <ReactTable
                        data={this.state.data}
                        columns={columns}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                          String(row[filter.id]) === filter.value}
                        defaultPageSize={10}
                        className="-striped -highlight"
                      />
                      <Loader showLoading={this.state.showLoading}/>
                  </div>
                  <div className='col-xl-6'>
                      <MemberLocation start_date={this.state.startDate} end_date={this.state.endDate} />
                  </div>
              </div>
            </Fragment>
        );
    }
};

const reduxState = (state) => ({
  dataId: state.Login.detailUser.id_users,
  dataToken: state.Login.lgnInitialize,
})

const reduxDispatch = (dispatch) => ({
  resettoken: (data, headers) => dispatch(resettoken(data, headers))
 })

export default connect(reduxState, reduxDispatch)(withRouter(DatatableMembership));