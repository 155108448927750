/* eslint-disable eqeqeq, no-unused-vars */

import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../components/common/loader';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Moment from 'moment';
import Breadcrumb from '../../components/common/breadcrumb';
import {matchSorter} from 'match-sorter';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { saveAs } from "file-saver";

class PaymentAll extends Component {

    constructor(props) {
        super(props);
        this.state = {
            operation: "",
            modal: false,
            modalDetail: false,
            errors: {},
            row: [],
            filtered:[],
        }
    }

    componentDidMount() {
        this.getDataJenisPembayaran();
        this.getDataStatusPembayaran();
        this.getDataPayment();
    }

    getDataJenisPembayaran() {
        const url = API_URL.superStudent+'/master/jenis-pembayaran';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ 
                jenisPayment: data 
            })
        })
    }

    getDataStatusPembayaran() {
        const url = API_URL.superStudent+'/master/status-pembayaran';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ 
                statusPayment: data 
            })
        })
    }

    getDataPayment() {
        const url = API_URL.superStudent+'/finance/payment-all';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ dataPayment: data })
        })
    }

    SetFilterJenisPayment = (e) => {
        this.setState({
            codeJenisPayment: e.target.value
        }, () => {
            this.filterDataByCustom(this.state.codeJenisPayment, "code_jenis_pembayaran");
        })
    }

    SetFilterStatusPayment = (e) => {
        this.setState({
            codeStatusPayment: e.target.value
        }, () => {
            this.filterDataByCustom(this.state.codeStatusPayment, "code_status_pembayaran");
        })
    }

    filterDataByCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;

        if (filtered.length) {
            filtered.forEach((filter, i) => {
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length) filtered.splice(i, 1);
                    else filter["value"] = value;
            
                    insertNewFilter = 0;
                }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }

        this.setState({ filtered: filtered });
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    closeDialog() {
        this.setState({
            modal: false
        })
    }

    editRow(row) {
        this.setState({
            operation: 'edit',
            modal: true,
            row: row
        })
    }

    viewImage(img) {
        this.setState({
            modalDetail: true,
            image: img.bukti_pembayaran
        })
    }

    downloadFile = (url, filename) => {
        saveAs(url,filename.substring(filename.lastIndexOf('/')));
    }

    sendData() {
        this.setState({
            modal: false
        })
    }

    renderDialog() {
        const { modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{this.state.row.nama_lengkap}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Margin</label>
                                    <input className="form-control" name="margin" type="number" id="margin" onChange={(e) => this.handleInput(e)} value={this.state.margin} />
                                    <span>{this.state.errors.margin}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDetailDialog() {
        const modal = this.state.modalDetail;
        const url_doc = [{ uri:this.state.image }]
        const url_download = this.state.image;

        return (
            <Modal className="modal-dialog" isOpen={modal} toggle={() => this.setState({ modalDetail: false })} size="lg">
                <ModalHeader toggle={() => this.setState({ modalDetail: false })}>Detail Image</ModalHeader>
                <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <div>
                        <DocViewer 
                            pluginRenderers={DocViewerRenderers} 
                            documents={url_doc} 
                            config={{
                                header: {
                                    disableHeader: false,
                                    disableFileName: false,
                                    retainURLParams: false
                                }
                            }}
                        />
                        <div className="form-row" style={{textAlign: "end"}}>
                            <div className="col-md-12">
                                <button onClick={() => this.downloadFile(url_download, 'payment')} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    render() {

        let idr = Intl.NumberFormat('us-US');

        const columns = [
            {
                Header: 'Kode Payment',
                accessor: 'code_pembayaran',
                filterable: true,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["code_pembayaran"] }),
                    filterAll: true
            },
            {
                Header: 'Date',
                accessor: 'created',
                filterable: false,
                style: {
                    textAlign: 'center'
                },
                Cell: (row) => (
                    <div>
                        {Moment(row.original.created).format('DD MMMM YYYY')}
                    </div>
                )
            },
            {
                Header: 'Student',
                accessor: 'student.nama',
                filterable: true,
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["student.nama"] }),
                    filterAll: true
            },
            {
                Header: 'Jenis Pembayaran',
                accessor: 'jenis_pembayaran.jenis_pembayaran',
                style: {textAlign: 'center'},
                filterable: true,
                Filter: () => 
                <select value={this.state.codeJenisPayment || ''} onChange={(e) => this.SetFilterJenisPayment(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.jenisPayment.map( item => {
                            return <option key={item.code_user} value={item.code_jenis_pembayaran}>{item.jenis_pembayaran}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'Status Pembayaran',
                accessor: 'status_pembayaran.status_pembayaran',
                style: {textAlign: 'center'},
                filterable: true,
                Filter: () => 
                <select value={this.state.codeStatusPayment || ''} onChange={(e) => this.SetFilterStatusPayment(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.statusPayment.map( item => {
                            return <option key={item.code_user} value={item.code_status_pembayaran}>{item.status_pembayaran}</option>
                        })
                    } 
                </select>,
            },
            {
                Header: 'Amount',
                accessor: 'nominal',
                filterable: false,
                style: {
                    textAlign: 'end'
                },
                Cell: (row) => (
                    <div>
                        {row.original.code_mata_uang} {idr.format(Math.ceil(row.original.nominal))}
                    </div>
                )
            },
            {
                Header: 'Oprasional',
                accessor: 'rate',
                filterable: false,
                style: {
                    textAlign: 'end'
                },
                Cell: (row) => (
                    <div>
                        {row.original.code_mata_uang} {idr.format(Math.ceil(row.original.rate))}
                    </div>
                )
            },
            {
                Header: 'Laba',
                accessor: 'total_rupiah',
                filterable: false,
                style: {
                    textAlign: 'end'
                },
                Cell: (row) => (
                    <div>
                        {row.original.code_mata_uang} {idr.format(Math.ceil(row.original.total_rupiah))}
                    </div>
                )
            }
        ]

        return (
            <Fragment>
                <Breadcrumb title="Student Payment" parent="Student Payment" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {
                                            this.state.dataPayment.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataPayment}
                                                filtered={this.state.filtered}
                                                columns={columns}
                                                defaultPageSize={50}
                                                className="-striped -highlight"
                                                showPaginationBottom={true}
                                                onFilteredChange={(filtered, column, value) => {
                                                    this.filterDataByCustom(value, column.id || column.accessor);
                                                }}
                                                SubComponent={row => {
                                                    const data = row.original;
                                                    return (
                                                        <Container>
                                                            <h4>Bukti Pembayaran</h4>
                                                            <br/>
                                                            <Row>
                                                                <Col lg='12'>
                                                                    <div onClick={() => this.viewImage(row.original)}>
                                                                        {
                                                                            row.original.bukti_pembayaran != 'Midtrans' || row.original.bukti_pembayaran != 'Midtrans English Course' ? 
                                                                            <img alt='imagess' style={{width: '100px'}} src={row.original.bukti_pembayaran} />
                                                                            :
                                                                            ''
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    )
                                                }}
                                            /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDetailDialog()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(PaymentAll));