/* eslint-disable eqeqeq */

import React, {Component} from "react"
import ReactTable from 'react-table'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import CKEditors from "react-ckeditor-component"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from "moment"
import { withRouter } from "react-router";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

class DataTablesPoint extends Component {

    constructor(props){
        super(props)
        this.state  = {
            dataPoint: []
        }
    }

    componentDidMount(){
        this.loadDataPoint()
    }

    loadDataPoint = () => {
        axios.get(API_URL.superStudent+'/point', {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataPoint: response.data.data }))
        .catch(error => console.log(error))
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    renderModalPoint(){
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalPoint} toggle={() => this.setState({ modalPoint: false })} >
                    <ModalHeader toggle={() => this.setState({ modalPoint: false })}>Form Point</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>
                                    <div className='form-group'>
                                        <label className='control-label'>Title</label>
                                        <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Nominal</label>
                                        <input className='form-control' type='text' name='nominal' value={this.state.nominal} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Periode</label>
                                        <input type='date' className='form-control' name='periode' value={this.state.periode} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Description</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.description}
                                            events={{
                                                    "change": this.onChange.bind(this),
                                            }}
                                            config={{
                                                toolbar:[
                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                    ['Indent','Outdent'],
                                                    ['Link','Unlink'],
                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                ]
                                            }}     
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Syarat dan Ketentuan</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.syarat_ketentuan}
                                            events={{
                                                    "change": this.onChangeSyarat.bind(this),
                                            }}
                                            config={{
                                                toolbar:[
                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                    ['Indent','Outdent'],
                                                    ['Link','Unlink'],
                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                ]
                                            }}     
                                        />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Image</label>
                                        <input className='form-control' type='file' onChange={this.handleImages}/>
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Thumbnail</label>
                                        <input className='form-control' type='file' onChange={this.handleThumbnail}/>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button disabled={this.state.btnAddDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnTextSave}</Button>
                    <Button  color="secondary" onClick={() => this.setState({ modalPoint: false })}>Cancel</Button>
                    </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){

        const columns = [
            {
                Header:'#',
                accessor:'thumbnail',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div className="image-sm-size align-middle">
                        <img className="img-radius img-50 align-top" src={row.original.thumbnail !== null ? row.original.thumbnail : ''} alt="" />
                    </div>
                ),
            },
            {
                Header: 'Title',
                accessor: 'title',
                style: {textAlign: 'center'},
                filterable: false,
            },
            {
                Header: 'Nominal',
                accessor: 'nominal',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    this.formatRupiah(row.original.nominal)
                )
            },
            {
                Header: 'Periode',
                accessor: 'periode',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <>{moment(row.original.periode).format('DD MMM YYYY')}</>
                )
            },
            {
                Header: 'Status',
                accessor: 'status',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    row.original.status == 1 ? <span className='badge bg-success'>Active</span> : <span className='badge bg-danger'>Inactive</span>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div className="row">
                      <div className="col-md-3">
                        <button 
                            onClick={() => this.props.history.push({
                                pathname: `${process.env.PUBLIC_URL}/pages/point/form-point/`+row.original.id,
                                state: row.original,
                            })}
                            className="btn btn-info btn-pill btn-sm" type="button" style={{border:'none'}} >edit
                        </button>
                      </div>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]
        return (
            <>
                <div className='card' >
                    <div className='card-header'>
                        <Link className="btn btn-success mb-3" style={{color:'white'}} to={'/pages/point/form-point'}> Tambah Data</Link>
                    </div>
                    <div className='card-body'>
                        <ReactTable
                            data={this.state.dataPoint}
                            filterable
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            SubComponent={row => {
                                return(
                                  <div style={{padding:'20px'}} className='row'>
                                     <div dangerouslySetInnerHTML={{ __html: row.original.description }} />             
                                  </div>
                                )
                              }}
                        /> 
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})


export default connect(reduxState,null)(withRouter(DataTablesPoint));