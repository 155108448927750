/* eslint-disable eqeqeq */

import React, {Component} from "react"
import ReactTable from 'react-table'
import API_URL from '../../ApiUrl'
import axios from 'axios'
import CKEditors from "react-ckeditor-component"
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'


class GetPoint extends Component {

    constructor(props){
        super(props)
        this.state = {
            dataGetPoint: [],
            modallPoint:false,
            id:'',
            title:'',
            description:'',
            nominal:'',
            status:'',
            btnDisabled:false,
            btnText:'Save',
            btnAction:''

        }
    }

    componentDidMount(){
        this.loadDataGetPoint()
    }

    addPoint = () => {
        this.setState({
            modallPoint:true,
            title:'',
            description:'',
            nominal:'',
            status:'',
            btnAction:'add'
        })
    }

    editPoint = (item) => {
        this.setState({
            modallPoint: true,
            id: item.id,
            title: item.title,
            description: item.description,
            nominal: item.nominal,
            status: item.status,
            btnAction: 'update'
        })
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loadDataGetPoint(){
        axios.get(API_URL.superStudent+'/get-point', {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({ dataGetPoint: response.data.data }))
        .catch(error => console.log(error))
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            description: newContent
        })
    }

    saveData(){
        let formData = new FormData()
        formData.append('title', this.state.title)
        formData.append('description', this.state.description)
        formData.append('nominal', this.state.nominal)
        formData.append('status', this.state.status)
        this.setState({
            btnDisabled:true,
            btnText:'Saving...'
        })
        let url = 'get-point'

        if (this.state.btnAction === 'update'){
            formData.append('_method','PATCH');
            url = 'get-point/'+this.state.id
        }

        axios.post(API_URL.superStudent+'/'+url, formData, {
            headers:{ Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                btnDisabled:false,
                btnText:'Save',
                modallPoint:false
            })
            this.loadDataGetPoint()
            toast.success('Data added successfully')
        })
        .catch(error => {
            console.log(error)
            this.setState({
                btnDisabled:false,
                btnText:'Save',
                modallPoint:false
            })
            toast.error('Something wrong')
        })

    }

    renderModalPoint(){
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modallPoint} toggle={() => this.setState({ modallPoint: false })} >
                    <ModalHeader toggle={() => this.setState({ modallPoint: false })}>Form Point</ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <div className='col-md-12'>
                                <form>
                                    <div className='form-group'>
                                        <label className='control-label'>Title</label>
                                        <input className='form-control' type='text' name='title' value={this.state.title} onChange={this.handleInput} />
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Status</label>
                                        <select name="status" className="form-control" value={this.state.status} onChange={this.handleInput}    >
                                            <option value=''>--Atur Status--</option>
                                            <option value={1}>Active</option>
                                            <option value={0}> Inactive</option>
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label className='control-label'>Description</label>
                                        <CKEditors
                                            activeclassName="p10"
                                            content={this.state.description}
                                            events={{
                                                    "change": this.onChange.bind(this),
                                            }}
                                            config={{
                                                toolbar:[
                                                    ["Bold","Italic",'Strike','RemoveFormat'],
                                                    ['Indent','Outdent'],
                                                    ['Link','Unlink'],
                                                    ['Styles','Format','Table','Blockquote','Maximize'],
                                                    ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                ]
                                            }}     
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveData()}>{this.state.btnText}</Button>
                    <Button  color="secondary" onClick={() => this.setState({ modallPoint: false })}>Cancel</Button>
                    </ModalFooter>
              </Modal>
            </div>
        )
    }

    render(){

        const columns = [
            {
                Header: 'Title',
                accessor: 'title',
                style: {textAlign: 'center'},
                filterable: false,
            },
            // {
            //     Header: 'Nominal',
            //     accessor: 'nominal',
            //     style: {textAlign: 'center'},
            //     filterable: false,
            //     Cell: (row) => (
            //         this.formatRupiah(row.original.nominal)
            //     )
            // },
            {
                Header: 'Status',
                accessor: 'status',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    row.original.status == 1 ? <span className='badge bg-success'>Active</span> : <span className='badge bg-danger'>Inactive</span>
                )
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editPoint(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil"></i></button> &nbsp;
                    </div>
                  ),
                style: {
                    textAlign: 'center'
                },
                width:100,
                sortable: false,
                filterable: false
            }
        ]
        return(
            <>
                <div className='card'>
                    <div className="card-header">
                        <button onClick={this.addPoint} className="btn btn-success">Tambah Data</button>
                    </div>
                    <div className='card-body'>
                        <ReactTable
                            data={this.state.dataGetPoint}
                            filterable
                            columns={columns}
                            defaultPageSize={10}
                            className="-striped -highlight"
                            showPaginationBottom={true}
                            SubComponent={row => {
                                return(
                                  <div style={{padding:'20px'}} className='row'>
                                     <div dangerouslySetInnerHTML={{ __html: row.original.description }} />             
                                  </div>
                                )
                              }}
                        /> 
                    </div>
                </div>
                {this.renderModalPoint()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(GetPoint)