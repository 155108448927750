/* eslint-disable eqeqeq */

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import ReactTable from 'react-table';
import { Container } from 'reactstrap';
import setRangeDate from '../../helper/general-function';

class ScheduleInvoiceHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            total_outstanding:0,
            total_paid:0,
            total_cancel:0,
            total_all:0,
            dataCart:[],
            dataMataUang: [],
            dataReport: [],
            month: Moment().format('YYYY-MM'),
            date:'',
            currency: 'AUD',
            formData:{start_date:Moment().startOf('month'), end_date: Moment().endOf('month')},
            start_date: Moment().startOf('month'),
            end_date: Moment().endOf('months'),
            total_outs: 0,
            total_pending: 0,
            total_sent: 0,
            total_new: 0
        }
    }

    componentDidMount() {
        this.getMataUang();
        this.getStudentsInvoice();
    }

    getMataUang() {
        const url = API_URL.superStudent+'/master/mata-uang';
        axios.get(url,{
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        }).then(response => response.data)
        .then((data) => {
            this.setState({ dataMataUang: data.data })
        })
    }

    getStudentsInvoice() {
        let formData = new FormData();
        formData.append('start_date', this.state.date != "all_time" ? Moment(this.state.start_date).format('YYYY-MM-DD') : "")
        formData.append('end_date', this.state.date != "all_time" ? Moment(this.state.end_date).format('YYYY-MM-DD'): "")
        formData.append('currency', this.state.currency)

        const url = API_URL.superStudent+'/student/invoice-report';
        axios.post(url, formData, { 
            headers:{ 'Authorization': `Bearer ${this.props.dataToken}`} 
        }).then(response => {
            console.log(response.data.data)
            this.setState({ dataReport: response.data.data})
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeDates()
        })
    }

    handleChangeMonth = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.setRangeMonth()
        })
    }

    handleChangeCurrency = (e) => {
        this.setState({
            [e.target.name]:e.target.value
        }, () => {
            this.getStudentsInvoice()
        })
    }

    setRangeDates() { 
        let data = setRangeDate(this.state.date);
        this.setState({ 
            start_date: data.start,
            end_date: data.end,
        }, () => {
            this.getStudentsInvoice()
        })
    }

    setRangeMonth() {
        let start_date = Moment(this.state.month+"-01");
        let end_date  = Moment(start_date).endOf('month');

        this.setState({ 
            start_date,
            end_date
        }, () => {
            this.getStudentsInvoice()
        })
    }
    
    render() {
        let idr = Intl.NumberFormat('us-US');

        const columns = [
            {
                Header: 'Currency',
                accessor: 'currency',
                filterable: false
            },
            {
                Header: 'Total New',
                accessor: 'new',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(row.original.new.toFixed(2))} </div>
                    )
                }
            },
            {
                Header: 'Total Paid',
                accessor: 'paid',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(row.original.paid.toFixed(2))} </div>
                    )
                }
            },
            {
                Header: 'Total Pending',
                accessor: 'pending',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(row.original.pending.toFixed(2))} </div>
                    )
                }
            },
            {
                Header: 'Total Cancel',
                accessor: 'cancel',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(row.original.cancel.toFixed(2))} </div>
                    )
                }
            },
            {
                Header: 'Total Sent',
                accessor: 'send',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(row.original.send.toFixed(2))} </div>
                    )
                }
            },
            {
                Header: 'Total Outstanding',
                accessor: 'outstanding',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(row.original.outstanding.toFixed(2))} </div>
                    )
                }
            },
            {
                Header: 'Total All',
                accessor: 'total_all',
                filterable: false,
                style: {textAlign: 'right'},
                Cell: (row) => {
                    return (
                        <div> {idr.format(Math.ceil(row.original.new + row.original.paid + row.original.pending + row.original.cancel + row.original.send + row.original.outstanding))} </div>
                    )
                }
            },
        ]

        return (
            <Fragment>
                <div className="tab-content active default" id="tab-1">
                    <div className="row m-1 mb-5">
                        <div className="col-xl-6 col-sm-6">
                            <input className='form-control digits' name='month' type='month' defaultValue={this.state.month} onChange={this.handleChangeMonth}></input>
                        </div>
                        <div className="col-xl-6 col-sm-6">
                            <select name='date' value={this.state.date} onChange={this.handleChange} className='form-control'>
                                <option value=''>--Choose Time--</option>
                                <option value='all_time'>All Time</option>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                                <optgroup label='Quartal'>
                                    <option value='1'>Quartal I</option>
                                    <option value='2'>Quartal II</option>
                                    <option value='3'>Quartal III</option>
                                    <option value='4'>Quartal IV</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div className="row m-1">
                        <div className="col-xl-12 col-sm-12">
                            <span style={{fontSize:'1.5em'}} className='f-w-600 mb-5'>{this.state.date != "all_time" ? Moment(this.state.start_date).format('DD MMMM yyyy') : "ALL"} - {this.state.date != "all_time" ? Moment(this.state.end_date).format('DD MMMM yyyy') : "TIME"}</span>
                            <div className="card">
                                <div className="card-body">
                                    {this.state.dataReport.length > 0 ? 
                                        <ReactTable
                                            data={this.state.dataReport}
                                            columns={columns}
                                            filterable
                                            defaultFilterMethod={(filter, row) =>
                                                String(row[filter.id]) === filter.value}
                                            defaultPageSize={100}
                                            className="-striped -highlight"
                                            SubComponent={row => {
                                                const data = row.original.detail;
                                                return(
                                                    <Container className='mt-3 mb-3'>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>University</th>
                                                                    <th>New</th>
                                                                    <th>Paid</th>
                                                                    <th>Pending</th>
                                                                    <th>Cancel</th>
                                                                    <th>Send</th>
                                                                    <th>Outstanding</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {data.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td >{item.university}</td>
                                                                    <td className='text-right'>{idr.format(item.value.new.toFixed(2))}</td>
                                                                    <td className='text-right'>{idr.format(item.value.paid.toFixed(2))}</td>
                                                                    <td className='text-right'>{idr.format(item.value.pending.toFixed(2))}</td>
                                                                    <td className='text-right'>{idr.format(item.value.cancel.toFixed(2))}</td>
                                                                    <td className='text-right'>{idr.format(item.value.send.toFixed(2))}</td>
                                                                    <td className='text-right'>{idr.format(item.value.outstanding.toFixed(2))}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </Container>
                                                )
                                            }}
                                        />
                                    : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null) (ScheduleInvoiceHome);