import React, { Component } from 'react';
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';
import { User, List } from 'react-feather';
import Breadcrumb from '../../components/common/breadcrumb';
class Merchandise extends Component{

    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    render(){
        return(
            <>
            <Breadcrumb title="MERCHANDISE" />
                <div className='container-fluid'>
                    <div className='row theme-tab'>
                        <div className='col-sm-12'>
                            <RoutedTabs tabClassName="tab-link" activeTabClassName="active" >
                                <div className='tabs tab-title'>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/merchandise/produk`}>
                                        <User /> Produk Merchandise
                                    </NavTab>
                                    <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/merchandise/order`}>
                                        <List /> Order
                                    </NavTab>
                                </div>
                            </RoutedTabs>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/merchandise`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/merchandise/produk`} />}
                                />
                                <Route path={`${process.env.PUBLIC_URL}/pages/merchandise/order`}/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default Merchandise