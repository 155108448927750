/* eslint-disable react-hooks/exhaustive-deps */

import React ,{useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';

const CardTaskList = ({start_date, end_date}) => {

    const[tasklist,setTaskList] = useState([])

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }));

    useEffect(() => {
        loadDataTaskListByConsultant()
    },[start_date, end_date])

    const loadDataTaskListByConsultant = () => {
        let formData = new FormData ()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('code_consultant', dataUser.code_konsultan)
        axios.post(API_URL.superStudent+'/todo/list-todo-by', formData, {
            headers:{ Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            setTaskList(result);
        })
        .catch(error => console.log(error))
    }

    return( 
        <>
            <div style={{height: '400px', maxHeight: '400px'}} className='card scroll recent-notification'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Task List</h6>
                </div>
                <div className='card-body'>
                    {
                        tasklist.length > 0 ?
                        tasklist.map((item,index) => (
                        <div style={{marginBottom:10}} key={index} className="media">
                            <strong>{Moment(item.created_at).format('DD MMM')}</strong>
                            <div className="media-body">
                                <span style={{fontSize:11}}>{item.title}</span>
                                <div></div>
                                {
                                    item.category != null ?
                                    <span style={{color:'black', fontSize:12}}>by {item.user.username}, <strong>{item.category != null ? item.category.name : 'general'}</strong></span> 
                                    :
                                    <span></span>
                                }
                                
                            </div>
                            <div style={{float:'right'}}><span className={'badge bg-'+item.status.label}>{item.status.name}</span></div>
                        </div> 
                        ))
                        :
                        'Data Kosong'
                    }    
                </div>
            </div>
        </>
    )

}

export default CardTaskList;

