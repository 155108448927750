/* eslint-disable no-unused-vars */

import { createStore, applyMiddleware } from 'redux';

// middlewares
import thunkMiddleware from 'redux-thunk';

// Import custom components
import {persistReducer } from 'redux-persist'
import storage from "redux-persist/es/storage";
import reducers from '../reducers/index';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

const persistConfig = {
    key: "root",
    storage,
  };

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    }catch(e){
        return undefined
    }
}

/**
 * Create a Redux store that holds the app state.
 */

const persistedReducer = persistReducer(persistConfig, reducers)

const devTools =
  process.env.NODE_ENV === "production"
    ? applyMiddleware(thunkMiddleware)
    : composeWithDevTools(applyMiddleware(thunkMiddleware));

// const store = createStore(persistedReducer,   compose(
//     applyMiddleware(thunkMiddleware, logger),
//     //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
//     window.devToolsExtension ? window.devToolsExtension() : function (f) {
//         return f;
//     }
// ));

export const store = createStore(persistedReducer, devTools);
const unsubscribe = store.subscribe(() => {
    const state = store.getState();
    saveToLocalStorage(state);
});


export default store;