/* eslint-disable eqeqeq, no-useless-concat */

import React, { Component } from "react"
import ReactTable from 'react-table'
import axios from 'axios'
import { connect } from 'react-redux'
import { matchSorter } from 'match-sorter'
import Loader from "../../common/loader"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import API_URL from "../../../ApiUrl"

class StudentFollowUp extends Component {

  constructor(props) {
    super(props)
    this.state = {
      leadsData: [],
      dataConsultant: [],
      min_value: 14,
      max_value: 30,
      type: '1',
      showLoading: false,
      btnDisabled: false,
      typeUpdate: '',
      dataNoted: '',
      code_students: '',
      code_konsultan: '',
      modalNoted: false,
      modalUpdate: false
    }
  }

  componentDidMount() {
    this.loadDataLeads()
  }

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  loadDataLeads() {
    this.setState({ showLoading: true })
    let formData = new FormData()
    formData.append('code_konsultan', this.props.dataUser.code_konsultan)
    formData.append('min_value', this.state.min_value)
    formData.append('max_value', this.state.max_value)
    formData.append('code_roles', this.props.dataUser.code_roles)
    axios.post(API_URL.superStudent + '/student/leads-followup', formData, {
      headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
    })
      .then(response => {
        this.setState({ leadsData: response.data.data, showLoading: false })
      })
  }

  getConsultant() {
    const url = API_URL.superStudent + '/master/consultant/list-active';
    axios.get(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataConsultant: data })
      })
  }

  closeModal() {
    this.setState({
      modalNoted: false,
      modalUpdate: false
    });
  }

  editNoted = (row) => {
    this.setState({
      code_students: row.code_students,
      dataNoted: row.progress_log.length > 0 ? row.progress_log[0].code : '',
      modalNoted: true,
    })
  }

  updateNoted() {
    let formData = new FormData()
    formData.append('code_students', this.state.code_students)
    formData.append('code', this.state.dataNoted)
    formData.append('code_users', this.props.dataUser.code_users)
    formData.append('code_lpt', 'CMNT')
    axios.post(API_URL.studentUrl + 'progress-log', formData, {
      headers: { 'Authorization': `Bearer ${API_URL.studentToken}` }
    })
      .then(() => {
        this.loadDataLeads()
        this.closeModal();
      })
  }

  renderModalNote() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalNoted} toggle={() => this.closeModal()}>
        <ModalHeader toggle={() => this.closeModal()}>Update Note</ModalHeader>
        <ModalBody>
          <h5 className='mb-3'>Edit Noted</h5>
          <form className="needs-validation" noValidate="">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <textarea rows={3} className='form-control' name='dataNoted' value={this.state.dataNoted} onChange={(e) => this.setState({ dataNoted: e.target.value })}></textarea>
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateNoted()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderModalUpdate() {
    return (
      <Modal className="modal-dialog" isOpen={this.state.modalUpdate} toggle={() => this.closeModal()} size="lg">
        <ModalHeader toggle={() => this.closeModal()}></ModalHeader>
        <ModalBody>
          <h5 className='mb-3'>Edit Data</h5>
          <form className="needs-validation" noValidate="">
            <div className="form-row">
              <div className="col-md-12 mb-3">
                {
                  this.state.typeUpdate == "Consultant" ?
                    <select className="form-control" name="code_konsultan" value={this.state.code_konsultan} onChange={this.handleInput}>
                      {
                        this.state.dataConsultant.map(item => {
                          return <option key={item.code} value={item.code}>{item.name}</option>
                        })
                      }
                    </select>
                    : ''
                }

              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.updateStudent()}>Save</Button>
          <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  handleType = (e) => {
    let type = e.target.value
    this.setState({
      type,
      min_value: type == 1 ? 14 : type == 2 ? 31 : 45,
      max_value: type == 1 ? 30 : type == 2 ? 44 : 300
    }, () => this.loadDataLeads())
  }

  editDetail = (row, type) => {
    this.getConsultant()
    this.setState({
      code_students: row.code_students,
      typeUpdate: type,
      code_konsultan: row.code_konsultan,
      modalUpdate: true
    })
  }

  updateStudent() {
    this.handleEditable("code_konsultan", this.state.code_students, this.state.code_konsultan)
  }

  handleEditable(kolom, code_students, value) {
    var formData = new FormData();
    formData.append('code_students', code_students);
    formData.append('columns', kolom);
    formData.append('value', value);

    axios.post(API_URL.superStudent + '/student/update-students-by-columns', formData, {
      headers: {
        'Authorization': `Bearer ${this.props.dataToken}`
      }
    })
      .then(response => {
        this.loadDataLeads();
        this.closeModal();
      })
      .catch(error => {
      })
  }

  handleLock = (row) => {
    if (this.props.dataUser.code_roles == 'RL003' || row.consultant.code_superior == this.props.dataUser.code_konsultan) {
      let status = row.status_follow_up == 1 ? 0 : 1;
      let formData = new FormData()
      formData.append('code_students', row.code_students);
      formData.append('columns', 'status_follow_up');
      formData.append('value', status);

      axios.post(API_URL.superStudent + '/student/update-students-by-columns', formData, {
        headers: { 'Authorization': `Bearer ${this.props.dataToken}` }
      })
        .then(() => {
          this.loadDataLeads();
        })
        .catch(() => {
        })
    }
  }

  render() {

    const columns = [
      {
        Header: 'Code Student',
        accessor: 'code_students',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["code_students"] }),
        width: 120,
        filterAll: true
      },
      {
        Header: 'Name',
        accessor: 'nama',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["nama"] }),
        filterAll: true,
        Cell: (row) => (
          <span><i onClick={() => this.handleLock(row.original)} className={row.original.status_follow_up == 1 ? 'fa fa-lock btn-outline-danger' : 'fa fa-unlock btn-outline-success'}></i> {row.original.nama}</span>
        )
      },
      {
        Header: 'Whatsapp',
        accessor: 'no_hp',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["no_hp"] }),
        filterAll: true,
        Cell: (row) => (
          row.original.no_hp.substring(0,2) == '62' ?
          <a href={'https://wa.me/'+row.original.no_hp}rel='noreferrer noopener' target='_blank'>{row.original.no_hp}</a>
          :
          <a href={'https://wa.me/'+'62'+row.original.no_hp.substring(1)} rel='noreferrer noopener' target='_blank'>{row.original.no_hp}</a>
      )
      },
      {
        Header: 'Consultant',
        accessor: 'consultant_name',
        width: 120,
        Cell: (row) => (
          row.original.status_follow_up === 1 ?
            <span>{row.original.consultant_name}</span>
            :
            <div onClick={e => this.editDetail(row.original, "Consultant")}>{row.original.consultant_name}</div>
        ),
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["consultant_name"] }),
        filterAll: true
      },
      {
        Header: 'Progress',
        accessor: 'name_progress',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name_progress"] }),
        filterAll: true
      },
      {
        Header: 'Total day',
        accessor: 'total_days',
        width: 100,
        style: { textAlign: 'center' },
        filterable: false,
        Cell: (row) => (
          <>
            <div style={{ fontSize: '11px', color: 'grey' }}>{row.original.total_days} days ago</div>
          </>
        ),
      },
    ];

    return (
      <>
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="row">
                <div className="col-md-8">
                  <h5>Leads Students Follow Up</h5>
                </div>
                <div className="col-md-4">
                  <select onChange={this.handleType} name="type" value={this.state.value} className="form-control">
                    <option value='1'>14 - 30 days</option>
                    <option value='2' >31 - 44 days</option>
                    <option value='3' >more than 45 days</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <ReactTable
                    data={this.state.leadsData}
                    filterable
                    columns={columns}
                    collapseOnDataChange={false}
                    className="-striped -highlight"
                    showPaginationBottom={true}
                    SubComponent={row => {
                      return (
                        <div style={{ padding: "20px" }}>
                          <div className='row'>
                            <div className='col'>
                              <i onClick={e => this.editNoted(row.original)} className="fa fa-pencil" style={{ fontSize: 16, color: "#22af47" }}></i><strong> Notes :</strong> <span>{row.original.progress_log.length > 0 ?
                                <>
                                  {row.original.progress_log[0].code}
                                </>
                                : ''}</span>
                            </div>
                          </div>
                        </div>
                      )
                    }}
                  />
                  <Loader showLoading={this.state.showLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderModalNote()}
        {this.renderModalUpdate()}
      </>
    );
  }
}


const reduxState = (state) => ({
  dataToken: state.Login.lgnInitialize,
  dataUser: state.Login.detailUser
})

export default connect(reduxState, null)(StudentFollowUp)
