/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Moment from 'moment';

class SchoolGrandted extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSchoolGranted: []
        }
    }

    componentDidMount() {
        this.getSchoolGranted()
    }

    getSchoolGranted() {
        const url = API_URL.superStudent+'/school-activities/granted/'+this.props.match.params.codeSchool;
        axios.get(url, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}` 
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({dataSchoolGranted:data});
        });
    }

    render() {
        const columns = [
            {
                Header: 'Code',
                accessor: 'code_students',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) =>(
                    <button style={{border:'none'}} className='btn btn-outline-primary btn-sm btn-pill text-center' onClick={() => 
                        window.open(`${process.env.PUBLIC_URL}/pages/studentsdetail/`+row.original.token_students)    
                    }>{row.original.code_students}</button>
                )
            },
            {
                Header: 'Nama',
                accessor: 'nama',
                filterable: false
            }, 
            {
                Header: 'University',
                accessor: 'university.nama_sekolah',
                filterable: false
            }, 
            {
                Header: 'Date Granted',
                style: {textAlign: 'center'},
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.progress_log != undefined ?
                            Moment(row.original.progress_log[0].date_created).format('DD MMMM YYYY')
                            :
                            ''
                        }
                    </div>
                )
            }, 
        ]

        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body datatable-react">
                                    <ReactTable 
                                        data={this.state.dataSchoolGranted}
                                        columns={columns}
                                        pageSize={25}
                                        showPagination={false}
                                        filterable
                                        defaultFilterMethod={(filter, row) =>
                                            String(row[filter.id]) === filter.value}
                                        style={{
                                            height: "700px" // This will force the table body to overflow and scroll, since there is not enough room
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}


const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(SchoolGrandted);