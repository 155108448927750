/* eslint-disable eqeqeq */

import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { DollarSign, FileText } from 'react-feather';
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect} from 'react-router-dom';
import { NavTab, RoutedTabs } from 'react-router-tabs';

const Accounting = () => {

    const { dataUser } = useSelector(state => ({
        dataUser: state.Login.detailUser,
    }))

    return (
        <Fragment>
            <Breadcrumb title="Accounting" parent="Dashboard" />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <div className="col-sm-12">
                        <RoutedTabs>
                            <div className="tabs tab-title">
                                {
                                    dataUser.code_roles == 'RL001' ? 
                                    <>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-indikator`}>
                                            <FileText /> Indikator
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`}>
                                            <FileText /> Budgeting
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`}>
                                            <FileText /> Budgeting 
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-revenue`}>
                                            <FileText /> Transaction
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-profit`}>
                                            <DollarSign /> Profit
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-income`}>
                                            <FileText /> Income
                                        </NavTab>
                                    </>
                                    :
                                    dataUser.code_roles == 'RL003' || dataUser.code_roles == 'RL005' || dataUser.code_roles == 'RL023' ?
                                    <>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-indikator`}>
                                            <FileText /> Indikator
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`}>
                                            <FileText /> Budgeting
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-revenue`}>
                                            <FileText /> Transaction
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-profit`}>
                                            <DollarSign /> Profit
                                        </NavTab>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-income`}>
                                            <FileText /> Income
                                        </NavTab>
                                    </>
                                    :
                                    <>
                                        <NavTab activeClassName="active" to={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`}>
                                            <FileText /> Budgeting 
                                        </NavTab>
                                    </>
                                }
                            </div>
                        </RoutedTabs>
                        <Switch>
                            {
                                dataUser.code_roles == 'RL001'|| dataUser.code_roles == 'RL003' || dataUser.code_roles == 'RL005' || dataUser.code_roles == 'RL023' ?
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/accounting`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting`} />}
                                /> 
                                :
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/pages/accounting`}
                                    render={() => <Redirect replace to={`${process.env.PUBLIC_URL}/pages/accounting/data-budgeting-jobdesk`} />}
                                />
                            }
                        </Switch>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
  
export default Accounting;