/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { withRouter } from "react-router";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pdf from '../../assets/images/document-logo/pdf-logo.png';
import CKEditors from "react-ckeditor-component";
import seven from '../../assets/images/user/7.jpg';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

class AddSchoolRep extends Component{

    constructor(props) {
        super(props);
        this.state = {
            listCountry: [],
            listSchoolRep:[],
            listRequirement:[],
            dataResources:[],
            educationGroup:[],
            programType:[],
            providerType:[],
            tagging:[],
            disabledButton:false,
            buttonText: 'Simpan',
            buttonEdit: 'Edit',
            buttonUpload:'Upload',
            buttonDelete:'Delete',
            action:'',
            id_school_rep:'',
            education_group_id:'',
            name:'',
            country:'',
            province:'',
            scholarship_info:'',
            city:'',
            address:'',
            start_date:'',
            end_date:'',
            rating:'',
            selling_rating: '',
            website:'',
            email:'',
            phone:'',
            provider_id:'',
            jenjang_pendidikan:'',
            top_university:0,
            application_fee:0,
            living_cost:0,
            content:'',
            postal_code:'',
            courses_type:[],
            requirement_selected:[],
            selected_tagging:[],
            errors:[],
            filedata:'',
            file_type:'',
            displayImage: 'none',
            saveLogo: 'Save',
            disableBtnLogo:false,
            input_logo:'',
            input_cover:'',
            last_update: '',
            image_logo: null,
            image_cover: null,
            modalUploadFile:false,
            modalPreview:false,
            modalDeleteFile:false,
            modalEditUserSchoolRep:false,
            modalDeleteUserSchoolRep:false,
            title: '',
            nama_lengkap:'',
            username:'',
            password:'',
            jabatan:'',
            urlPreview:'',
            id_resource:'',
        }
    }

    onChange(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            content: newContent
        })
    }

    onChangeScholarship(evt) {
        var newContent = evt.editor.getData();
        this.setState({
            scholarship_info: newContent
        })
    }

    componentDidMount(){
        console.log(this.props.match.params.codeSchoolRep)
        this.getListCountry()
        this.getRequirement()
        this.loadEducationGroup()
        this.loadProgramType()
        this.loadProviderType()
        this.loadTagging()
        if(this.props.match.params.codeSchoolRep === undefined)
            this.setState({ action: 'tambah' })
        else {
            this.loadUniversityById()
        }
    }

    loadUniversityById = () => {
        var formData = new FormData();
        formData.append('params', 'id_school_rep');
        formData.append('value', this.props.match.params.codeSchoolRep);

        axios.post(API_URL.superStudent+'/universities-params', formData, {
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => response.data.data[0])
        .then((data) => {
            this.setState({
                action: 'edit',
                displayImage:'',
                id_school_rep: data.id,
                name: data.name,
                rating:data.rating,
                selling_point:data.selling_point,
                start_date: data.start_date,
                end_date:data.end_date,
                content:data.about,
                scholarship_info:data.scholarship_info,
                country:data.country_code,
                postal_code: data.zip_code,
                phone: data.phone,
                email:data.email,
                website:data.website,
                address:data.address,
                city:data.city,
                province:data.province,
                provider_id: data.provider_id,
                image_logo: data.logo,
                image_cover: data.cover,
                courses_type: data.jenjang,
                requirement_selected: data.requirements,
                selected_tagging: data.tagging,
                application_fee: data.application_fee,
                living_cost: data.living_cost,
                top_university: data.top_university,
                last_update: data.last_update,
                education_group_id: data.education_group_id
             },() => {
                this.loadUniversityUser()
                this.loadUniversityResources()
            });
        })
        .catch(error => console.log(error))
    }

    loadUniversityUser = () => {
        axios.get(API_URL.superStudent+'/list-university-login/'+this.state.id_school_rep,{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({listSchoolRep:response.data.data}))
        .catch(error => console.log(error))
    }

    loadUniversityResources = () => {
        axios.get(API_URL.superStudent+'/load-university-file/'+this.state.id_school_rep,{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({dataResources:response.data.data}))
        .catch(error => console.log(error))
    }

    loadEducationGroup = () => {
        axios.get(API_URL.superStudent+'/master/education-group',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({educationGroup:response.data.data}))
        .catch(error => console.log(error))
    }

    loadProgramType = () => {
        axios.get(API_URL.superStudent+'/master/jenjang-tujuan',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({programType:response.data.data}))
        .catch(error => console.log(error))
    }

    loadTagging = () => {
        axios.get(API_URL.superStudent+'/master/tag',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({tagging:response.data.data}))
        .catch(error => console.log(error))
    }

    loadProviderType = () => {
        axios.get(API_URL.superStudent+'/master/provider-type',{
            headers:{
                Authorization: `Bearer `+this.props.dataToken
            }
        })
        .then(response => this.setState({providerType:response.data.data}))
        .catch(error => console.log(error))
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleChangeLogo = (e) => {
        this.setState({
            input_logo:e.target.files[0]
        })
    }

    handleChangeCover = (e) => {
        this.setState({
            input_cover: e.target.files[0]
        })
    }

    setHandleCourses = (e) => {
        this.setState({
            courses_type: e
        })
    }

    setHandleRequirement = (e) => {
        this.setState({
            requirement_selected: e
        })
    }

    setHandleTagging = (e) => {
        this.setState({
            selected_tagging: e
        })
    }

    getListCountry(){
        axios.get(API_URL.superStudent+'/master/country')
        .then(response => this.setState({ listCountry: response.data.data}))
        .catch(error => console.log(error))
    }

    getRequirement(){
        axios.get(API_URL.superStudent+'/master/requirement-university')
        .then(response => this.setState({ listRequirement: response.data.data}))
        .catch(error => console.log(error))
    }

    handleSubmit = async (e) => {
        e.preventDefault()

        let course = []
        this.state.courses_type.map(item => (
            course.push(item.id)
        ))
        let requirement = []
        this.state.requirement_selected.map(item => (
            requirement.push(item.id)
        ))
        let tagging = []
        this.state.selected_tagging.map(item => (
            tagging.push(item.id)
        ))
        var formData = new FormData();
        formData.append('id', this.state.id_school_rep);
        formData.append('name', this.state.name);
        formData.append('start_date', this.state.start_date);
        formData.append('end_date', this.state.end_date);
        formData.append('country', this.state.country);
        formData.append('city', this.state.city);
        formData.append('province', this.state.province);
        formData.append('address', this.state.address);
        formData.append('website', this.state.website);
        formData.append('email', this.state.email);
        formData.append('phone', this.state.phone);
        formData.append('rating', this.state.rating);
        formData.append('selling_rating', this.state.selling_rating);
        formData.append('postal_code', this.state.postal_code);
        formData.append('about', this.state.content);
        formData.append('scholarship_info', this.state.scholarship_info)
        formData.append('application_fee', this.state.application_fee)
        formData.append('living_cost', this.state.living_cost)
        formData.append('provider_id', this.state.provider_id);
        formData.append('last_update', this.state.last_update)
        formData.append('top_university', this.state.top_university)
        formData.append('courses_type', JSON.stringify(course));
        formData.append('requirement', JSON.stringify(requirement))
        formData.append('tagging', JSON.stringify(tagging))
        formData.append('education_group_id', this.state.education_group_id)
        this.setState({
            disabledButton:true,
            buttonText:'Menyimpan...'
        })

        let url = '/universities'
        let method = 'post'

        if (this.state.action === 'edit'){
            formData.append('_method','PATCH');
            url = '/universities/'+this.state.id_school_rep
        }

        axios({
            method,
            url: API_URL.superStudent+url,
            data: formData,
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(() => {
            this.setState({
                disabledButton:false,
                buttonText:'Simpan'
            })
            toast.success("Data university berhasil diperbaharui");
            this.props.history.push('/pages/schoolrep')
        })
        .catch(err => {
            if(err.response.status === 422){
                this.setState({
                    errors:err.response.data.data,
                    disabledButton:false,
                    buttonText:'Simpan'
                })
            }
        })
    }

    handleSubmitLogo =  async (e) => {
        e.preventDefault()
        const body = new FormData()
        body.append('logo', this.state.input_logo)
        body.append('cover', this.state.input_cover)
        body.append('id', this.state.id_school_rep)
        this.setState({
            disableBtnLogo:true,
            saveLogo:'Saving..'
        })
        await axios.post(API_URL.superStudent+'/set-images', body, {
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(() => {
            toast.success("Data university berhasil diperbaharui");
            this.props.history.push('/pages/schoolrep')
        })
        .catch(err => console.log(err))
    }

    closeModal = () =>{
        this.setState({
            modalUploadFile:false,
            modalPreview:false,
            modalDeleteFile:false,
            modalEditUserSchoolRep:false,
            modalDeleteUserSchoolRep:false
        })
    }

    handleChangeFile = (e) => {
        this.setState({
            filedata:e.target.files[0]
        })
    }

    renderUploadFile() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalUploadFile} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Upload File</ModalHeader>
            <ModalBody style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto'
            }}>
    
            <input type='file' className='form-control' accept='.pdf' onChange={this.handleChangeFile}/>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.disabledButton} color="primary" onClick={(e) => this.saveFile()}>{this.state.buttonUpload}</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    showModalUpload = (data) => {
        this.setState({
            modalUploadFile:true,
            file_type:data
        })
    }

    saveFile = () => {
        this.setState({
            buttonUpload: 'Uploading...',
            disabledButton:true
        })
        let formData = new FormData()
        formData.append('id', this.state.id_school_rep)
        formData.append('file_type', this.state.file_type)
        formData.append(this.state.file_type, this.state.filedata)
        axios.post(API_URL.superStudent+'/upload-university-file', formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.setState({
                modalUploadFile:false,
                filedata:'',
                buttonUpload: 'Upload',
                disabledButton:false
            })
            this.loadUniversityResources()
            toast.success("data updated successfully...");
        })
        .catch(error => {
            console.log(error)
            this.setState({
                modalUploadFile:false,
                filedata:'',
                buttonUpload: 'Upload',
                disabledButton:false
            })
            toast.error("There is an error...");
        })
    }

    deleteFile = () => {
        this.setState({
            buttonDelete:'Deleting..',
            disabledButton:true
        })
        axios.delete(API_URL.superStudent+'/delete-university-file/'+this.state.id_resource,{
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            this.setState({
                modalDeleteFile:false,
                buttonDelete: 'Delete',
                disabledButton:false
            })
            this.loadUniversityResources()
            toast.success("data deleted successfully...");
        })
        .catch(error => {
            this.setState({
                modalDeleteFile:false,
                buttonDelete: 'Delete',
                disabledButton:false
            })
            toast.error("There is an error...");
        })
    }

    handleModalPreivew = (urlPreview) => {
        this.setState({
            modalPreview:true,
            urlPreview
        })
    }

    handleModalDelete = (id) => {
        this.setState({
            modalDeleteFile:true,
            id_resource:id
        })
    }

    renderPreview(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalPreview} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>Preview Data</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>              
                    <DocViewer 
                        pluginRenderers={DocViewerRenderers} 
                        documents={[{ uri:this.state.urlPreview }]} 
                        config={{
                            header: {
                                disableHeader: false,
                                disableFileName: false,
                                retainURLParams: false
                            }
                        }}
                    />  
              </ModalBody>
            </Modal>
        )
    }

    renderDeleteFile(){
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalDeleteFile} toggle={() => this.closeModal()} size="md">
              <ModalHeader toggle={() => this.closeModal()}>Delete Data</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>              
                    Are you sure you delete this data ?
              </ModalBody>
              <ModalFooter>
              <Button disabled={this.state.disabledButton} color="danger" onClick={(e) => this.deleteFile()}>{this.state.buttonDelete}</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
            </Modal>
        )
    }

    addUserRep() {
        this.setState({
            modalEditUserSchoolRep: true,
            action: 'add',
            title: 'Add'
        })
    }

    editUserRep(item) {
        this.setState({
            modalEditUserSchoolRep: true,
            action: 'edit',
            title: 'Edit',
            nama_lengkap: item.nama_lengkap,
            username: item.username,
            email: item.email,
            password: item.password,
            jabatan: item.jabatan,
            id_user: item.id_users
        })
    }

    deleteUserRep(item) {
        this.setState({
            modalDeleteUserSchoolRep: true,
            action: 'delete',
            id_user: item.id_users
        })
    }

    saveUser() {
        this.setState({
            disabledButton:true
        })

        let formData = new FormData()
        formData.append('name', this.state.nama_lengkap)
        formData.append('username', this.state.username)
        formData.append('email', this.state.email)
        formData.append('password', this.state.password)
        formData.append('code_roles', 'RL011')
        formData.append('jabatan', this.state.jabatan)
        formData.append('userable_type', "App\\Models\\University")
        formData.append('userable_id', this.state.id_school_rep)

        let url = API_URL.superStudent+'/users/add';

        if (this.state.action == 'edit') {
            formData.append('_method', 'PATCH');
            url = API_URL.superStudent+'/users/'+this.state.id_user
        } else if (this.state.action == 'delete') {
            formData.append('_method', 'DELETE');
            url = API_URL.superStudent+'/users/'+this.state.id_user
        }

        axios.post(url, formData, {
            headers:{
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data' 
            }
        })
        .then(response => {
            this.setState({
                modalEditUserSchoolRep:false,
                disabledButton:false
            })
            this.loadUniversityUser()
            toast.success("data updated successfully...");
        })
        .catch(error => {
            this.setState({
                modalEditUserSchoolRep:false,
                disabledButton:false
            })
            toast.error("There is an error...");
        })
    }

    renderEditUserRep() {
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalEditUserSchoolRep} toggle={() => this.closeModal()} size="md">
                <ModalHeader toggle={() => this.closeModal()}>{this.state.title} User</ModalHeader>
                <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
                    <div className='form-group'>
                        <label className='f-w-600'>Full Name</label>
                        <input type='text' className='form-control' name='nama_lengkap' value={this.state.nama_lengkap} onChange={this.handleInput}/>
                    </div>
                    <div className='form-group'>
                        <label className='f-w-600'>User Name</label>
                        <input type='text' className='form-control' name='username' value={this.state.username} onChange={this.handleInput}/>
                    </div>
                    <div className='form-group'>
                        <label className='f-w-600'>Email</label>
                        <input type='text' className='form-control' name='email' value={this.state.email} onChange={this.handleInput}/>
                    </div>
                    <div className='form-group'>
                        <label className='f-w-600'>Password</label>
                        <input type='password' className='form-control' name='password' value={this.state.password} onChange={this.handleInput}/>
                    </div>
                    <div className='form-group'>
                        <label className='f-w-600'>Jabatan</label>
                        <select className='form-control' name='jabatan' value={this.state.jabatan} onChange={this.handleInput}>
                            <option id="marketing" value='Marketing'>Marketing</option>
                            <option id="finance" value='Finance'>Finance</option>
                        </select>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.disabledButton} color="primary" onClick={(e) => this.saveUser()}>Save</Button>
                    <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }


    
    render(){
        return (
            <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <Breadcrumb title="Form University" parent="SchoolRep" />
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                    <div className="col-md-5 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label className='f-w-600'>name<span className="text-danger">*</span></label>
                                            <input className="form-control" onChange={this.handleInput} value={this.state.name} name="name" type="text" placeholder="example" />
                                            <div className="text-danger">{this.state.errors.name}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label className='f-w-600'>Email<span className="text-danger">*</span></label>
                                            <input className="form-control" type="email" name="email" value={this.state.email} onChange={this.handleInput} placeholder="example@gmail.com" />
                                            <div className="text-danger">{this.state.errors.email}</div>
                                        </div>
                                    </div> 
                                    <div className="col-md-4 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label className='f-w-600'>Website</label>
                                            <input className="form-control" name='website' value={this.state.website} onChange={this.handleInput} type="text" placeholder="https://example.com" />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label className='f-w-600'>Country<span className="text-danger">*</span></label>
                                            <select name='country' value={this.state.country} onChange={this.handleInput} className="form-control btn-square">
                                                <option value="0">--Select Country--</option>
                                                {
                                                    this.state.listCountry.length > 0 ?
                                                    this.state.listCountry.map(country => {
                                                        return <option key={country.code} value={country.code}>{country.name}</option>
                                                    })
                                                    :
                                                    ''
                                                }
                                            </select>
                                            <div className="text-danger">{this.state.errors.country}</div>
                                        </div>
                                    </div> 
                                    <div className="col-sm-6 col-md-3 col-xs-12">
                                        <div className="form-group">
                                            <label className='f-w-600'>Province</label>
                                            <input className="form-control" name='province' value={this.state.province} onChange={this.handleInput} type="text" placeholder="Bali" />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="form-group">
                                            <label className='f-w-600'>City</label>
                                            <input className="form-control" name='city' value={this.state.city} onChange={this.handleInput} type="text" placeholder="Denpasar" />
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-3">
                                        <div className="form-group">
                                            <label className='f-w-600'>Postal Code</label>
                                            <input className="form-control" name='postal_code' value={this.state.postal_code} onChange={this.handleInput} type="text" placeholder="123456" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className='f-w-600'>Address</label>
                                            <input className="form-control" name='address' value={this.state.address} onChange={this.handleInput} type="text" placeholder="Pemecutan Kaja" />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12">
                                        <div className="form-group">
                                            <label className='f-w-600'>Phone</label>
                                            <input className="form-control" name='phone' value={this.state.phone} onChange={this.handleInput} type="text" placeholder="(021) 8080 8080" />
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Education Group</label>
                                            <select name='education_group_id' value={this.state.education_group_id} onChange={this.handleInput} className='form-control'>
                                                <option key={0} value=''>Select</option>
                                                <option key={-1} value={null}>No Group</option>
                                                {
                                                    this.state.educationGroup.length > 0 ?
                                                    this.state.educationGroup.map(group => {
                                                        return <option key={group.id} value={group.id}>{group.name}</option>
                                                    })
                                                    :
                                                    ''
                                                }
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="col-md-6">
                                        <input type="hidden" name="id_school_rep" value={this.state.id_school_rep}/>
                                        <div className="form-group">
                                            <label className="f-w-600">Tanggal Kontrak</label>
                                            <div className="input-group">
                                                <input type="date" value={this.state.start_date} onChange={this.handleInput} name="start_date" className="form-control"/>
                                                <input type="date" value={this.state.end_date} onChange={this.handleInput} name="end_date" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Rating</label>
                                            <select name='rating' value={this.state.rating} onChange={this.handleInput} className='form-control'>
                                                <option key={0} value=''>--Select</option>
                                                <option key={1} value='1'>1</option>
                                                <option key={2} value='2'>2</option>
                                                <option key={3} value='3'>3</option>
                                                <option key={4} value='4'>4</option>
                                                <option key={5} value='5'>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Selling Rating</label>
                                            <select name='selling_rating' value={this.state.selling_rating} onChange={this.handleInput} className='form-control'>
                                                <option key={0} value=''>--Select</option>
                                                <option key={1} value='1'>1</option>
                                                <option key={2} value='2'>2</option>
                                                <option key={3} value='3'>3</option>
                                                <option key={4} value='4'>4</option>
                                                <option key={5} value='5'>5</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                        <label className="f-w-600">Top University</label>
                                            <select name='top_university' value={this.state.top_university} onChange={this.handleInput} className='form-control'>
                                                <option key={0} value=''>Tidak</option>
                                                <option key={1} value='1'>Ya</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Provider Type</label>
                                            <select name='provider_id' value={this.state.provider_id} onChange={this.handleInput} className='form-control'>
                                                <option key={0} value=''>--Select</option>
                                                {
                                                    this.state.providerType.length > 0 ?
                                                    this.state.providerType.map(item => (
                                                        <option key={item.id} value={item.id}>{item.provider_name}</option>
                                                    ))
                                                    :
                                                    ''
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Application Fee</label>
                                            <input type='number' onChange={this.handleInput} name='application_fee' value={this.state.application_fee} className='form-control'/> 
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Living Cost</label>
                                            <input type='number' onChange={this.handleInput} name='living_cost' value={this.state.living_cost} className='form-control'/> 
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Courses Type</label> 
                                            <Typeahead
                                                id="basic-typeahead-multiple"
                                                labelKey="jenjang_tujuan"
                                                multiple
                                                selected={this.state.courses_type}
                                                options={this.state.programType}
                                                onChange={this.setHandleCourses}
                                                placeholder="can choose several type."
                                            /> 
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Requirements</label> 
                                            <Typeahead
                                                id="basic-typeahead-multiple"
                                                labelKey="description"
                                                multiple
                                                selected={this.state.requirement_selected}
                                                options={this.state.listRequirement}
                                                onChange={this.setHandleRequirement}
                                                placeholder="can choose several data."
                                            /> 
                                        </div>
                                    </div> 
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label className="f-w-600">Tagging</label> 
                                            <Typeahead
                                                id="basic-typeahead-multiple"
                                                labelKey="name"
                                                multiple
                                                selected={this.state.selected_tagging}
                                                options={this.state.tagging}
                                                onChange={this.setHandleTagging}
                                                placeholder="can choose several data."
                                            /> 
                                        </div>
                                    </div> 
                                    <div className='col-md-3 col-lg-6'>
                                        <label className="f-w-600">Last Update</label> 
                                        <input type='date' name='last_update' onChange={this.handleInput} value={this.state.last_update} className='form-control' />
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <div className="form-group mb-0">
                                            <label className='f-w-600'>About</label>
                                            <CKEditors
                                                activeclassName="p10"
                                                content={this.state.content}
                                                events={{
                                                        "change": this.onChange.bind(this),
                                                }}
                                                config={{
                                                toolbar:[
                                                            ["Bold","Italic",'Strike','RemoveFormat'],
                                                            ['NumberedList','BulletedList','Indent','Outdent'],
                                                            ['Link','Unlink'],
                                                            ['Styles','Format','Table','Blockquote','Maximize'],
                                                            ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                        ]
                                                    }}     
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-0">
                                            <label className='f-w-600'>Scholarship Info</label>
                                            <CKEditors
                                                activeclassName="p10"
                                                content={this.state.scholarship_info}
                                                events={{
                                                        "change": this.onChangeScholarship.bind(this),
                                                }}
                                                config={{
                                                toolbar:[
                                                            ["Bold","Italic",'Strike','RemoveFormat'],
                                                            ['NumberedList','BulletedList','Indent','Outdent'],
                                                            ['Link','Unlink'],
                                                            ['Styles','Format','Table','Blockquote','Maximize'],
                                                            ["Cut","Copy","Paste","Pasteasplaintext","Undo","Redo"]
                                                        ]
                                                    }}     
                                            />
                                        </div>
                                    </div>
                                </div>
                                    <Button className='mt-3'  disabled={this.state.disabledButton} type="submit" color="primary">{this.state.buttonText}</Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div style={{display:this.state.displayImage}}  className='col-md-8'>
                            <div className="card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmitLogo}>
                                        <div className='row'>
                                            <div className="col-sm-6">
                                                <figure itemProp="associatedMedia" className="img-hover hover-15">
                                                    <h4>Logo</h4>
                                                    <img src={this.state.image_logo !== null ? this.state.image_logo : seven} className='img-thumbnail'  alt="logo" />
                                                </figure>
                                                <input type='file' onChange={this.handleChangeLogo} className='form-control' accept='image/*' />
                                            </div>
                                            <div className="col-sm-6">
                                                <figure itemProp="associatedMedia" className="img-hover hover-15">
                                                    <h4>Cover</h4>
                                                    <img src={this.state.image_cover !== null ? this.state.image_cover : seven} className='img-thumbnail'  alt="cover" />
                                                </figure>
                                                <input type='file' accept='image/*' onChange={this.handleChangeCover} className='form-control'/>
                                            </div>
                                        </div>
                                        <button style={{ border:'none'}}  disabled={this.state.disableBtnLogo} className='btn btn-primary mt-2 btn-block' type='submit'>{this.state.saveLogo}</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.action == 'edit' ? 
                            <>
                                                                <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-8'>
                                            <h4>Attachment</h4>
                                        </div>
                                        <div className='col-md-4'>
                                            <button onClick={() => this.showModalUpload('attachment')} className='btn btn-outline-success btn-xs pull-right' style={{border:'none'}}><i className='fa fa-plus fa-2x'></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        {
                                            this.state.dataResources.attachment !== undefined ?
                                            this.state.dataResources.attachment.map(item => (
                                                <div key={item.id} className='col-md-3 col-xs-6 mb-3'>
                                                    <div className='product-box'>
                                                        <div className='product-img'>
                                                            <img className='img-thumbnail' src={pdf} alt='file' />
                                                            <div className='product-hover'>
                                                                <ul>
                                                                    <li>
                                                                        <button className="btn" type="button" onClick={() => this.handleModalPreivew(item.data)}><i className="icon-eye"></i>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button onClick={() => this.handleModalDelete(item.id)} className='btn btn-outline-danger btn-block btn-xs'><i className='fa fa-trash'></i></button>
                                                </div>
                                            ))
                                            :''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='card'>
                                <div className='card-header'>
                                    <div className='row'>
                                        <div className='col-md-10'>
                                            <h4>Resources Materi</h4>
                                        </div>
                                        <div className='col-md-2'>
                                            <button onClick={() => this.showModalUpload('resources')} className='btn btn-outline-success btn-xs pull-right' style={{border:'none'}}><i className='fa fa-plus fa-2x'></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body'>
                                <div className='row'>
                                        {
                                            this.state.dataResources.resources !== undefined ?
                                            this.state.dataResources.resources.map(item => (
                                                <div key={item.id} className='col-md-3 col-xs-6 mb-3'>
                                                    <div className='product-box'>
                                                        <div className='product-img'>
                                                            <img className='img-thumbnail' src={pdf} alt='file' />
                                                            <div className='product-hover'>
                                                                <ul>
                                                                    <li>
                                                                        <button className="btn" type="button" onClick={() => this.handleModalPreivew(item.data)}><i className="icon-eye"></i>
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button onClick={() => this.handleModalDelete(item.id)} className='btn btn-outline-danger btn-block btn-xs'><i className='fa fa-trash'></i></button>
                                                </div>
                                            ))
                                            :''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h4>Rep Data</h4>
                                </div>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <button onClick={() => this.addUserRep()} className='btn btn-outline-success btn-xs pull-left' style={{border:'none'}}><i className='fa fa-plus fa-2x'></i></button>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Nama Rep</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Jabatan</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.listSchoolRep.length > 0 ?
                                                            this.state.listSchoolRep.map((item, index) =>(
                                                                <tr key={index}>
                                                                    <th scope="row">{index+1}</th>
                                                                    <td>{item.nama_lengkap}</td>
                                                                    <td>{item.email}</td>
                                                                    <td>{item.jabatan}</td>
                                                                    <td>
                                                                        <div className='row justify-content-center'>
                                                                            <div className='col-md-3'>
                                                                                <button onClick={() => this.editUserRep(item)} className="btn btn-success btn-xs" type="button" style={{border:'none', display: this.props.display}} ><i className="fa fa-pencil"></i></button>
                                                                            </div>   
                                                                            <div className='col-md-3'>
                                                                                <button onClick={() => this.deleteUserRep(item)} className="btn btn-danger btn-xs" type="button" style={{border:'none',display:this.props.display}} ><i className="fa fa-trash"></i></button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr aria-colspan={6}><th>Data kosong</th></tr>
                                                        }
                                                        
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </> : ''
                        }
                    </div>
                </div>
            </div>
            <ToastContainer />
            {this.renderUploadFile()}
            {this.renderPreview()}
            {this.renderDeleteFile()}
            {this.renderEditUserRep()}
        </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
  })

export default connect(reduxState, null) (withRouter(AddSchoolRep));