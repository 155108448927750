/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import template from '../../assets/images/pdf_icon.png'
import pdf from '../../assets/images/document-logo/pdf-logo.png';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { saveAs } from "file-saver";

class FileProfile extends Component {

    constructor(props){
        super(props)
        this.state = {
            modalDialog:false,
            modalPreview:false,
            file_id:'',
            fileData:'',
            urlPreview:''
        }
    }

    closeModal = () => {
        this.setState({
            modalDialog:false,
            modalPreview:false,
        })
    }

    openModal = (id) => {
        this.setState({
            modalDialog:true,
            file_id: id
        })
    }

    handleChangeFile = (e) => {
        this.setState({
            fileData:e.target.files[0]
        })
    }

    handleModalPreivew = (urlPreview) => {
        this.setState({
            modalPreview: true,
            urlPreview
        })
    }

    downloadFile = (e, filename) => {
        saveAs(filename,filename.substring(filename.lastIndexOf('/')));
    }

    renderUploadFile() {
        return(
          <Modal className="modal-dialog" isOpen={this.state.modalDialog} toggle={() => this.closeModal()} size="md">
            <ModalHeader toggle={() => this.closeModal()}>Edit File</ModalHeader>
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <input type='file' className='form-control' onChange={this.handleChangeFile}/>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.saveFile()}>Save Changes</Button>
              <Button color="secondary" onClick={e => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )
    }

    renderPreview() {
        const url_doc = [{ uri:this.state.urlPreview }]
        return(
            <Modal className="modal-dialog" isOpen={this.state.modalPreview} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>Preview Data</ModalHeader>
              <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>   
                {
                    this.state.urlPreview.split('.').pop().toLowerCase() == 'png' || 
                    this.state.urlPreview.split('.').pop().toLowerCase() == 'jpg' || 
                    this.state.urlPreview.split('.').pop().toLowerCase() == 'jpeg' ?
                    <img style={{maxWidth: '100%', height: 'auto'}} src={this.state.urlPreview} alt="" /> 
                    :
                    <DocViewer 
                        pluginRenderers={DocViewerRenderers} 
                        documents={url_doc}
                        config={{
                            header: {
                                disableHeader: true,
                                disableFileName: false,
                                retainURLParams: false
                            }
                        }}
                    />
                }
                <div className="form-row mt-1" style={{textAlign: "end"}}>
                    <div className="col-md-12">
                        <button onClick={(e) => this.downloadFile(e, this.state.urlPreview)} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
                    </div>
                </div>
              </ModalBody>
            </Modal>
        )
    }

    saveFile = () => {
        let formData = new FormData()
        formData.append('code_konsultan',this.props.data.code_konsultan)
        formData.append('file_id', this.state.file_id)
        formData.append('file_data',this.state.fileData)
        this.props.saveFile(formData)
        this.closeModal()
    }

    render(){
        return(
            <>
            <div className='card'>
                <div className='card-header'>
                    <h4>Attachment</h4>
                </div>
                <div className='card-body'>
                    <div className='row'>
                        {
                            this.props.dataFile.length > 0 ?
                            this.props.dataFile.map(document => (
                                <div className='col-md-2 mb-3'>
                                    <div className='product-box'>
                                        <div className='product-img'>
                                            {
                                                document.employee_document !== null ?
                                                document.employee_document.file_name.split('.').pop() === 'pdf' ?
                                                <img style={{maxHeight:'130px'}} className='img-thumbnail' src={pdf} alt={document.slug} />
                                                :
                                                <img style={{maxHeight:'150px'}} className='img-thumbnail' src={document.employee_document.file_name} alt={document.slug} />
                                                :
                                                <img className='img-thumbnail' src={template} alt={document.slug} />
                                            }
                                            <div className="product-hover">
                                                <ul>
                                                {
                                                this.props.data.code_konsultan === this.props.dataLogin.code_konsultan || this.props.dataLogin.code_roles === 'RL004' ? 
                                                    <li>
                                                        <button className="btn" type="button" onClick={() =>this.openModal(document.id)} ><i className="icon-pencil"></i></button>
                                                    </li>
                                                    : ''
                                                }
                                                {
                                                document.employee_document !== null ?
                                                    <li>
                                                        <button className="btn" type="button" data-toggle="modal" onClick={() => this.handleModalPreivew(document.employee_document.file_name)} data-target="#exampleModalCenter"> <i className="icon-eye"></i></button>
                                                    </li>
                                                    : ''
                                                }              
                                                </ul>
                                            </div>
                                            <label className='form-label text-center'>{document.name}</label>
                                        </div>
                                    </div>
                                </div>
                            ))
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
            {this.renderUploadFile()}
            {this.renderPreview()}
            </>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize
})

export default connect(reduxState, null) (FileProfile)