/* eslint-disable eqeqeq, jsx-a11y/alt-text */
import React, { Fragment, Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';

class EnglishTeacher extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            btnDisabled: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            dataTeacher: [],
            dataArea: [],
            row: '',
            id_teacher: '',
            kode_teacher: '',
            id_area: '',
            nama_lengkap: '',
            email: '',
            no_wa: '',
            status: '',
            foto: '',
            image: '',
            filename: ''
        }
    }

    componentDidMount() {
        this.getTeacherEnglishCourse();
        this.getInternalArea();
    }

    getTeacherEnglishCourse() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/english-course/list-teacher';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataTeacher:data
            });
        });
    }

    getInternalArea() {
        const url = API_URL.superStudent+'/master/area';
        axios.get(url, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}` 
            } 
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataArea:data
            });
        });
    }

    handleInput(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    onChangeFileName = (e) => { 
        this.setState({
            image: e.target.files[0],
            filename: e.target.value
        })
    }

    addRow() {
        this.setState({
            modal: true,
            operation: 'add',
            title: 'Add Teacher',
            id_area: '1',
            id_teacher: '',
            nama_lengkap: '',
            email: '',
            no_wa: '',
            status: 'Y',
            foto: '',
            image: '',
            filename: ''
        })
    }

    editRow(row) {
        this.setState({
            modal: true,
            operation: 'edit',
            title: 'Edit Teacher',
            id_area: row.id_area,
            id_teacher: row.id_teacher,
            nama_lengkap: row.nama_lengkap,
            kode_teacher: row.code_teacher,
            email: row.email,
            no_wa: row.no_wa,
            status: row.status
        })
    }

    deletRow(row) {
        this.setState({
            modalDelete: true,
            operation: 'delete',
            title: 'Delete Teacher',
            id_teacher: row.id_teacher
        })
    }

    closeDialog() {
        this.setState({
            modal: false,
            modalDelete: false
        })
    }

    saveRow() {
        this.setState({
            btnDisabled: true
        });

        var formData = new FormData();
        formData.append('id_teacher', this.state.id_teacher);
        formData.append('kode_teacher', this.state.kode_teacher);
        formData.append('id_area', this.state.id_area);
        formData.append('nama_lengkap', this.state.nama_lengkap);
        formData.append('email', this.state.email);
        formData.append('no_wa', this.state.no_wa);
        formData.append('status', this.state.status);
        formData.append('image', this.state.image);

        let url = '/english-course/create-teacher';

        if (this.state.operation == 'edit') {
            url = '/english-course/update-teacher'
        } else if (this.state.operation == 'delete') {
            url = '/english-course/delete-teacher'
        }

        axios.post(API_URL.superStudent+url, formData, { 
            headers: { 
                'Authorization': `Bearer ${this.props.dataToken}`, 
                'Content-Type': 'multipart/form-data' 
            }
        }).then((reply)=>{
            this.closeDialog();
            this.getTeacherEnglishCourse();
            this.setState({ 
                btnDisabled: false,
                image: "",
                flyerFile: ""
            });
            if (this.state.operation == "add") {
                toast.success("Save Sucsessfully");
            } else {
                toast.success("Update Sucsessfully");
            }
            window.location.reload();
        }).catch(err => {
            if(err.response.status === 422 || err.response.status === 409){
                this.setState({
                    errors: err.response.data.data,
                    btnDisabled: false
                })
            }
        });
    }

    renderDialog() {
        const { title, modal } = this.state;

        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>{title}</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Area</label>
                                    <select className='form-control' name='id_area' onChange={(e) => this.handleInput(e)} value={this.state.id_area}>
                                        {
                                            this.state.dataArea.map((area) => (
                                                <option value={area.id_area}>{area.area}</option>
                                            )) 
                                        }
                                    </select>
                                    <span>{this.state.errors.nama_lengkap}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Fullname</label>
                                    <input className="form-control" name="nama_lengkap" type="text" value={this.state.nama_lengkap}
                                        onChange={(e) => this.handleInput(e)}
                                    />
                                    <span>{this.state.errors.nama_lengkap}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">No. Whatsapp</label>
                                    <input className="form-control" name="no_wa" type="number" value={this.state.no_wa}
                                        onChange={(e) => this.handleInput(e)}
                                    />
                                    <span>{this.state.errors.no_wa}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Email</label>
                                    <input className="form-control" name="email" type="text" value={this.state.email}
                                        onChange={(e) => this.handleInput(e)}
                                    />
                                    <span>{this.state.errors.email}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Status</label>
                                    <select className='form-control' name='status' onChange={(e) => this.handleInput(e)} value={this.state.status}>
                                        <option value={'Y'}>Aktif</option>
                                        <option value={'N'}>Non Aktif</option>
                                    </select>
                                    <span>{this.state.errors.durasi}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" name="image" type="file" id="image"
                                        onChange={(e) => this.onChangeFileName(e)}
                                    />
                                    <span>{this.state.errors.image}</span>
                                    <div className="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.saveRow()}>Save Changes</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        const { title, modalDelete } = this.state;

        return (
            <Modal isOpen={modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>{title}</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.sendData()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Area',
                accessor: 'area.area',
                filterable: false
            },
            {
                Header: 'Full Name',
                accessor: 'nama_lengkap',
                filterable: false
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: false
            },
            {
                Header: 'No. Whatsapp',
                accessor: 'no_wa',
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} src={row.original.foto}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'status',
                accessor: 'status',
                filterable: false,
                Cell: (row) => (
                    <div>
                        {
                            row.original.status == 'Y' ?
                            <span className={'badge bg-primary'}>Aktif</span>
                            :
                            <span className={'badge bg-danger'}>Non Aktif</span>
                        }
                    </div>
                )
            },
            {
                Header: 'Action',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div>
                        <button onClick={() => this.editRow(row.original)} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}></i>{row.id}</button>
                    </div>
                )
            },
        ]

        return (
            <Fragment>
                <Breadcrumb title="Teacher English Course" />
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <button onClick={() => this.addRow()} className="btn btn-outline-primary btn-xs" type="button" style={{border:'none'}} >
                                            <div>
                                                <span>
                                                    <i className="fa fa-plus" style={{ width: 35, fontSize: 20, padding: 8, color: 'rgb(40, 167, 69)' }}></i>
                                                </span>
                                            </div>
                                        </button>
                                        <br/>
                                        {
                                            this.state.dataTeacher.length > 0 ?
                                            <ReactTable
                                                data={this.state.dataTeacher}
                                                columns={columns}
                                                filterable
                                                defaultFilterMethod={(filter, row) =>
                                                    String(row[filter.id]) === filter.value}
                                                defaultPageSize={100}
                                                className="-striped -highlight"
                                             /> 
                                            : 
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})

export default connect(reduxState)(withRouter(EnglishTeacher));