/* eslint-disable  react-hooks/exhaustive-deps */

import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { useSelector } from "react-redux";
import ListProgram from './list-program';
import API_URL from '../../../ApiUrl'
import axios from 'axios'
 
const ProgramData = () => {
    const [university,setUniversity] = useState([])

    const { dataToken,dataUser } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize,
        dataUser:state.Login.detailUser
    }));

    const loadUniversity = () => {
        axios.get(API_URL.superStudent+'/universities?education_group_id='+dataUser.userable_id,{
            headers: { Authorization: `Bearer ${dataToken}`}
        }) 
        .then(response => {
            setUniversity(response.data.data)
        })
        .catch(err => { })
    }


    useEffect(() => {
        loadUniversity()
    },[])

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    <Breadcrumb title="Program Data" parent="SchoolRep" />
                    <div className="row">
                        <div className="col-md-12">
                            <ListProgram university={university}/>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ProgramData;