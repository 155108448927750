import React, { Fragment, Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {matchSorter} from 'match-sorter';

class DatatableDocument extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_document:"",
            code_document: "",
            text_description: "",
            image: "",
            operation: '',
            modal:false,
            modalDelete:false,
            btnDisabled: false,
            textSave: 'Save'
        }
    }

    resetState(){
        this.setState({
            nama_document:"",
            code_document: "",
            text_description: "",
            image: "",
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleIamge = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }

    addRow() {
        this.setState({
            operation: 'add',
            modal: true,
        });
    }

    editRow(row) {
        this.setState({
            operation: 'edit',
            code_document: row.code,
            nama_document: row.name,
            text_description: row.description,
            modal: true,
        });
    }

    deleteRow(row) {
        this.setState({
            modalDelete:true,
          code_document: row.code
        });
    }

    sendData() {
        this.setState({
            btnDisabled: true,
            textSave: 'Saving..'
        });

        var formData = new FormData();
        formData.append('code_document', this.state.code_document)
        formData.append('nama_document', this.state.nama_document)
        formData.append('images', this.state.image)
        formData.append('text_description', this.state.text_description)

        let url = '/documents'

        if (this.state.operation === 'edit'){
            formData.append('_method','PATCH');
            url = '/documents/'+this.state.code_document
        }

        axios({
            method:'POST',
            url: API_URL.superStudent+url,
            data: formData,
            headers: {
                Authorization: `Bearer ${this.props.dataToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(() => {
            window.location.reload(false)
        })
        .catch(error => console.log(error))
    }

    saveDeleteRow() {
        this.setState({
            btnDisabled: true
        })

        axios.delete(API_URL.superStudent+'/documents/'+this.state.code_document,{
            headers: { Authorization: `Bearer ${this.props.dataToken}` }
        })
        .then(() => {
            window.location.reload(false)
        })
        .catch(error => console.log(error))
    }

    closeDialog(){
        this.setState({
            modal: false,
            modalDelete: false
        });
        this.resetState();
    }

    renderDialog() {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false })}>
                    <ModalHeader toggle={() => this.setState({ modal: false })}>Form Document</ModalHeader>
                    <ModalBody>
                        <form className="needs-validation" noValidate="">
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Nama Document</label>
                                    <input className="form-control" name="nama_document" type="text" value={this.state.nama_document} onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Description</label>
                                    <textarea className="form-control" name='text_description' rows="3" value={this.state.text_description} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="name">Image</label>
                                    <input className="form-control" name="image" type="file" onChange={this.handleIamge}
                                    />
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>{this.state.textSave}</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    renderDeleteDialog() {
        return (
            <Modal isOpen={this.state.modalDelete} toggle={() => this.setState({ modalDelete: false })}>
                <ModalHeader toggle={() => this.setState({ modalDelete: false })}>Delete Document</ModalHeader>
                <ModalBody>
                    Delete this data ?
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveDeleteRow()}>Save Changes</Button>
                    <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeDialog()}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        const { pageSize, myData } = this.props;
        const columns = [
            {
              Header: 'Nama Document',
              accessor: 'name',
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Description',
                accessor: 'description',
                Cell: (row) => (
                    <div>                     
                        <p>{row.original.description}</p>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: 'Images',
                Cell: (row) => (
                    <div>                     
                        <img style={{width: '100px'}} alt='images' src={row.original.images}/>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: true,
                filterable: false
            },
            {
                Header: <b>Action</b>,
                id: 'delete',
                accessor: str => "delete",
                Cell: (row) => (
                    <div>                     
                        <button onClick={() => this.editRow(row.original)} className="btn btn-primary btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-pencil"></i></button> &nbsp;
                        <button onClick={() => this.deleteRow(row.original)} className="btn btn-danger btn-xs" type="button" style={{border:'none'}} ><i className="fa fa-trash"></i></button>
                    </div>
                ),
                style: {
                    textAlign: 'center'
                },
                sortable: false,
                filterable: false
            }
        ]

        return(
            <Fragment>
                <div style={{marginBottom: '5px'}}>
                  <button onClick={() => this.addRow()} className="btn btn-success btn-xs" type="button" style={{border:'none'}} >
                    <div>
                      <span>
                        <i className="fa fa-plus fa-2x"></i>
                      </span>
                    </div>
                  </button>
                </div>
                <ReactTable 
                    data={myData}
                    columns={columns}
                    pageSize={pageSize}
                    showPagination={true}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value}
                />
                <ToastContainer />
                {this.renderDialog()}
                {this.renderDeleteDialog()}
            </Fragment>
        );
    }
};

// export default Datatable;

const reduxState = (state) => ({
    codeUsers: state.Login.detailUser.code_users,
    dataId: state.Login.detailUser.id_users,
    dataToken: state.Login.lgnInitialize,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})

export default connect(reduxState, reduxDispatch)(DatatableDocument);