/* eslint-disable no-unused-vars, react-hooks/exhaustive-deps  */

import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import Breadcrumb from '../../common/breadcrumb';
import { Progress } from 'reactstrap';
import CountUp from 'react-countup';
import setRangeDate from "../../../helper/general-function";
import axios from 'axios'
import API_URL from '../../../ApiUrl';
import { useSelector } from "react-redux"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import DataKPI from "../partition/component/data-kpi";
import { barChartOptions, color1, color2 } from "../../../constant/variable";
import DetailKPI from "../partition/component/detail-kpi";
import PeformanceBizdev from "../partition/peformance-bizdev";
import SchoolVisitSchedule from "../partition/school-visit-schedule";
import IncomeBizdev from "../partition/income-bizdev";

const DashboardBisDev = () => {

    const[kpi,setKPI] = useState({
        result:0,
        MoU:0,
        visit:0,
        selisih:0,
        last_result:0
    });

    const [total,setTotal] = useState(0)
    const[indikator,setIndikator] = useState([]);
    const[master,setMaster] = useState([]);
    const[index,setIndex] = useState(0);
    const[report,setReport] = useState([index])
    const[date,setDate] = useState(Moment().endOf('month'))
    const [typeDate,setTypeDate] = useState('this_week')
    const[filterDate,setFilterDate] = useState({start_date: Moment().startOf('isoWeek').format('YYYY-MM-DD'), end_date:Moment().endOf('isoWeek').format('YYYY-MM-DD')})


    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    })); 

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }));

    useEffect(() => {
        loadDataIndikator()
    },[]);

    useEffect(() => {
        loadDataPeformance()
    },[date]);

    const handlePickerMonth = (e) => {
        setDate( Moment(e).endOf('month'))
    }

    const loadDataIndikator = () => {
        let formData = new FormData()
        formData.append('code_jobdesk','JD007')
        axios.post(API_URL.superStudent+'/kpi/list-indikator', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            setMaster(response.data.data)
        })
        .catch(error => console.log(error))
    }

    const loadDataPeformance = () => {
        let formData = new FormData()
        formData.append('code_konsultan',dataUser.code_konsultan)
        formData.append('end_date', date.format('YYYY-MM-DD'))
        formData.append('time_type',2)
        formData.append('reference_type',1)
        axios.post(API_URL.superStudent+'/bisdev-perform/recap-kpi', formData,{
            headers: { 'Authorization': `Bearer ${dataToken}` }
        })
        .then(response => {
            let result = response.data.data
            let report_kpi = result.kpi_data

            let kpi = report_kpi[0].bobot+report_kpi[1].bobot
            let last_kpi = report_kpi[0].last_bobot+report_kpi[1].last_bobot
            let selisih = 0
            if(kpi < 100){
                selisih = 100 - kpi
            }
            setKPI({
                result:kpi,
                MoU: report_kpi[0].bobot,
                visit: report_kpi[1].bobot,
                selisih,
                last_result: kpi - last_kpi
            })
            setIndikator(report_kpi)
            setReport(result.grafik)
            setTotal(result.grafik[index][1].reduce((a,b) => a + b,0))

        })
        .catch(error => console.log(error))
    }

    const setParam = (e) => {
        setIndex(e)
        setTotal(report[e][1].reduce((a,b) => a + b,0))
    }

    useEffect(() => {
        rangeDate()
    },[typeDate])

    const rangeDate =() => {
        let data = setRangeDate(typeDate);
        setFilterDate({start_date:data.start, end_date: data.end })
    }

    
    var barChartData = {
        labels:report[index][0],
        datasets: [
            {
                label: 'y',
                lagend: 'y',
                data: report[index][1],
                borderColor: "#4466f2",
                backgroundColor: "rgba(68, 102, 242, 0.4)",
                highlightFill: "rgba(68, 102, 242, 0.95)",
                highlightStroke: "#4466f2",
                borderWidth: 2
            }
        ],
        plugins: {
            datalabels: {
                display: false,
                color: 'white'
            }
        }
    }


    return (
        <>
           <Breadcrumb parent="Home" title = "Report Bisdev" />
           <div className="container-fluid">
                <div className="row justify-content-center mb-2">
                    <div className="col-md-3">
                        <DatePicker dateFormat='MMMM yyyy' selected={date._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={handlePickerMonth} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div style={{padding:'11px'}}>
                            <DataKPI kpi={kpi} />
                                <div className="progress-showcase">
                                    <Progress multi style={{ height: "15px"}}>
                                        <Progress animated style={{backgroundColor:color1}} bar value={kpi.MoU}>{kpi.MoU} %</Progress>
                                        <Progress animated style={{backgroundColor:color2}} bar value={kpi.visit}>{kpi.visit} %</Progress>
                                        <Progress animated bar style={{backgroundColor:'#d6d6c2'}} value={kpi.selisih} />
                                    </Progress>
                                </div>
                                <div style={{color:'white'}} className="mt-1">
                                    <span style={{fontSize:'8px',backgroundColor:color1}} className="badge badge-pill">MoU & Ambassador</span>
                                    <span style={{fontSize:'8px',backgroundColor:color2}} className="badge badge-pill">Visit & Info Session</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-8 col-sm-12">
                        <DetailKPI indikator={indikator}/>
                    </div> */}
                </div>
                <div className="row justify-content-center">
                    <div className="col-sm-12"></div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row justify-content-between">
                                        <div className="col">
                                        <strong>Peformance BisDev</strong>
                                        </div>
                                        <div className="col">
                                            <select name='index' value={index} onChange={e => setParam(e.target.value)} className='form-control'>
                                            {
                                            master.map((indikator, index) => (
                                            <option key={indikator.id} value={index}>{indikator.name}</option>
                                            ))
                                            }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center font-primary font-weight-bold">
                                    <CountUp separator="." className="counter" end={total} />
                                </div>
                                <div className="card-body chart-block">
                                    <Bar height={200} data={barChartData} options={barChartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <SchoolVisitSchedule/>
                        </div>
                </div>
                <div className="row justify-content-center">
                    <select name='date' value={typeDate} onChange={e => setTypeDate(e.target.value)} className='form-control col-sm-3 mb-1'>
                        <option value='this_week'>This Week</option>
                        <option value='last_week'>Last Week</option>
                        <option value='this_month'>This Month</option>
                        <option value='last_month'>Last Month</option>
                        <option value='this_year'>This Year</option>
                        <option value='last_year'>Last Year</option>
                        <optgroup label='Quartal'>
                            <option value='1'>Quartal I</option>
                            <option value='2'>Quartal II</option>
                            <option value='3'>Quartal III</option>
                            <option value='4'>Quartal IV</option>
                        </optgroup>
                    </select>
                    <div className='col-sm-12 mb-3'>
                        <span style={{fontSize:'1.5em'}} className='f-w-600'>{Moment(filterDate.start_date).format('DD MMMM yyyy')} - {Moment(filterDate.end_date).format('DD MMMM yyyy')}</span>
                    </div>
                    <div className="col-sm-6">
                        <PeformanceBizdev  start_date={filterDate.start_date} end_date={filterDate.end_date} />
                    </div>
                    <div className="col-sm-12">
                        <IncomeBizdev  start_date={filterDate.start_date} end_date={filterDate.end_date} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardBisDev;

