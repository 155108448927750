/* eslint-disable react-hooks/exhaustive-deps */

import React ,{ useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardCount from './component/card-count';

const CardStatic = () => {

  const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll:1,
        arrows: false,
        autoplay:true,
        responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
            slidesToShow: 3,
            }
        },
        {
            breakpoint: 480,
            settings: {
            slidesToShow: 2,
            }
        },
        {
            breakpoint: 370,
            settings: {
            slidesToShow: 1,
            }
        }
        ]
    };

    const[dataStatic,setDataStatic] = useState({ students:0, members:0, ambassador: 0, user_apps:0, student_apps:0 })

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    useEffect(() => {
        loadDataStatics()
    },[])


    const loadDataStatics = () => {
        axios.get(API_URL.superStudent+'/recap-home-statis', {
            headers: { 'Authorization': `Bearer ${dataToken}` } 
        })
        .then(response => response.data.data)
        .then(result => {
            setDataStatic({
                students: result.students,
                members: result.members,
                ambassador: result.ambassadors,
                //user_apps: result.user_apps,
                student_apps: result.student_apps
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    return(
        <>
        <div className='row'>
            <div className='col-md-12 ecommerce-slider'>
                <Slider {...settings}>
                    <CardCount handleRedirect='membership' backgroundColor='#35d3da' icon='fa-credit-card' label='Members' amount={dataStatic.members} />
                    <CardCount handleRedirect='students' backgroundColor='#9cb806' icon='fa-graduation-cap' label='Students' amount={dataStatic.students} />
                    <CardCount handleRedirect='ambassador' backgroundColor='brown' color='white' icon='fa-users' label='Ambassador' amount={dataStatic.ambassador} />
                    <CardCount backgroundColor='red' icon='fa-users' label='User Apps' amount={dataStatic.student_apps} />
                </Slider>
            </div>
        </div>
        </>
    )
}

export default CardStatic;