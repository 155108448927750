/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import PeformanceTable from './peformance-table'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class KPIReport extends Component{

    constructor(props){
        super(props)
        this.state = {
            reportKPI:[],
            HistoryKPI:[],
            totalBobot:0,
            timeType:1,
            displayGrafik:'none',
            filterStartDate:Moment().startOf('isoWeek'),
            filterEndDate:Moment().endOf('isoWeek'),
            showComponent:false,
        }
    }

    componentDidMount(){
        this.loadKPIReportKPI();
        this.loadHistoryKPI()
    }

    loadKPIReportKPI = () => {
        let formData = new FormData()
        formData.append('code_konsultan',this.props.employe_code)
        formData.append('reference_type','1')
        formData.append('time_type', this.state.timeType)
        formData.append('start_date', this.state.filterStartDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndDate.format('YYYY-MM-DD'))

        axios.post(API_URL.superStudent+'/kpi/report-per-employee', formData,{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then( result => {
            let totalBobot = 0
            result.forEach(function(item){
                totalBobot+=parseFloat(item.nilai_bobot)
            })
            this.setState({ reportKPI: result, showComponent:true, totalBobot})
        })
        .catch(error => console.log(error))
    }

    loadHistoryKPI = () => {
        let formData = new FormData()
        formData.append('code_konsultan', this.props.employe_code)
        formData.append('reference_type','1')
        formData.append('time_type', this.state.timeType)
        formData.append('start_date', this.state.filterStartDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.filterEndDate.format('YYYY-MM-DD'))

        axios.post(API_URL.superStudent+'/kpi/history-per-employee', formData,{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => response.data.data)
        .then( result => {
            this.setState({ HistoryKPI: result})
        })
        .catch(error => console.log(error))
    }

    handleType = (e) => {
        let startDate = e.target.value == 1 ? Moment().startOf('isoWeek') : e.target.value == 2 ? Moment().startOf('month') : Moment().startOf('year').add(6,'months')
        let endDate = e.target.value == 1 ? Moment().endOf('isoWeek') : e.target.value == 2 ? Moment().endOf('month') : Moment().endOf('year').add(6,'months')
        this.setState({
            timeType:e.target.value,
            filterStartDate: startDate,
            filterEndDate: endDate,
            displayGrafik: e.target.value == 4 ? '' : 'none'
        },() => {
            this.loadKPIReportKPI()
            this.loadHistoryKPI()
        })
    }

    handlePickerMonth = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('month'),
            filterEndDate: Moment(e).endOf('month'),
            timeType:2
        },() => {
            this.loadKPIReportKPI()
        })
    }

    handlePickerWeek = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('isoWeek'),
            filterEndDate: Moment(e).endOf('isoWeek'),
            timeType:1,
            valueWeek:e
        },() => {
            this.loadKPIReportKPI()
        })
    }

    render(){
        return(
            <>
                <div className='container-fluid'>
                    <div className='edit-profile'>
                        <div className='row justify-content-center'>
                            <div className='col-sm-3 mb-2'>
                                <select value={this.state.timeType} onChange={this.handleType} className='form-control'>
                                    <option value='1'>Weekly</option>
                                    <option value='2'>Monthly</option>
                                    <option value='4'>Thir Year</option>
                                </select>
                            </div>
                            {
                                this.state.timeType == 1 ?
                                <DatePicker dateFormat='d MMMM yyyy' selected={this.state.filterStartDate._d} startDate={this.state.filterStartDate._d} endDate={this.state.filterEndDate._d} className='form-control' onChange={this.handlePickerWeek} />
                                :
                                this.state.timeType == 2 ?
                                <DatePicker dateFormat='MMMM yyyy' selected={this.state.filterStartDate._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                                :
                                ''
                            }
                            <div className='col-sm-12'>
                                {
                                    this.state.showComponent === true ? <PeformanceTable data={this.state.reportKPI} totalBobot={this.state.totalBobot} grafikKPI={this.state.HistoryKPI} showGrafik={this.state.displayGrafik} /> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null) (KPIReport);