
/* eslint-disable eqeqeq */
import API from '../services';
import axios from 'axios';
import API_URL from '../ApiUrl';
 
import {
    GET_LGNSTS,
    GET_USERSDATA,
    LOGOUT,
    INITIALIZE,
    GET_LISTMENU,
    RESET_TOKEN,
    CLEAR_STUDENTS,
    USERABLE_DATA,
    TOTAL_LAST
} from '../constant/actionTypes';

export const LoginStudentAPI = (data) => async (dispatch) => {
    return (
        await axios.post(API_URL.url+'/api/membership-check', data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${API_URL.niecsisKey}`
            }
        })
        .then(response => {
            const result = response.data
                dispatch({type: GET_LGNSTS, value: true })
                dispatch({type: GET_USERSDATA, value: result.message })
                dispatch({type: GET_LISTMENU, value: result.listmenu })
                dispatch({type: INITIALIZE, value: result.api_token })
                return {
                        loggedIn: true,
                        token: result.api_token,
                        cekmenu: result.listmenu,
                        dataUser:result.message,
                        dataResponse: response
                }
        })
        .catch(err => {
            dispatch({type: GET_LGNSTS, value: false })
            dispatch({type: GET_USERSDATA, value:{} })
            dispatch({type: GET_LISTMENU, value: [] })
            dispatch({type: INITIALIZE, value: '' })
                return {
                    loggedIn: false,
                    token: '',
                    cekmenu: [],
                    dataUser: ''
                }
        })
    )
}

export const loginUserAPI = (data) => (dispatch) => {
    return (
        axios.post(API_URL.superStudentAuth+'/login-sis', data)
        .then((res) => {
            const detailUser = res.data;
            // console.log(detailUser.message.userable_type == 'App\\Models\\Consultant');
            if(detailUser.status === true) {
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(function(position) {
                        let formData = new FormData()
                        formData.append('code_users', detailUser.message.code_users)
                        formData.append('latitude', position.coords.latitude)
                        formData.append('longitude', position.coords.longitude)
                        axios.post(API_URL.superStudent+'/log-location', formData, {
                            headers:{ Authorization: `Bearer ${detailUser.api_token}` }
                        })
                        .then(() => console.log('data log location disimpan'))
                        .catch(error => console.log(error))
                    });
                } else {
                    console.log("Not Available");
                }

                if (detailUser.message.userable_type == 'App\\Models\\Consultant') {
                    dispatch({ type: GET_USERSDATA, value: detailUser.message })
                    dispatch({ type: INITIALIZE, value: detailUser.api_token })
                    // dispatch({ type: GET_LGNSTS, value: detailUser.status })
                    // dispatch({ type: TOTAL_LAST, value: detailUser.last_day })

                    if(detailUser.last_day >= 90 || detailUser.last_day == null) {
                        dispatch({ type: GET_LISTMENU, value: [] })
                        return {
                            loggedIn: true,
                            token: detailUser.api_token,
                            cekmenu: [],
                            roles: detailUser.message.code_roles,
                            userable_type: detailUser.message.userable_type,
                        };
                    } else {
                        dispatch({ type: GET_LISTMENU, value: detailUser.listmenu })
                        return {
                            loggedIn: true,
                            token: detailUser.api_token,
                            cekmenu: detailUser.listmenu,
                            userable_type: detailUser.message.userable_type
                        };
                    }   
                } else {
                    dispatch({ type: GET_USERSDATA, value: detailUser.message })
                    dispatch({ type: INITIALIZE, value: detailUser.api_token })
                    dispatch({ type: GET_LGNSTS, value: detailUser.status })
                    dispatch({ type: TOTAL_LAST, value: detailUser.last_day })
                    dispatch({ type: GET_LISTMENU, value: detailUser.listmenu })
                    return {
                        loggedIn: true,
                        token: detailUser.api_token,
                        cekmenu: detailUser.listmenu,
                        userable_type: detailUser.message.userable_type
                    };
                }
            } else {
                dispatch({type: GET_LGNSTS, value: detailUser.status })
                return { loggedIn: false };
            }
        })
    )
}

export const registerUserAPI = (data) => (dispatch) => {
    return (

        API.register(data)
        .then((res) => {
                const detailUser = res.data;
                if(detailUser.status === true){

                    dispatch({type: GET_LGNSTS, value: detailUser.status })
                    dispatch({type: GET_USERSDATA, value: detailUser.message })
                    dispatch({type: GET_LISTMENU, value: detailUser.listmenu })
                    dispatch({type: INITIALIZE, value: detailUser.api_token })
                    

                    return {
                        loggedIn: true,
                        token: detailUser.api_token,
                        cekmenu: detailUser.listmenu
                      };
                }else{
                    dispatch({type: GET_LGNSTS, value: detailUser.status })
                    return {
                        loggedIn: false
                      };
                }

        })
    )
}

export const logout = (data, headers) => (dispatch) => {
    dispatch({type: LOGOUT});
    dispatch({type: CLEAR_STUDENTS});   
}

export const resettoken = (data, headers) => (dispatch) => {
    API.resettoken(data, headers)
        .then((res) => {
            const detailUser = res.data;
                if(detailUser.code === 400){
                    dispatch({type: RESET_TOKEN});
                }else{
                    dispatch({type: RESET_TOKEN});
                }
        })
}

export const getDataUserable = (token) => async (commit) => {
    return (
        await axios.get(API_URL.superStudent+'/userable', {
            headers:{
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            commit({type: USERABLE_DATA, value: response.data.data})
        })
        .catch(error => {
            throw(error)
        })
    )
}

export const updataDataUserable = (data,id,token) => async (commit) => {
    return (
        await axios.post(API_URL.superStudent+'/universities/'+id, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            axios.get(API_URL.superStudent+'/userable', {
                headers:{
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {
                commit({type:USERABLE_DATA, value: result.data.data})
            })
            .catch(error => {
                throw(error)
            })
        })
        .catch(error => {
            throw(error)
        })
    )
}

export const updateDataMenu = (data) => (dispatch) => {
    return (
        dispatch({ type: GET_LISTMENU, value: data })
    )
}

export const updateCurrentLogin = (status) => (dispatch) => {
    return (
        dispatch({ type: GET_LGNSTS, value: status })
    )
}
