import React, { Fragment, Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import Loader from '../../../components/common/loader';
import Breadcrumb from '../../../components/common/breadcrumb';
import EventCard from '../particion/event-card';

class UniversityEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            title: "",
            operation: "",
            logName: "",
            errors: "",
            modal: false,
            modalDelete: false,
            btnDisabled: false,
            dataListEvent: [],
            data_cart: [],
            id: "",
            code_event: "",
            totalKota: 0,
            hargaKota: 0,
            selectKota: []
        }
    }

    componentDidMount() {
        this.getDataMasterEvent();
    }

    getDataMasterEvent() {
        this.setState({
            showLoading:true
        });

        const url = API_URL.superStudent+'/event/all';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                showLoading:false,
                dataListEvent:data.data
            });
        });
    }

    render() {
        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <Breadcrumb title="NIEC Event" parent="NIEC Event" />
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='row justify-content-center'>
                                        {
                                            this.state.dataListEvent.length > 0 ?
                                            this.state.dataListEvent.map((detail, i) => (
                                                <EventCard data={detail} />
                                            ))
                                            :
                                            <Loader showLoading={this.state.showLoading} />
                                        }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    userableData: state.Login.userableData
})

export default connect(reduxState)(withRouter(UniversityEvent));