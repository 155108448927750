/* eslint-disable react-hooks/exhaustive-deps */

import React ,{ useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../../ApiUrl';
import axios from 'axios'
import DataCollapse from './component/data-collapse';
import { ExportCSV } from '../../../services/ExportCSV';


const StudentGrantedLocation = ({start_date, end_date}) => {

    const[params,setParams] = useState('university')
    const[data,setData]= useState([]);
    const[dataGranted, setDataGranted] = useState([])
    const stdGranted = [];

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const loadGraphic = () => {
        let formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        axios.post(API_URL.superStudent+'/graphic-location-student/'+params, formData,{
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            setData(result)
        })
        .catch(error => console.log(error))
    }

    const getAllStudentGranted = () => {
        let formData = new FormData()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('param', params)
        axios.post(API_URL.superStudent+'/graphic-location-student/granted-student', formData,{
            headers: {
                'Authorization': `Bearer ${dataToken}`
            }
        })
        .then(response => {
            const std = response.data.data;
            for (let i = 0; i < std.length; i++) {
                stdGranted.push({
                    code_student: std[i].code_students, 
                    nama: std[i].nama, 
                    label: std[i].label, 
                })
            }
            setDataGranted(stdGranted);

        })
    }

    useEffect(() => {
        loadGraphic()
        getAllStudentGranted()
    },[params, start_date, end_date])

    return(
        <>
            <div className="card">
                <div className="card-header">
                    <div className='row'>
                        <div className='col-sm-6 col-md-6 col-lg-3'>
                                <h6 className='f-w-600'>Top Students Granted</h6>
                        </div>
                        <div className='col-sm-6 col-md-4 col-lg-3 mb-3'>
                            <div className="select2-drpdwn-project select-options">
                                <select onChange={e => setParams(e.target.value)} value={params} className="form-control form-control-primary btn-square" name="params">
                                    <option value="province">Provinsi</option>
                                    <option value="kabupaten">Kabupaten</option>
                                    <option value="school">Sekolah Asal</option>
                                    <option value="university">Kampus Tujuan</option>
                                    </select>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-6">
                            <ExportCSV csvData={dataGranted} fileName={`student_granted_by_${params}`}/>
                        </div>
                    </div>
                </div> 
                <div className="card-body scroll" style={{height:450}}>
                    <ul className="list-group">
                        {
                            data.length > 0 ?
                            data.map((list, index) => (
                                    <DataCollapse key={index} list={list} index={index} type={params} />
                            ))
                            :''
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default StudentGrantedLocation;
