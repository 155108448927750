/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { resettoken } from '../../actions/login.action';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-table/react-table.css';
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from 'react-table';
import {matchSorter} from 'match-sorter';
import debounce from 'lodash.debounce';

class DataLeadsAll extends Component {
    constructor(props) {
        super(props);
        this.filterColumn = debounce(this.filterColumn.bind(this),500);
        this.state = {
            showLoading: false,
            modalSet: false,
            url: API_URL.superStudent+'/event/leads-all',
            filtered: [],
            dataLeads: [],
            dataKonsultan: [],
            dataCategory: [],
            dataListEvent: [],
            id_registrasis: '',
            nama_registrasis: '',
            consultant: '',
            potensial: '',
            next: '',
            previous: '',
            notes: '',
            perPage: 10,
            skip: 10,
            filter_params: '',
            filterEvent: '',
            filterCategory: '',
            filterConsultant: '',
        }
    }

    componentDidMount() {
        this.getAllLeads();
        this.getConsultant();
        this.getCategory();
        this.getDataMasterEvent();
    }

    handleChangeSet = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getConsultant = () => {
        axios.get(API_URL.superStudent+'/master/consultant/list-priotas', {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                dataKonsultan: result
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getCategory = () => {
        axios.get(API_URL.superStudent + '/master/categories', {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then(result => {
            this.setState({
                dataCategory: result
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    getDataMasterEvent() {
        const url = API_URL.superStudent+'/master-event';
        axios.get(url, { headers: 
            { 'Authorization': `Bearer ${this.props.dataToken}` } }).then(response => response.data)
        .then((data) => {
            this.setState({
                dataListEvent: data.data
            });
        });
    }

    getAllLeads = () => {
        var formData = new FormData();
        formData.append('per_page', this.state.perPage);
        formData.append('skip', this.state.skip);
        if(this.state.filter_params.length > 0)
            formData.append('filter', JSON.stringify(this.state.filter_params));

        axios.post(this.state.url, formData, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => response.data.data)
        .then((data) => {
            this.setState({ 
                dataLeads: data.data,
                halaman: data.links,
                previous: data.prev_page_url,
                next: data.next_page_url
            })
        })
    }

    getAllLeadsByPaging = (url) => {
        this.setState({
            url: url
        }, () => {
            this.getAllLeads()
        })
    }

    SetFilterByEvent = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterEvent = newData; 
        return { filterEvent };                   
        }, () => {
          this.onFilteredChangeCustom(newData, "id_event");
        })
    }

    SetFilterByCategory = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterCategory = newData; 
        return { filterCategory };                   
        }, () => {
          this.onFilteredChangeCustom(newData, "category");
        })
    }

    SetFilterByConsultant = (e) => {        
        const newData = e.target.value;
        this.setState(prevState => {
        let filterConsultant = newData; 
        return { filterConsultant };                   
        }, () => {
          this.onFilteredChangeCustom(newData, "consultant");
        })
    }

    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
    
        if (filtered.length) {
          filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
              if (value === "" || !value.length) filtered.splice(i, 1);
              else filter["value"] = value;
    
              insertNewFilter = 0;
            }
          });
        }
    
        if (insertNewFilter) {
          filtered.push({ id: accessor, value: value });
        }
    
        this.setState({ 
            filter_params: filtered
        },() => {
            this.filterColumn();
        });
    }

    filterColumn() {
        this.getAllLeads();
    }

    handleChangePerPage = (e) => {
        this.setState({
            perPage: e.target.value,
            skip: 0
        }, () => {
            this.getAllLeads()
        })
    }

    getLeadsDetail(row) {
        this.setState({
            id_registrasis: row.id,
            nama_registrasis: row.name,
            consultant: row.code_konsultan,
            potensial: row.code_category,
            modalSet: true
        })
    }

    getAllLeadsByPrev() {
        this.setState(prevState => ({
            skip: parseInt(prevState.skip) - parseInt(this.state.perPage)
        }), () => {
            this.getAllLeads()
        })
    }

    getAllLeadsByNext() {
        this.setState(prevState => ({
            skip: parseInt(prevState.skip) + parseInt(this.state.perPage)
        }), () => {
            this.getAllLeads()
        })
    }

    saveData() {
        var formData = new FormData();
        formData.append('id_registrasis', this.state.id_registrasis);
        formData.append('code_consultant', this.state.consultant);
        formData.append('code_students_category', this.state.potensial);
        formData.append('notes', this.state.notes);

        axios.post(API_URL.superStudent+'/event/set-leads', formData, {
            headers: {
                'Authorization': `Bearer ${this.props.dataToken}`
            }
        })
        .then(response => {
            toast.success('leads data update succesfully');
            this.setState({
                modalSet: false
            }, () => {
                this.getAllLeads()
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    renderModalSet = () => {
        return (
            <div className="card-body btn-showcase">
                <Modal isOpen={this.state.modalSet} toggle={() => this.setState({ modalSet: false })}>
                    <ModalHeader toggle={() => this.setState({ modalSet: false })}>Set Handle</ModalHeader>
                    <ModalBody>
                        <h5 className='mb-3'>{this.state.nama_registrasis}</h5>
                        <div className='form-group'>
                            <select onChange={this.handleChangeSet} name='consultant' className='form-control' value={this.state.consultant}>
                                <option value=''>Pilih Consultant</option>
                                {
                                    this.state.dataKonsultan.map(function (consultan) {
                                        return (<option key={consultan.id} value={consultan.code}>{consultan.name}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <select onChange={this.handleChangeSet} name='potensial' className='form-control' value={this.state.potensial}>
                                <option value=''>Pilih Potensial</option>
                                {
                                    this.state.dataCategory.map(function (category) {
                                        return (<option value={category.code_students_category}>{category.category}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <div className='form-group'>
                            <textarea className='form-control' name='notes' onChange={this.handleChangeSet} placeholder="Notes" value={this.state.notes}></textarea>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button disabled={this.state.btnDisabled} color="primary" onClick={() => this.saveData()}>Save</Button>
                        <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.setState({ modalSet: false })}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    render() {

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["email"] }),
                filterAll: true
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["phone"] }),
                filterAll: true
            },
            {
                Header: 'Event',
                accessor: 'event',
                style: {textAlign: 'center'},
                filterable: true,
                Cell:(row) => (
                    <ul className='row'>
                        {
                            row.original.event.length > 0 ?
                            row.original.event.map((event) => (
                                <li className='col'>{event.name}</li>
                            ))
                            :
                            ''
                        }
                    </ul>
                ),
                Filter: () =>
                <select className='form-control' value={this.state.filterEvent || ''} onChange={(e) => this.SetFilterByEvent(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataListEvent.map(event => {
                            return <option key={event.id} value={event.id}>{event.name}</option>
                        })
                    }
                </select> 
            },
            {
                Header: 'Kab. / Kota',
                accessor: 'nama_kabkota',
                style: {textAlign: 'center'},
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["nama_kabkota"] }),
                filterAll: true
            },
            {
                Header: 'Potensial Rep.',
                accessor: 'potensial',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <ul className='row'>
                        {
                            row.original.registration_rep.length > 0 ?
                            row.original.registration_rep.map((rep) => (
                                <li className='col'>{rep != null ? rep.nama_sekolah+" ("+rep.status_potensial+")" : ''}</li>
                            ))
                            :
                            ''
                        }
                    </ul>
                )
            },
            {
                Header: 'Category',
                accessor: 'category',
                style: {textAlign: 'center'},
                filterable: true,
                Filter: () =>
                <select className='form-control' value={this.state.filterCategory || ''} onChange={(e) => this.SetFilterByCategory(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataCategory.map(category => {
                            return <option key={category.code_students_category} value={category.code_students_category}>{category.category}</option>
                        })
                    }
                </select> 
            },
            {
                Header: 'Consultant Follow Up',
                accessor: 'konsultan_name',
                filterable: true,
                Filter: () =>
                <select className='form-control' value={this.state.filterConsultant || ''} onChange={(e) => this.SetFilterByConsultant(e)}>
                    <option key={0} value=''>Show All</option>
                    {
                        this.state.dataKonsultan.map(consultant => {
                            return <option key={consultant.code} value={consultant.code}>{consultant.name}</option>
                        })
                    }
                </select> 
            },
            {
                Header: 'Action',
                accessor: 'action',
                style: {textAlign: 'center'},
                filterable: false,
                Cell:(row) => (
                    <div>
                        {
                            row.original.konsultan_name == null ? 
                            <button onClick={() => this.getLeadsDetail(row.original)} className="btn btn-outline-success btn-xs" type="button" style={{border:'none'}}><i className="fa fa-pencil" style={{ fontSize: 16}}></i></button>
                            :
                            ""
                        }
                    </div>
                )
            },
        ]

        return (
            <Fragment>
                <div className="page-wrapper">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className='card-header'>
                                        <h5>Leads Event</h5>
                                    </div>
                                    <div className="card-body datatable-react">
                                        <ReactTable
                                            data={this.state.dataLeads}
                                            columns={columns}
                                            pageSize={this.state.perPage}
                                            filterable
                                            className="-striped -highlight"
                                            showPaginationBottom={false}
                                            showPageSizeOptions={true}
                                            onFilteredChange={(filtered, column, value) => {
                                                this.onFilteredChangeCustom(value, column.id || column.accessor);
                                            }}
                                        />
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <nav className="mt-3 m-b-30" aria-label="Page navigation example">
                                                    <Pagination aria-label="Page navigation" className="pagination justify-content-start pagination-primary">
                                                        {
                                                            this.state.halaman != null ?
                                                            this.state.halaman.map((halaman) => (
                                                                halaman.url != null ?
                                                                <PaginationItem onClick={() => this.getAllLeadsByPaging(halaman.url)}>
                                                                    <PaginationLink>
                                                                        {
                                                                            halaman.label == "&laquo; Previous" ?
                                                                            "Previous"
                                                                            :
                                                                            halaman.label == "Next &raquo;" ?
                                                                            "Next"
                                                                            :
                                                                            halaman.label
                                                                        }
                                                                    </PaginationLink>
                                                                </PaginationItem>
                                                                :
                                                                ''
                                                            ))
                                                            :
                                                            ''
                                                        }
                                                    </Pagination>
                                                </nav>
                                            </div>
                                            <div className='col-lg-6 d-flex justify-content-end'>
                                                <div className='row'>
                                                    <div className='col-lg-6'><p className='mt-3 m-b-30'>Per Page</p></div>
                                                    <div className='col-lg-6'>
                                                        <div className='mt-3 m-b-30'>
                                                            <select className='form-control' name='perPage' value={this.state.perPage} onChange={(e) => this.handleChangePerPage(e)}>
                                                                <option value={5}>5</option>
                                                                <option value={10}>10</option>
                                                                <option value={25}>25</option>
                                                                <option value={50}>50</option>
                                                                <option value={100}>100</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModalSet()}
            </Fragment>
        )
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users,
    codeRoles: state.Login.detailUser.code_roles,
})
  
const reduxDispatch = (dispatch) => ({
    resettoken: (data, headers) => dispatch(resettoken(data, headers))
})
  
export default connect(reduxState, reduxDispatch)(withRouter(DataLeadsAll));