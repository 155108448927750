/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import "./styles/react-router-tabs.css";

const VisitCampus = (dataUser) => {
    const roles = dataUser.dataUser.code_roles
    return (
        <Fragment>
            <Breadcrumb title="Campus Visit" parent="Campus Visit" />
        </Fragment>
    );
}

// export default Students;
const reduxState = (state) => ({
    loginStatus: state.Login.lgnsts,
    dataUser: state.Login.detailUser,
})
  
export default connect(reduxState, null)(withRouter(VisitCampus));