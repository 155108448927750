/* eslint-disable react-hooks/exhaustive-deps */

import React ,{useEffect, useState} from 'react';
import { useSelector } from "react-redux";
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';

const CardMeetingNote = ({start_date, end_date}) => {

    const[meetingNote,setMeetingNotes] = useState([])

    const { dataToken } = useSelector(state => ({
        dataToken:state.Login.lgnInitialize
    }));

    const { dataUser } = useSelector(state => ({
        dataUser:state.Login.detailUser
    }));

    useEffect(() => {
        loadDataMeetingNotesByConsultant()
    },[start_date, end_date])

    const loadDataMeetingNotesByConsultant = () => {
        let formData = new FormData ()
        formData.append('start_date', start_date)
        formData.append('end_date', end_date)
        formData.append('code_consultant', dataUser.code_konsultan)
        axios.post(API_URL.superStudent+'/todo/list-meeting-notes', formData, {
            headers:{ Authorization: `Bearer ${dataToken}` }
        })
        .then(response => response.data.data)
        .then(result => {
            setMeetingNotes(result);
        })
        .catch(error => console.log(error))
    }

    return( 
        <>
            <div style={{height: '400px', maxHeight: '400px'}} className='card scroll recent-notification'>
                <div className='card-header'>
                    <h6 className='f-w-600'>Meeting Notes</h6>
                </div>
                <div className='card-body'>
                    {
                        meetingNote.length > 0 ?
                        meetingNote.map((item,index) => (
                        <div style={{marginBottom:10}} key={index} className="media">
                            <strong>{Moment(item.task_periode.created_at).format('DD MMM YY')}</strong>
                            <div className="media-body">
                                <span style={{fontSize:11}}>{item.description}</span>
                            </div>
                        </div> 
                        ))
                        :
                        'Data Kosong'
                    }    
                </div>
            </div>
        </>
    )

}

export default CardMeetingNote;

