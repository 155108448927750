/* eslint-disable eqeqeq */

import React, { Fragment, Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import seven from '../../../assets/images/user/7.jpg';
import pdf from '../../../assets/images/document-logo/pdf-logo.png';
import { Link } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { saveAs } from "file-saver";
import Moment from 'moment';

class ProfileData extends Component {
    constructor(props, data) {
      super(props);
      this.state = {
        btnText:'Save',
        url:window.location.pathname.split("/").pop(),
        styleEditbtn:'',
        btnDisabled:false,
        modalEdit:false,
        modalContract:false,
        modalDeleteFile:false,
        modalPreview:false,
        urlPreview:'',
        ambasador_id:'',
        name:'',
        code_ambassador:'',
        code_recommender:'',
        code_acc_manager:'',
        email:'',
        phone:'',
        province:'',
        kabupaten:'',
        about:'',
        username:'',
        photo:'',
        contract:'',
        start_contract:'',
        is_students_ambassador:0,
        no_rek:'',
        bank_name:'',
        account_name:'',
        type_accomodir: '',
        dataProvinsi:[],
        dataKabKota:[],
        dataBizDev:[],
        dataManager:[],
        dataKabKotaFilter:[],
        dataContract:[],
        errors:[]
      }
    }

    componentDidMount(){
      this.getProvinsi();
      this.getDataProfile();
      this.getBizdev();
      this.getManager();
    }

    handleInput = (e) => {
      this.setState({
        [e.target.name] : e.target.value
      }, () => {
        this.getKabKota();
      })
    }

    editProfile = () => {
      this.setState({
        modalEdit:true,
      })
    }

    addContract = () => {
      this.setState({
        modalContract: true,
        start_contract: ''
      })
    }

    getDataProfile() {
      let data = this.props.data
      let dataUsers = this.props.dataUsers
      this.setState({
        id:data.id,
        code_ambassador:data.code_ambassador,
        name:data.name,
        styleEditbtn:this.props.match.params.codeAmbassador == dataUsers.code_ambassador || dataUsers.code_roles != 'RL008' ? '' : 'none',
        email:data.email,
        phone: data.phone,
        about: data.about,
        photo:data.foto,
        is_students_ambassador: data.is_students_ambassador,
        province:data.province !== undefined ? data.province.id : '',
        kabupaten: data.kabupaten !== undefined ? data.kabupaten.id : '',
        username:data.user_data !== null ? data.user_data.username :'',
        code_recommender:data.recommender !== null ? data.recommender.code : '',
        code_acc_manager:data.manager !== null ? data.manager.code : '',
        no_rek: data.no_rek,
        bank_name: data.bank_name,
        account_name: data.account_name,
        type_accomodir: data.type_accomodir,
      }, () => {
        this.getContract()
      })
    }

    getProvinsi(){
      const url = API_URL.superStudent+'/master/provinces';
      axios.get(url,{
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataProvinsi: data },() => {
          this.getKabKota();
        })
      })
    }

    getBizdev(){
      const url = API_URL.superStudent+'/consultant/jobdesk';
      let formData = new FormData ()
      formData.append('jobdesk', JSON.stringify(['JD007','JD016']))
      axios.post(url, formData, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
      .then(response => response.data.data)
      .then(result => this.setState({ dataBizDev: result}))
      .catch(error => console.log(error))    
    }

    getManager(){
      const url = API_URL.superStudent+'/consultant/jobdesk';
      let formData = new FormData ()
      formData.append('jobdesk', JSON.stringify(['JD004']))
      axios.post(url, formData, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
      .then(response => response.data.data)
      .then(result => this.setState({ dataManager: result}))
      .catch(error => console.log(error))    
    }

    getContract(){
      axios.get(API_URL.superStudent+'/ambassador/get-contract/'+this.state.id, {
        headers: { 'Authorization': `Bearer ${this.props.dataToken}`}
      })
      .then(response => this.setState({ dataContract: response.data.data}))
      .catch(error => console.log(error))
    }

    handleSubmit = (e) => {
      e.preventDefault()
      var formData = new FormData();
      formData.append('_method','PATCH');
      formData.append('id_register', this.state.id);
      formData.append('name', this.state.name);
      formData.append('email', this.state.email);
      formData.append('phone', this.state.phone);
      formData.append('province', this.state.province);
      formData.append('kabupaten', this.state.kabupaten);
      formData.append('about', this.state.about);
      formData.append('photo', this.state.photo);
      formData.append('code_recommender', this.state.code_recommender);
      formData.append('code_acc_manager', this.state.code_acc_manager);
      formData.append('is_students_ambassador', this.state.is_students_ambassador);
      formData.append('no_rek', this.state.no_rek);
      formData.append('bank_name', this.state.bank_name);
      formData.append('account_name', this.state.account_name); 
      formData.append('type_accomodir', this.state.type_accomodir); 
      this.setState({
        btnText:'Saving...',
        btnDisabled:true
      });

      axios.post(API_URL.superStudent+'/ambassadors/'+this.state.id, formData, {
        headers:{
          Authorization: `Bearer ${this.props.dataToken}`,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        window.location.reload()
        toast.success("Data rep school berhasil diperbaharui");
      })
      .catch(error => {
        if(error.response.status === 422){
          this.setState({
            btnText:'Save',
            errors:error.response.data.data,
            btnDisabled:false
          });
        }
      })
    }

    handleDelete = (e) => {
      axios.delete(API_URL.superStudent+'/ambassador/delete-contract/'+this.state.id,{
        headers:{ Authorization: `Bearer ${this.props.dataToken}`}
      })
      .then(response => {
        window.location.reload()
        toast.success("Data contract di perbaharui");
      } )
    }

    handleContract = (e) => {
      e.preventDefault()
      this.setState({
        btnText:'Saving...',
        btnDisabled:true
      });
      let formData = new FormData()
      formData.append('contract', this.state.contract)
      formData.append('start_contract', this.state.start_contract)
      formData.append('id', this.state.id)
      axios.post(API_URL.superStudent+'/ambassador/upload-contract', formData, {
        headers:{
          Authorization: `Bearer ${this.props.dataToken}`,
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => {
        this.setState({
          btnText:'Save',
          btnDisabled:false
        });
        window.location.reload()
        toast.success("Data rep school berhasil diperbaharui");
      })
      .catch(error => console.log(error))
    }

    handleChangeContract = (e) => {
      this.setState({
        contract:e.target.files[0]
      })
    }

    handleChangeImage = (e) => {
      this.setState({
        photo:e.target.files[0]
      })
    }

    handleModalPreivew = (urlPreview) => {
      this.setState({
        modalPreview:true,
        urlPreview
      })
    }

    handleModalDelete = (id) => {
      this.setState({
        modalDeleteFile:true,
        id:id
      })
    }
    
    getKabKota(){
      const url = API_URL.superStudent+'/master/kabupaten/'+this.state.province;
      axios.get(url,{
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.dataToken}`
        }
      })
      .then(response => response.data.data)
      .then((data) => {
        this.setState({ dataKabKota: data })
      })
    }

    closeModal(){
      this.setState({
        modalEdit: false,
        modalContract:false,
        modalDeleteFile:false
      });
    }

    downloadFile = () => {
      let url = this.state.urlPreview
      let filename = url.substring(url.lastIndexOf('/'))
      saveAs(url,filename);
    }

    filePreview(link_doc){
      this.setState({
        modalPreview:true,
        urlPreview:link_doc
      })
    }

    closePreview(){
      this.setState({
        modalPreview: false,
        urlPreview:''
      });
    }

    renderPreviewModal(){
      return(
        <Modal className="modal-dialog" isOpen={this.state.modalPreview} toggle={() => this.closePreview()} size="lg">
          <ModalHeader toggle={() => this.closePreview()}>Dokumen Kontrak</ModalHeader>
          <ModalBody style={{maxHeight: 'calc(100vh - 210px)', overflowY: 'auto'}}>
            <DocViewer 
                pluginRenderers={DocViewerRenderers} 
                documents={[{uri: this.state.urlPreview}]} 
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: true,
                    retainURLParams: true
                  }
                }}
            />
            <div className="form-row mt-2" style={{textAlign: "end"}}>
              <div className="col-md-12">
                <button onClick={this.downloadFile} className="btn btn-outline-success" type="button"><i className="fa fa-download"></i></button>
              </div>
            </div>
          </ModalBody>
      </Modal>
      )
    }

    renderModalDelete(){
      return (
        <Modal className="modal-dialog" isOpen={this.state.modalDeleteFile} toggle={() => this.closeModal()}>
          <ModalHeader toggle={() => this.closeModal()}>Delete Contract</ModalHeader>
          <form onSubmit={this.handleDelete} >
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
              Apakah Anda yakin ingin menghapus contract ?
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" type="submit" >{this.state.btnText}</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>
      )
    }

    renderContarctDialog(){
      return (
        <Modal className="modal-dialog" isOpen={this.state.modalContract} toggle={() => this.closeModal()} size="lg">
          <ModalHeader toggle={() => this.closeModal()}>Upload Contract</ModalHeader>
          <form onSubmit={this.handleContract} >
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="id_kabkota">File Kontrak</label>
                  <input name='photo' className='form-control' type='file' onChange={this.handleChangeContract}/>
                </div>
              </div>
              <div className='form-row'>
                <div className='col-md-12 mb-3'>
                  <label htmlFor="id_kabkota">Tanggal mulai kontrak</label>
                  <input name='start_contract' className='form-control' type='date' value={this.state.start_contract} onChange={this.handleInput}/>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" type="submit" >{this.state.btnText}</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>
      )
    }

    renderEditDialog() {
      const modal = this.state.modalEdit;
      return(
        <Modal className="modal-dialog" isOpen={modal} toggle={() => this.closeModal()} size="lg">
          <ModalHeader toggle={() => this.closeModal()}>Edit Profile</ModalHeader>
          <form onSubmit={this.handleSubmit} >
            <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="nama">Nama<span className='text-danger'>*</span> </label>
                    <input className="form-control" name="name" type="text" onChange={this.handleInput} value={this.state.name}/>
                  <span><span className='text-danger'>{this.state.errors.name}</span></span>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="alamat">Telepon/WA<span className='text-danger'>*</span> </label>
                    <input className="form-control" name="phone" onChange={this.handleInput} type="text" value={this.state.phone}/>
                  <span><span className='text-danger'>{this.state.errors.phone}</span></span>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="id_propinsi">Provinsi<span className='text-danger'>*</span></label>
                    <select className="form-control" name="province" onChange={this.handleInput} type="text" value={this.state.province}>
                      <option value="0">--Pilih Province--</option>
                      {this.state.dataProvinsi.map((data)=>(
                        <option key={data.id} value={data.id}>{data.province_name}</option>
                      ))}
                    </select>
                  <span style={{color:"#FF5370"}}><span className='text-danger'>{this.state.errors.province}</span></span>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="id_kabkota">Kab / Kota<span className='text-danger'>*</span> </label>
                    <select className="form-control" name="kabupaten" onChange={this.handleInput} type="text" value={this.state.kabupaten}>
                      <option value="0">--Pilih Kab/Kota--</option>
                      {this.state.dataKabKota.map((data)=>(
                        <option key={data.id} value={data.id}>{data.kabupaten_name}</option>
                      ))} 
                    </select>
                  <span style={{color:"#FF5370"}}><span className='text-danger'>{this.state.errors.kabupaten}</span></span>
                </div>
              </div>
              {
                this.props.dataUsers.code_roles == 'RL001' || this.props.dataUsers.code_roles == 'RL001'  ?
                <>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="id_kabkota">Recommender<span className='text-danger'>*</span> </label>
                        <select className="form-control" name="code_recommender" onChange={this.handleInput} type="text" value={this.state.code_recommender}>
                          <option value="0">--Pilih recommender--</option>
                          {this.state.dataBizDev.map((data)=>(
                            <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                          ))} 
                        </select>
                      <span style={{color:"#FF5370"}}><span className='text-danger'>{this.state.errors.code_recommender}</span></span>
                    </div>
                  </div>
                  {/* <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="id_kabkota">Counsellor<span className='text-danger'>*</span> </label>
                        <select className="form-control" name="code_acc_manager" onChange={this.handleInput} type="text" value={this.state.code_acc_manager}>
                          <option value="0">--Pilih Counsellor--</option>
                          {this.state.dataManager.map((data)=>(
                            <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                          ))} 
                        </select>
                      <span style={{color:"#FF5370"}}><span className='text-danger'>{this.state.errors.code_acc_manager}</span></span>
                    </div>
                  </div> */}
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor="id_kabkota">Accomodir<span className='text-danger'>*</span> </label>
                        <select className="form-control" name="type_accomodir" onChange={this.handleInput} type="text" value={this.state.type_accomodir}>
                          <option value="0">--Pilih Accomodir--</option>
                          <option value="1">Branch</option>
                          <option value="2">Public</option>
                        </select>
                      <span style={{color:"#FF5370"}}><span className='text-danger'>{this.state.errors.type_accomodir}</span></span>
                    </div>
                  </div>
                </> : ''
              }
              {
                this.props.dataUsers.code_roles !== 'RL008' ?
                <div className='form-row'>
                  <div className='col-md-12 mb-3'>
                    <label htmlFor="id_kabkota">Status Student Ambassdor </label>
                    <select className='form-control' name='is_students_ambassador' value={this.state.is_students_ambassador} onChange={this.handleInput}>
                      <option value='0'>Tidak</option>
                      <option value='1'>Ya</option>
                    </select>
                  </div>
                </div>
              : ''
              }
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="id_kabkota">About</label>
                  <textarea className="form-control" name="about" rows={5} type="text" onChange={this.handleInput} value={this.state.about} />
                  <span style={{color:"#FF5370"}}>{this.state.errors.about}</span>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>No. Rekening</label>
                  <input name='no_rek' className='form-control' type='text' onChange={this.handleInput} value={this.state.no_rek}/>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>Bank Name</label>
                  <input name='bank_name' className='form-control' type='text' onChange={this.handleInput} value={this.state.bank_name}/>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label>Account Name</label>
                  <input name='account_name' className='form-control' type='text' onChange={this.handleInput} value={this.state.account_name}/>
                </div>
              </div>
              <div style={{display:this.props.dataUsers.code_roles != 'RL008' ? '' : 'none'}} className="form-row">
                <div className="col-md-12 mb-3">
                  <label >File Kontrak <span className='text-danger'>(input file jika diubah)</span></label>
                  <input name='contract' className='form-control' type='file' onChange={this.handleChangeContract}/>
                  <span style={{color:"#FF5370"}}>{this.state.errors.contract}</span>
                </div>
              </div>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <label htmlFor="id_kabkota">Photo <span className='text-danger'>(input file jika diubah)</span></label>
                  <input name='photo' className='form-control' type='file' onChange={this.handleChangeImage}/>
                  <span style={{color:"#FF5370"}}>{this.state.errors.about}</span>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button disabled={this.state.btnDisabled} color="primary" type="submit" >{this.state.btnText}</Button>
              <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
            </ModalFooter>
          </form>
        </Modal>
      )
    }

    render(){
        return (
            <Fragment>
              <div>
                <div className="card custom-card">
                  <div className="col-12 mt-2">
                    <button onClick={this.editProfile} className="btn btn-outline-warning btn-xs" type="button" style={{border:'none', display:this.state.styleEditbtn}} ><i className="fa fa-pencil" style={{ width: 35, fontSize: 25 }}></i></button>
                  </div>
                  <div className='card-profile'>
                    <img className="rounded-circle" src={this.state.photo !== null ? this.state.photo : seven} alt="" />
                  </div>
                  <div className="text-center profile-details">
                    <h4>{this.state.name}</h4>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Code</td>
                          <td>{this.state.code_ambassador}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{this.state.email}</td>
                        </tr>
                        <tr>
                          <td>Telepon</td>
                          <td>{this.state.phone}</td>
                        </tr>
                        <tr>
                          <td>Provinsi</td>
                          <td>{this.props.data.province !== undefined ?  this.props.data.province.province_name : '' }</td>
                        </tr>
                        <tr>
                          <td>Kab/Kota</td>
                          <td>{this.props.data.kabupaten !== undefined ? this.props.data.kabupaten.kabupaten_name : ''}</td>
                        </tr>

                        {
                        this.props.dataUsers.code_roles !== 'RL008' ? 
                        <tr>
                          <td>Status Student Ambassador</td>
                          <td>{ this.state.is_students_ambassador === 0 ? 'Tidak' : 'Ya'}</td>
                        </tr>
                        :''
                        }
                        <tr>
                          <td>Recommender</td>
                          <td>{this.props.data.recommender !== null ?  this.props.data.recommender.name : '' }</td>
                        </tr>
                        {/* <tr>
                          <td>Account Manager</td>
                          <td>{this.props.data.manager !== null ?  this.props.data.manager.name : '' }</td>
                        </tr> */}
                        <tr>
                          <td>Accomodir</td>
                          <td>
                            {
                              this.state.type_accomodir == '1' ?
                              <span>Branch</span>
                              :
                              this.state.type_accomodir == '2' ?
                              <span>Public</span>
                              :
                              <span>&nbsp;</span>
                            }
                          </td>
                        </tr>
                        <tr>
                          <td>About</td>
                          <td>{this.state.about}</td>
                        </tr>
                        <tr>
                          <td>No. Rek.</td>
                          <td>{this.state.no_rek}</td>
                        </tr>
                        <tr>
                          <td>Bank Name</td>
                          <td>{this.state.bank_name}</td>
                        </tr>
                        <tr>
                          <td>Account Name</td>
                          <td>{this.state.account_name}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="text-center profile-details m-3">
                      <h4>Personal Page Link</h4>
                      <Link to={{pathname: `https://ambassador.niecindonesia.com/`+this.state.username}} target="_blank">{'https://ambassador.niecindonesia.com/'+this.state.username}</Link>
                    </div>
                  </div>
                </div>
                <div className='card'>
                  <div className='card-header'>
                    <button onClick={() => this.addContract()} className="btn btn-outline-success btn-sm" type="button" style={{border:'none'}} >
                      <span>
                        <i className="fa fa-plus"></i>
                      </span>
                    </button>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      {
                        this.state.dataContract.length > 0 ?
                        this.state.dataContract.map(item => (
                          <div key={item.id} className='col-md-3 col-xs-6 mb-3'>
                            <div className='product-box'>
                              <span>{Moment(item.start_contract).format('DD MMMM yyyy')}</span>
                                <div className='product-img'>
                                    <img className='img-thumbnail' src={pdf} alt='file' />
                                    <div className='product-hover'>
                                        <ul>
                                            <li>
                                                <button className="btn" type="button" onClick={() => this.handleModalPreivew(item.filename)}><i className="icon-eye"></i></button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <button onClick={() => this.handleModalDelete(item.id) } className='btn btn-outline-danger btn-block btn-xs'><i className='fa fa-trash'></i></button>
                          </div>
                        )) : ''
                      }
                    </div>
                  </div>
                </div>
              </div>
              {this.renderEditDialog()}
              {this.renderContarctDialog()}
              {this.renderModalDelete()}
              {this.renderPreviewModal()}
            </Fragment>
        );
    }
}

const reduxState = (state) => ({
    dataToken: state.Login.lgnInitialize,
    codeUsers: state.Login.detailUser.code_users,
    dataUsers: state.Login.detailUser
  })
  
  export default connect(reduxState, null)(withRouter(ProfileData));