/* eslint-disable no-unused-vars */

import React, { Component,Fragment } from 'react';
import Countup from 'react-countup';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Moment from 'moment';
import DetailStudentConsultant from './partition/detail-student-consultan';

// const StudentsPersonalComponent = () => {
class StudentsPersonalComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // detailData:[],
            startDate: Moment().startOf('year').add(-6,'months'),
            endDate: Moment().endOf('year').add(-6,'months'),
            staticFilter:'',
            dinamicFilter:'none',
            date:'this_year',
            totalStudentsConsultant:0,
            studentsGranted:0,
            studentsRejected:0,
            codeFilter: this.props.dataUser.code_roles === 'RL008' ? 'code_ambassador' : 'code_konsultan',
            filterData: this.props.dataUser.code_roles === 'RL008' ? this.props.dataUser.code_ambassador : this.props.dataUser.code_konsultan,
            url: API_URL.superStudent+'/student/count-progress-log'
        }
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        //this.showDetail()
        this.getStudentsConsultantAll();
        this.getStudentsGranted();
        this.getStudentsRejected();
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        },() => {
            if(this.state.date === 'dinammic_date'){
                this.setState({
                    dinamicFilter:'',
                    staticFilter:'none'
                })
            }
            else{
                this.setRangeDate()
            }
        })
    }

    setRangeDate(){   
        let startDate = Moment();
        let endDate  = Moment();     
        switch(this.state.date){
            case 'this_week':
                startDate.startOf('isoWeek')
                endDate.endOf('isoWeek')
                break;
            case 'last_week':
                startDate.subtract(1, 'weeks').startOf('isoWeek')
                endDate.subtract(1, 'weeks').endOf('isoWeek')
                break;
            case 'this_month':
                startDate.startOf('month')
                endDate.endOf('month')
                break;
            case 'last_month':
                startDate.subtract(1, 'months').startOf('month')
                endDate.subtract(1, 'months').endOf('month')
                break;
            case 'last_year':
                startDate.subtract(1, 'year').startOf('year').add(-6,'months')
                endDate.subtract(1, 'year').endOf('year').add(-6,'months')
                break;
            case '1':
            case '2':
            case '3':
            case '4':
                startDate.quarter(this.state.date).subtract(2,'quarter').startOf('quarter')
                endDate.quarter(this.state.date).subtract(2, 'quarter').endOf('quarter')
                break;
            default:
                startDate.startOf('year').add(-6,'months')
                endDate.endOf('year').add(-6,'months')
        }
        this.setState({ 
            startDate,
            endDate
        },() => this.filter())
    }

    showDetail = () => {
        let filterData = new FormData();
        filterData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        filterData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        filterData.append(this.state.codeFilter, this.state.filterData)
        axios.post(API_URL.superStudent+'/consultant-peformance/student-consultant-by-progress', filterData ,{
            headers: { 'Authorization': `Bearer ${this.props.dataToken}`, } 
        })
        .then(response => {
            this.setState({
                detailData:response.data.data,
            })
        })
        .catch(error => {
            console.log(error)
        })
    }

    filter = () => {
        //this.showDetail()
        this.getStudentsConsultantAll();
        this.getStudentsGranted();
        this.getStudentsRejected();
    }


    getStudentsConsultantAll() {
        let formData = new FormData();
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        formData.append(this.state.codeFilter, this.state.filterData)
        axios.post(API_URL.superStudent+'/ambassador/total-students', formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => this.setState({totalStudentsConsultant:response.data.data }))     
    }

    getStudentsGranted() {
        let formData = new FormData();
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        formData.append(this.state.codeFilter, this.state.filterData)
        formData.append('code','PG015')
        axios.post(API_URL.superStudent+'/ambassador/total-progress', formData, { 
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ studentsGranted: data }) 
        });     
    }

    getStudentsRejected() {
        let formData = new FormData();
        formData.append('start_date', this.state.startDate.format('YYYY-MM-DD'))
        formData.append('end_date', this.state.endDate.format('YYYY-MM-DD'))
        formData.append(this.state.codeFilter, this.state.filterData)
        formData.append('code','PG018')
        axios.post(API_URL.superStudent+'/ambassador/total-progress', formData, {
            headers: { 'Authorization': `Bearer ${this.props.dataToken}` } 
        }).then(response => response.data.data).then((data) => {
            this.setState({ studentsRejected: data }) 
        });     
    }

    render() {
        return (
            <Fragment>
                <div className="tab-content" id="tab-2">
                    <div className="row m-1">
                    <div className="col-sm-12">
                    <div style={{marginBottom:'10px'}} className="row justify-content-md-center justify-content-xs-center">
                        <div style={{display:this.state.staticFilter}} className='col-md-3 col-sm-4 mb-2'>
                            <select name='date' value={this.state.date} onChange={this.handleInput} className='form-control'>
                                <option value='this_week'>This Week</option>
                                <option value='last_week'>Last Week</option>
                                <option value='this_month'>This Month</option>
                                <option value='last_month'>Last Month</option>
                                <option value='this_year'>This Year</option>
                                <option value='last_year'>Last Year</option>
                                    <optgroup label='Quartal'>
                                        <option value='1'>Quartal I</option>
                                        <option value='2'>Quartal II</option>
                                        <option value='3'>Quartal III</option>
                                        <option value='4'>Quartal IV</option>
                                </optgroup>
                                <option value='dinammic_date'> --Dinammic Date-- </option>
                            </select>
                        </div>
                        <div style={{display:this.state.dinamicFilter}} className='row'>
                            <div className="col-md-4 mb-2">
                                <input className="form-control" type="date" onChange={(e) => this.setState({ startDate: Moment(e.target.value)})} name="startDate"  value ={this.state.startDate.format('YYYY-MM-DD')} />
                            </div>
                            <div className="col-md-4 mb-2">
                                <input className="form-control" type="date" onChange={(e) => this.setState({ endDate: Moment(e.target.value)})} name="endDate"  value ={this.state.endDate.format('YYYY-MM-DD')} />
                            </div>
                            <div className="col-md-4 btn-group" role='group'>
                                <button style={{color:'white'}} type='button' className='btn btn-primary btn-md' onClick={() => this.filter()} ><i className='fa fa-filter'></i> Filter</button>
                                <button style={{color:'white'}} className='btn btn-success btn-md' type='button' onClick={() => this.setState({dinamicFilter:'none', staticFilter:''})}><i className='fa fa-refresh'></i></button>
                            </div>
                        </div>
                        <div className='col-sm-12 mb-3'>
                            <span style={{fontSize:'1.5em'}} className='f-w-600'>{this.state.startDate.format('DD MMMM yyyy')} - {this.state.endDate.format('DD MMMM yyyy')}</span>
                        </div>          
                    </div>
                </div>
                        <div className="col-xl-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="mb-0">Total Students</h5>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h2 className="font-primary counter">
                                            <Countup end={this.state.totalStudentsConsultant} />
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-12">
                            <div className="card">
                                        <div className="card-body">
                                            <div className="text-center">
                                                <h6 className="mb-0">Granted</h6>
                                            </div>
                                            <div className="project-widgets text-center">
                                                <h2 className="font-primary counter">
                                                    <Countup end={this.state.studentsGranted} />
                                                </h2>
                                            </div>
                                        </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h6 className="mb-0">Rejected</h6>
                                    </div>
                                    <div className="project-widgets text-center">
                                        <h2 className="font-primary counter">
                                            <Countup end={this.state.studentsRejected} />
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-sm-12'>
                            <DetailStudentConsultant data={this.state.detailData}/>
                        </div> */}
                    </div>
                </div>

            </Fragment>
        );
    }
}

// export default StudentsPersonalComponent;

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
  })
  
export default connect(reduxState, null)(withRouter(StudentsPersonalComponent));