import { Card, CardBody, CardHeader } from 'reactstrap';
import ScrollBar from "react-perfect-scrollbar";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import API_URL from '../../../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class DataOrangTua extends Component {    
  constructor(props) {
    super(props);
    this.state = {
        id_parents: "",
        code_students: "",
        keterangan: "",
        nama_ayah: "",
        nama_ibu: "",
        no_telp_ayah: "",
        no_telp_ibu: "",
        modal: false,
        operation: '',
        btnDisabled: false,
    }
}

addParent() {
    this.setState({
      operation: 'add',
      modal: true,
      btnDisabled: false,
    })
}

editParent() {
    const parent = this.props.data;
    this.setState({
        modal:true,
        btnDisabled: false,
        operation: 'update',
        id_parents: parent.id_parents,
        keterangan: parent.keterangan,
        nama_ayah: parent.nama_ayah,
        nama_ibu: parent.nama_ibu,
        no_telp_ayah: parent.no_telp_ayah,
        no_telp_ibu: parent.no_telp_ibu,
    })
}

closeModal(){
  this.setState({
      modal: false,
  });
  this.resetState();
}

resetState(){
    this.setState({
        id_parents: "",
        keterangan: "",
        nama_ayah: "",
        nama_ibu: "",
        no_telp_ayah: "",
        no_telp_ibu: "",
    });
}

sendData() {
    this.setState({
      btnDisabled: true
    });

    var formData = new FormData();
    formData.append('code_students',this.props.code_students);
    formData.append('nama_ayah',this.state.nama_ayah);
    formData.append('nama_ibu',this.state.nama_ibu);
    formData.append('no_telp_ayah',this.state.no_telp_ayah);
    formData.append('no_telp_ibu',this.state.no_telp_ibu);
    formData.append('keterangan',this.state.keterangan);

    let url = '/student/parents'

    if (this.state.operation === 'update'){
        formData.append('_method','PATCH');
        url = '/student/parents/'+this.state.id_parents
    }

    axios({
        method: 'POST',
        url: API_URL.superStudent+url,
        data: formData,
        headers: {  Authorization: `Bearer ${this.props.dataToken}`  }
    })
    .then(() => {
      toast.success('Data Update Successfully')
      this.props.onReloadParents();
      this.closeModal();
    })
}

handleInput = (e) => {
    this.setState({
        [e.target.name]:e.target.value
    })
}
    renderEditDialog() {
          return(
            <Modal className="modal-dialog" isOpen={this.state.modal} toggle={() => this.closeModal()} size="lg">
              <ModalHeader toggle={() => this.closeModal()}>Form Parent</ModalHeader>
              <ModalBody style={{
                    maxHeight: 'calc(100vh - 210px)',
                    overflowY: 'auto'
                }}>
                <form className="needs-validation" noValidate="" >
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                    <label htmlFor="nama_ayah">Nama Ayah</label>
                      <input className="form-control" name="nama_ayah" type="text" value={this.state.nama_ayah}
                        onChange={this.handleInput}/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                    <label htmlFor="nama_ibu">Nama Ibu</label>
                      <input className="form-control" name="nama_ibu" type="text" value={this.state.nama_ibu}
                        onChange={this.handleInput}/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                    <label htmlFor="no_telp_ayah">No Telp Ayah</label>
                      <input className="form-control" name="no_telp_ayah" type="text" value={this.state.no_telp_ayah} onChange={this.handleInput} />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                    <label htmlFor="no_telp_ibu">No Telp Ibu</label>
                      <input className="form-control" name="no_telp_ibu" type="text" value={this.state.no_telp_ibu}
                        onChange={this.handleInput}/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 mb-3">
                    <label htmlFor="keterangan">Keterangan</label>
                      <input className="form-control" name="keterangan" type="text" value={this.state.keterangan}
                        onChange={this.handleInput} />
                    </div>
                  </div>
                </form>
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.btnDisabled} color="primary" onClick={(e) => this.sendData()}>Save Changes</Button>
                <Button disabled={this.state.btnDisabled} color="secondary" onClick={() => this.closeModal()}>Cancel</Button>
              </ModalFooter>
            </Modal>
          )
      }

    render(){
        const data = this.props.data
        const dataOrtu = [
          {label: 'Nama Ayah', value: data?.nama_ayah},
          {label: 'Telepon/WA', value: data?.no_telp_ayah},
          {label: 'Nama Ibu', value: data?.nama_ibu },
          {label: 'Telepon/WA', value: data?.no_telp_ibu},
      ]
        return (
          <>
          <Card style={{height:'300px'}}>
              <CardHeader className='pb-0 d-flex justify-content-between mb-0'>
                  <h5>Data Orang Tua</h5>
                  {
                    this.props.data === null ?
                    <i onClick={() => this.addParent()} className='fa fa-plus color-red'></i>
                    :
                    <i onClick={() => this.editParent(data)} className='fa fa-pencil color-red'></i>
                  }
              </CardHeader>
              <CardBody>
              <ScrollBar className="scroll" options={{ suppressScrollY: true }} >
                {
                  this.props.data === null ?
                  <h5>No Data</h5>
                  :
                  <div className="table-responsive">
                  <table className='table text-left'>
                    <tbody>
                  {
                      dataOrtu.map((m,i)=>{
                      return(
                          <tr key={i}>
                              <th scope="col">{m.label}</th>
                              <th scope="col">{m.value}</th>
                          </tr>
                      )
                      })
                  }
                  </tbody>
                  </table>
                  </div>
                }
                </ScrollBar>
              </CardBody>
            </Card>
            {this.renderEditDialog()}
          </>
        )
    }
}

// export default Students;
const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    codeUsers: state.Login.detailUser.code_users || state.Login.detailUser.code_students,
    dataToken: state.Login.lgnInitialize,
  })
  
  export default connect(reduxState, null)(withRouter(DataOrangTua));