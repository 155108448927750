/* eslint-disable eqeqeq */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import API_URL from '../../ApiUrl';
import axios from 'axios';
import Moment from 'moment';
import setRangeDate from '../../helper/general-function';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ReportKPI extends Component {

    constructor(props){
        super(props)
        this.state = {
            timeType:2,
            code_jobdesk:'JD002',
            listJobDesk:[],
            reportKPI:[],
            filterStartDate:Moment().startOf('month').format('YYYY-MM-DD'),
            filterEndDate:Moment().endOf('month').format('YYYY-MM-DD'),
            this_year:{ start:'', end:''},
            last_year:{ start:'', end:''}
        }
    }

    componentDidMount(){
        this.loadJobdesk()
        this.loadKPIData()
    }

    setDateYear(){
        let this_year = setRangeDate('this_year')
        let last_year = setRangeDate('last_year')
        this.setState({
            this_year: {start: this_year.start, end:this_year.end},
            last_year: {start: last_year.start, end:last_year.end}
        })
    }

    loadJobdesk = () => {
        axios.get(API_URL.superStudent+'/consultant/list-jobdesk',{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => this.setState({listJobDesk:response.data.data}))
        .catch(error => console.log(error))
    }

    loadKPIData = () => {
        let formData = new FormData()
        formData.append('code_jobdesk', this.state.code_jobdesk)
        formData.append('time_type', this.state.timeType)
        formData.append('start_date', this.state.filterStartDate)
        formData.append('end_date', this.state.filterEndDate)
        axios.post(API_URL.superStudent+'/kpi/report-by-jobdesk', formData,{
            headers:{  Authorization: `Bearer ${this.props.dataToken}`}
        })
        .then(response => {
            this.setState({
                reportKPI: response.data.data
            })
        })
        .catch(error => console.log(error))
    }

    formatRupiah = (nominal) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        }).format(nominal);
    }

    handleJobdesk = (e) => {
        this.setState({
            code_jobdesk: e.target.value
        }, () => {
            this.loadKPIData()
        })
    }

    handleType = (e) => {
        let date = setRangeDate(parseInt(e.target.value))
        this.setState({
            timeType:e.target.value,
            filterStartDate: date.start,
            filterEndDate: date.end
        },() => {
            this.loadKPIData()
        })
    }

    handlePickerMonth = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('month').format('YYYY-MM-DD'),
            filterEndDate: Moment(e).endOf('month').format('YYYY-MM-DD'),
            timeType:2
        },() => {
            this.loadKPIData()
        })
    }

    handlePickerWeek = (e) => {
        this.setState({
            filterStartDate: Moment(e).startOf('isoWeek').format('YYYY-MM-DD'),
            filterEndDate: Moment(e).endOf('isoWeek').format('YYYY-MM-DD'),
            timeType:1,
            valueWeek:e
        },() => {
            this.loadKPIData()
        })
    }
    render(){
        return(
            <>
            <div className="tab-content active default" id="tab-1">
                <div className='row justify-content-center'>
                    <div className='col-sm-3 mb-2'>
                        <select name='code_jobdesk' value={this.state.code_jobdesk} onChange={this.handleJobdesk} className='form-control'>
                            <option>--Select Division--</option>
                            {
                                this.state.listJobDesk.map((item,index) => (
                                    <option key={index} value={item.code_jobdesk}>{item.jobdesk}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-sm-3 mb-2'>
                        <select value={this.state.timeType} onChange={this.handleType} className='form-control'>
                            <option value={2}>Monthly</option>
                            <option value={4}>This Year</option>
                            <option value={5}>Last Year</option>
                        </select>
                    </div>
                    {
                        this.state.timeType == 2 ?
                        <DatePicker dateFormat='MMMM yyyy' selected={Moment(this.state.filterStartDate)._d} showMonthYearPicker showFullMonthYearPicker className='form-control' onChange={this.handlePickerMonth} />
                        :
                        ''
                    }
                </div>
                {
                    this.state.reportKPI.length > 0 ?
                    this.state.reportKPI.map(item => (
                    <div key={item.id} className='row'>
                        <div className='col-sm-12'>
                            <div className='card'>
                                <div className='table-responsive'>
                                    <table className='table table-bordered'>
                                    <caption style={{captionSide:'top',textAlign:'center',fontSize:'20px',color:'black'}}>{item.name}</caption>
                                        <thead>
                                            <tr>
                                                <th>Employee Name</th>
                                                <th>Bobot </th>
                                                <th>Target</th>
                                                <th>Achieved</th>
                                                <th>Achivement</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            item.targets.length > 0 ?
                                            item.targets.map(value => (
                                                <tr key={value.id}>
                                                    <td>{value.consultant.nama}</td>
                                                    <td>{value.bobot}</td>
                                                    <td>{
                                                        item.id === 67 || item.id === 60 || item.id === 5 || item.id === 25 ? this.formatRupiah(value.total_target) : value.total_target  
                                                    }</td>
                                                    <td>{
                                                    item.id === 67 || item.id === 60 || item.id === 5 || item.id === 25 ? this.formatRupiah(value.kpi_data_value) : value.kpi_data_value
                                                    }</td>
                                                    <td>{value.kpi_data_bobot}</td>
                                                </tr>
                                            ))
                                            :
                                            <tr>
                                                <td colSpan={5}>Data Kosong</td>
                                            </tr>
                                            }
                                        </tbody>
                                        <tfoot style={{textAlign:'center'}}>
                                            <tr>
                                                <th colSpan={2}>Total</th>
                                                <th>{
                                                      item.id === 60 || item.id === 5 || item.id === 25 ? this.formatRupiah(item.total_target) : item.total_target    
                                                }</th>
                                                <th>{
                                                item.id === 60 || item.id === 5 || item.id === 25 ? this.formatRupiah(item.total_achived) : item.total_achived  
                                                }</th>
                                                <th>{item.total_achivement.toFixed(2)} %</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                    :
                    ''
                }
                
            </div>
            </>
        )
    }
}

const reduxState = (state) => ({
    dataUser: state.Login.detailUser,
    dataToken: state.Login.lgnInitialize,
})

export default connect(reduxState, null)(ReportKPI)